import React from 'react'
import { Trans } from '@lingui/react'

const Price = ({
    price = null,
    currencySymbol = '€',
    prefix = <Trans id="from" />,
    ...props
}) => {
    if (price === null) {
        return ''
    }
    return (
        <span {...props}>
            {prefix} {currencySymbol}{price}
        </span>
    )
}

export default Price
