import React from 'react'
import clsx from 'clsx'
import Icon from '../utils/Icon'

const QuickSearchSectionDesktopTab = ({
    type,
    onClick,
    children,
    iconName,
    isActive = true,
}) => (
    <li
        className={clsx(
            'relative first:rounded-tl-lg flex-1',
            isActive && 'bg-white'
        )}
    >
        <button
            type="button"
            onClick={onClick}
            data-testid={`QsmTab-${type}`}
            className={clsx(
                'w-full flex items-center justify-center leading-[22px] px-12 py-3 hover:underline',
                !isActive && 'text-gray-600',
                isActive && 'hover:no-underline  font-medium'
            )}
        >
            <Icon name={iconName} className="mr-2" size="smaller" />
            {children}
        </button>
    </li>
)

export default QuickSearchSectionDesktopTab
