import React, { useState } from 'react'
import { useQueryParam } from 'use-query-params'
import * as constants from '@connections/constants'
import useQuickSearchFlightFormState, {
    useFareTypeOptions,
} from './useQuickSearchFlightFormState'
import QuickSearchSectionDesktopFlights from './QuickSearchSectionDesktopFlights'

const { FLIGHT_MODE_ONE_WAY: ONE_WAY, FLIGHT_MODE_MULTI_CITY: MULTI_CITY } =
    constants

const QuickSearchSectionDesktopFlightsWithState = ({
    uid,
    values,
    onSearch,
    isLoading,
    isSearching,
    promoValues,
}) => {
    const [journeyUid] = useQueryParam('journeyUid')
    const [hasChosenTo, setHasChosenTo] = useState(false)
    const [hasChosenFrom, setHasChosenFrom] = useState(false)
    const [hasChosenDates, setHasChosenDates] = useState(false)
    const isPromo =
        promoValues.promoToIataCode ||
        promoValues.promoFromIataCode ||
        promoValues.promoToDefaultIataCodes?.length > 0 ||
        promoValues.promoFromDefaultIataCodes?.length > 0
    const fareType = promoValues.promoCabinClass
    const airlineIataCodes = promoValues.promoAirlineIataCodes
    const minNumberOfTravelers = promoValues.promoMinTravelers || 1
    const toIataCode = promoValues.promoToIataCode || values.toIataCode
    const fromIataCode = promoValues.promoFromIataCode || values.fromIataCode
    const toDefaultIataCodes =
        promoValues.promoToIataCode ||
        promoValues.promoToDefaultIataCodes?.length > 0
            ? promoValues.promoToDefaultIataCodes
            : values.toDefaultIataCodes
    const fromDefaultIataCodes =
        promoValues.promoFromIataCode ||
        promoValues.promoFromDefaultIataCodes?.length > 0
            ? promoValues.promoFromDefaultIataCodes
            : values.fromDefaultIataCodes
    const canSearchTo =
        !isPromo ||
        (!promoValues.promoToIataCode &&
            promoValues.promoToDefaultIataCodes?.length === 0)
    const canSearchFrom =
        !isPromo ||
        (!promoValues.promoFromIataCode &&
            promoValues.promoFromDefaultIataCodes?.length === 0)
    const fareTypes = useFareTypeOptions(fareType)
    const formState = useQuickSearchFlightFormState(
        fromIataCode,
        toIataCode,
        minNumberOfTravelers,
        fareType
    )
    const { flightMode } = formState.values
    const hasOnlyDepartureDate =
        flightMode === MULTI_CITY || flightMode === ONE_WAY

    return (
        <QuickSearchSectionDesktopFlights
            uid={uid}
            isPromo={isPromo}
            formState={formState}
            fareTypes={fareTypes}
            isLoading={isLoading}
            isSearching={isSearching}
            canSearchTo={canSearchTo}
            hasChosenTo={hasChosenTo}
            hasChosenFrom={hasChosenFrom}
            canSearchFrom={canSearchFrom}
            hasChosenDates={hasChosenDates}
            toDefaultIataCodes={toDefaultIataCodes}
            onAddExtraSegment={formState.addSegment}
            fromDefaultIataCodes={fromDefaultIataCodes}
            minNumberOfTravelers={minNumberOfTravelers}
            hasOnlyDepartureDate={hasOnlyDepartureDate}
            onRemoveExtraSegment={formState.removeSegment}
            onChangeFareType={formState.handleChangeFareType}
            onChangeFlightMode={formState.handleChangeFlightMode}
            onChangeNumberOfAdults={formState.handleChangeNumberOfAdults}
            onChangeNumberOfInfants={formState.handleChangeNumberOfInfants}
            onChangeNumberOfChildren={formState.handleChangeNumberOfChildren}
            onChangeDates={(departure, returnValue) => {
                formState.handleChange('departureDate', departure)
                formState.handleChange('returnDate', returnValue)
                if (
                    (hasOnlyDepartureDate && !!departure) ||
                    (!!departure && !!returnValue)
                ) {
                    setHasChosenDates(true)
                }
            }}
            onChangeToDestination={(destination) => {
                formState.handleChange('toDestination', destination)
                setHasChosenTo(true)
            }}
            onChangeFromDestination={(destination) => {
                formState.handleChange('fromDestination', destination)
                setHasChosenFrom(true)
            }}
            onChangeExtraSegmentToDestination={(index, value) => {
                formState.handleChangeSegment(index, 'toDestination', value)
            }}
            onChangeExtraSegmentDeparture={(index, value) => {
                formState.handleChangeSegment(index, 'departureDate', value)
            }}
            onChangeExtraSegmentFromDestination={(index, value) => {
                formState.handleChangeSegment(index, 'fromDestination', value)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch({
                        ...formState.values,
                        journeyUid,
                        airlineIataCodes,
                    })
                }
            }}
        />
    )
}

export default QuickSearchSectionDesktopFlightsWithState
