import React from 'react'
import ContactPhoneLink from '../utils/ContactPhoneLink'
import TopBarMenuItem from './TopBarMenuItem'
import TopBarLanguageSelect from './TopBarLanguageSelect'

const TopBar = ({
    contactText,
    menuItems,
}) => (
    <div className="bg-black">
        <div className="flex container justify-between py-3 items-center">
            <div className="flex flex-row text-white text-sm items-center">
                <div>{contactText}</div>
                <div className="ml-1">
                    <ContactPhoneLink />
                </div>
            </div>
            <nav className="flex">
                {menuItems.map((item) => (
                    <TopBarMenuItem
                        key={item._uid}
                        content={item}
                    />
                ))}
                <TopBarLanguageSelect />
            </nav>
        </div>
    </div>
)

export default TopBar
