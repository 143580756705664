import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'

export const Label = ({
    htmlFor,
    children,
    className,
}) => (
    <label
        htmlFor={htmlFor}
        className={clsx(
            'block w-full text-xs uppercase tracking-wider',
            className,

        )}
    >
        {children}
    </label>
)

const QuickSearchSectionMobileDestinationButton = ({
    destination,
    isPlaceholder,
    placeholder = <Trans id="Destination" />,
    ...props
}) => (
    <button
        type="button"
        className="items-center text-sm w-full p-4"
        {...props}
    >
        <div
            className={clsx(
                'flex-1 text-left truncate',
                isPlaceholder && 'text-gray-200'
            )}
        >
            {destination || placeholder}
        </div>
    </button>
)

export default QuickSearchSectionMobileDestinationButton
