import React from 'react'
import { useLingui, Trans, } from '@lingui/react'
import Button from '../utils/Button'
import SwitchButton from '../utils/SwitchButton'
import QuickSearchSectionDesktopDestinationSelect from './QuickSearchSectionDesktopDestinationSelect'
import QuickSearchSectionDesktopFlightsDateSelect from './QuickSearchSectionDesktopFlightsDateSelect'

const QuickSearchSectionDesktopFlightsExtraSegment = ({
    index,
    errors,
    viewDate,
    onRemove,
    isSearching,
    toDestination,
    departureDate,
    fromDestination,
    onSwitchFromAndTo,
    onChangeDeparture,
    numberOfSegments = 2,
    onChangeToDestination,
    dayIsDisabledCondition,
    onChangeFromDestination,
}) => {
    const { i18n } = useLingui()
    return (
        <div className="flex flex-wrap gap-4 ">
            <div className="flex relative gap-4">
                <div className="mt-3 flex-1 min-w-[14rem]">
                    <QuickSearchSectionDesktopDestinationSelect
                        disabled={isSearching}
                        selectClassName="!pr-4"
                        destination={fromDestination}
                        onChange={onChangeFromDestination}
                        inputPlaceholder={i18n._(/*i18n*/'From where?')}
                        resultsPlaceholder={<Trans id="Search an airport" />}
                        testId="QuickSearchSectionDesktopDestinationSelectFrom"
                        errors={errors.filter(({ path }) => (
                            path === `extraSegments.${index}.fromDestination`
                        ))}
                    />
                </div>
                {!isSearching && (
                    <SwitchButton
                        onClick={onSwitchFromAndTo}
                        className="self-end absolute bottom-2 left-1/2 -translate-x-1/2 z-1"
                    />
                )}
                <div className="mt-3 flex-1 min-w-[14rem]">
                    <QuickSearchSectionDesktopDestinationSelect
                        disabled={isSearching}
                        selectClassName="!pl-4"
                        destination={toDestination}
                        onChange={onChangeToDestination}
                        inputPlaceholder={i18n._(/*i18n*/'Where to?')}
                        testId="QuickSearchSectionDesktopDestinationSelectTo"
                        resultsPlaceholder={<Trans id="Search an airport" />}
                        errors={errors.filter(({ path }) => (
                            path === `extraSegments.${index}.toDestination`
                        ))}
                    />
                </div>
            </div>
            <div className="mt-3 flex-1 min-w-[13rem]">
                <QuickSearchSectionDesktopFlightsDateSelect
                    viewDate={viewDate}
                    date={departureDate}
                    disabled={isSearching}
                    onChange={onChangeDeparture}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    errors={errors.filter(({ path }) => (
                        path === `extraSegments.${index}.departureDate`
                    ))}
                />
            </div>
            <div className="mt-3 flex-1 min-w-[13rem]">
                <Button
                    variant="link"
                    onClick={onRemove}
                    iconBeforeName="bin"
                    disabled={isSearching || numberOfSegments < 2}
                    className="text-blue-bright disabled:text-gray-200"
                    data-testid="QuickSearchSectionDesktopFlightsExtraSegmentRemove"
                >
                    <Trans id="Remove" />
                </Button>
            </div>
            <div className="mt-3 flex-1" />
        </div>
    )
}

export default QuickSearchSectionDesktopFlightsExtraSegment
