import React from 'react'
import { cleanPhone } from '../../util/index'
import usePageProps from '../hooks/usePageProps'

const ContactPhoneLink = () => {
    const { data } = usePageProps()
    const { contactPhone } = data.settingsStory
    return (
        <a
            href={`tel:${cleanPhone(contactPhone)}`}
            className="px-1 hover:underline"
        >
            {contactPhone}
        </a>
    )
}

export default ContactPhoneLink
