import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import { SearchListboxLoading, SearchListboxError } from '../utils/SearchListbox'
import QuickSearchSectionTravelPeriods from './QuickSearchSectionTravelPeriods'

const QuickSearchSectionMobileTravelPeriodModal = ({
    error,
    months,
    loading,
    onClose,
    formState,
    hasValues,
    vacations,
    ...props
}) => (
    <ModalSlideUp
        isFullScreen
        onClose={onClose}
        {...props}
    >
        <ModalSlideUpHeader
            onClose={onClose}
            title={<Trans id="When" />}
        />
        <ModalSlideUpContent className="px-2">
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to fetch periods." />
                        </SearchListboxError>
                    )
                }
                if (loading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Loading periods..." />
                        </SearchListboxLoading>
                    )
                }
                return (
                    <QuickSearchSectionTravelPeriods
                        months={months}
                        vacations={vacations}
                        selectedMonths={formState.values.months}
                        selectedVacations={formState.values.vacations}
                        onSelectDefault={() => {
                            formState.handleChange('months', [])
                            formState.handleChange('vacations', [])
                            onClose()
                        }}
                    />
                )
            })()}
        </ModalSlideUpContent>
        <ModalSlideUpFooter className="flex items-center">
            <Button
                onClick={onClose}
                className="mr-4 w-full"
                variant="blue-outlined"
            >
                {hasValues
                    ? <Trans id="Select" />
                    : <Trans id="Close" />}
            </Button>
        </ModalSlideUpFooter>
    </ModalSlideUp>
)

export default QuickSearchSectionMobileTravelPeriodModal
