import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import { Label } from './QuickSearchSectionMobileDestinationButton'
import QuickSearchSectionMobileFlightsDateSelect from './QuickSearchSectionMobileFlightsDateSelect'
import QuickSearchSectionMobileFlightsDestinationButtons from './QuickSearchSectionMobileFlightsDestinationButtons'

const QuickSearchSectionMobileFlightsExtraSegment = ({
    index,
    errors,
    onRemove,
    viewDate,
    isSearching,
    canSearchTo,
    canSearchFrom,
    toDestination,
    departureDate,
    fromDestination,
    onSwitchFromAndTo,
    numberOfSegments = 2,
    onConfirmToDestination,
    onConfirmDepartureDate,
    dayIsDisabledCondition,
    onConfirmFromDestination,
}) => (
    <div>
        <div className="flex justify-between items-center">
            <Label>
                <Trans id="Trip {index}" values={{ index: index + 2 }} />
            </Label>
            <Button
                variant="link"
                onClick={onRemove}
                iconBeforeName="bin"
                disabled={isSearching || numberOfSegments < 2}
                className="text-blue-bright disabled:text-gray-200"
                data-testid="QuickSearchSectionMobileFlightsExtraSegmentRemove"
            >
                <Trans id="Remove" />
            </Button>
        </div>
        <QuickSearchSectionMobileFlightsDestinationButtons
            className="mb-4"
            toDefaultIataCodes={[]}
            fromDefaultIataCodes={[]}
            isSearching={isSearching}
            canSearchTo={canSearchTo}
            canSearchFrom={canSearchFrom}
            toDestination={toDestination}
            fromDestination={fromDestination}
            onSwitchFromAndTo={onSwitchFromAndTo}
            onConfirmToDestination={onConfirmToDestination}
            onConfirmFromDestination={onConfirmFromDestination}
            errors={errors.filter(({ path }) => (
                path === `extraSegments.${index}.fromDestination`
                || path === `extraSegments.${index}.toDestination`
            ))}
        />
        <QuickSearchSectionMobileFlightsDateSelect
            className="mb-4"
            date={departureDate}
            viewDate={viewDate}
            disabled={isSearching}
            onConfirm={onConfirmDepartureDate}
            dayIsDisabledCondition={dayIsDisabledCondition}
            errors={errors.filter(({ path }) => (
                path === `extraSegments.${index}.departureDate`
            ))}
        />
    </div>
)

export default QuickSearchSectionMobileFlightsExtraSegment
