import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { variant as createVariant } from '../../util/index'

export const VARIANT_BLUE_FILLED = 'blue-filled'
export const VARIANT_BLUE_OUTLINED = 'blue-outlined'
export const VARIANT_PRIMARY_FILLED = 'primary-filled'
export const VARIANT_GREEN_OUTLINED = 'green-outlined'
export const VARIANT_ORANGE_OUTLINED = 'orange-outlined'
export const VARIANT_BLUE_BORDERLESS = 'blue-borderless'

export const SIZE_LARGE = 'large'
export const SIZE_DEFAULT = 'default'

const Tag = forwardRef(({
    text,
    children,
    className,
    size = SIZE_DEFAULT,
    variant = VARIANT_BLUE_FILLED,
    ...props
}, ref) => (
    <div
        ref={ref}
        dangerouslySetInnerHTML={children ? undefined : { __html: text }}
        className={
            clsx(
                'uppercase font-medium rounded-md',
                createVariant(size, {
                    [SIZE_LARGE]: 'py-1 px-3 text-xs',
                    [SIZE_DEFAULT]: 'py-[2px] px-2 text-xs',
                }),
                createVariant(variant, {
                    [VARIANT_BLUE_FILLED]: 'bg-blue text-white',
                    [VARIANT_PRIMARY_FILLED]: 'bg-primary text-white',
                    [VARIANT_BLUE_BORDERLESS]: 'text-blue bg-[#EBF3FF]',
                    [VARIANT_GREEN_OUTLINED]: 'bg-lime-700 bg-opacity-5 border-lime-700 border text-lime-700',
                    [VARIANT_BLUE_OUTLINED]: 'bg-blue-bright bg-opacity-5 border-blue-bright border text-blue-bright',
                    [VARIANT_ORANGE_OUTLINED]: 'bg-amber-700 bg-opacity-5 border-amber-700 border text-amber-700',
                }),
                className,
            )
        }
        {...props}
    >
        {children}
    </div>
))

export default Tag
