import useFormState from 'use-form-state'
import useLocaleNavigateTo from './useLocaleNavigateTo'

const useSearchState = () => {
    const navigateTo = useLocaleNavigateTo()
    const formState = useFormState({
        query: '',
    })
    const { values: { query } } = formState

    const handleSearch = (e) => {
        e.preventDefault()
        navigateTo({
            pathname: '/zoeken',
            params: {
                query,
            },
        })
    }

    return {
        formState,
        handleSearch,
    }
}

export default useSearchState
