import useFormState, { createFormValidation } from 'use-form-state'
import {
    useQueryParams,
    StringParam,
    withDefault,
} from 'use-query-params'

const validation = createFormValidation([])

export const useShopsSearchQueryParams = () => (
    useQueryParams({
        code: withDefault(StringParam, ''),
    })
)

const useQuickSearchShopsFormState = () => {
    const [query] = useShopsSearchQueryParams()
    const formState = useFormState({
        location: null,
        postCode: query.code,
    }, { validation })
    return formState
}

export default useQuickSearchShopsFormState
