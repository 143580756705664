import { gql } from 'graphql-request'
import useLazyGraphqlQuery from './useGraphqlMutation'

const QUERY = gql`
    query searchCities($query: String!) {
        searchCities(query: $query) {
            nodes {
                id
                code
                name
                fullName
                countryName
                countryCode
                regionCode
            }
        }
    }
`

const useSearchCities = (options) => (
    useLazyGraphqlQuery(QUERY, options)
)

export default useSearchCities
