import React from 'react'
import clsx from 'clsx'
import { variant } from '../../util/index'
import Icon from './Icon'

export const SearchListItemContainer = ({
    className,
    isSelected,
    indentLevel,
    ...props
}) => (
    <li
        className={clsx(
            'flex items-center justify-between py-4 pr-4 text-sm cursor-pointer hover:bg-gray-100 hover:rounded truncate',
            isSelected && 'bg-gray-100 rounded',
            variant(indentLevel, {
                0: 'pl-2',
                1: 'pl-4',
                2: 'pl-8',
                3: 'pl-12',
                4: 'pl-16',
            }),
            className,
        )}
        {...props}
    />
)

const SearchListItem = ({
    iconName,
    children,
    isSelected,
    actionText,
    onClickAction,
    isOpen = false,
    indentLevel = 0,
    canCollapse = false,
    ...props
}) => (
    <SearchListItemContainer
        isSelected={isSelected}
        indentLevel={indentLevel}
        {...props}
    >
        <div className="flex items-center">
            {canCollapse && (
                <Icon
                    size="small"
                    color="black"
                    name="chevron-right"
                    className={clsx(
                        'mr-2 transition-transform',
                        isOpen && 'rotate-90'
                    )}
                />
            )}
            {iconName && (
                <Icon
                    color="black"
                    size="smaller"
                    name={iconName}
                    className="mr-2"
                />
            )}
            <span className="truncate">{children}</span>
        </div>
        {actionText && (
            <button
                type="button"
                onClick={onClickAction}
                className="text-blue-bright hover:underline"
            >
                {actionText}
            </button>
        )}
    </SearchListItemContainer>
)

export default SearchListItem
