import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import DateRangeCalendar from '../utils/DateRangeCalendar'

const QuickSearchSectionMobileFlightsDateRangeModal = ({
    initialDepartureDate,
    initialReturnDate,
    onClose,
    onConfirm,
    dayIsDisabledCondition,
    ...props
}) => {
    const [selectedDepartureDate, setSelectedDepartureDate] = useState(initialDepartureDate)
    const [selectedReturnDate, setSelectedReturnDate] = useState(initialReturnDate)
    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={<Trans id="Dates" />}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <DateRangeCalendar
                    className="pt-4"
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    departureDate={selectedDepartureDate}
                    returnDate={selectedReturnDate}
                    numberOfMonthButtons={5}
                    onChange={(departure, rtrn) => {
                        setSelectedDepartureDate(departure)
                        setSelectedReturnDate(rtrn)
                    }}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex">
                <Button
                    className="mr-4 w-full"
                    variant="blue-outlined"
                    onClick={onClose}
                >
                    <Trans id="Close" />
                </Button>
                <Button
                    className="w-full"
                    variant="blue-filled"
                    iconAfterName="arrow-right"
                    onClick={() => onConfirm(selectedDepartureDate, selectedReturnDate)}
                    data-testid="QuickSearchSectionFlightsDateRangeSelectConfirm"
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default QuickSearchSectionMobileFlightsDateRangeModal
