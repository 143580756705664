/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import NextLink from 'next/link'
import SbEditable from 'storyblok-react'
import { useOnClickOutside } from '@wappla/react-hooks'
import useLinkActive from '../hooks/useLinkActive'
import ConditionalWrapper from '../utils/ConditionalWrapper'
import HeaderDesktopMenuItemDropDown from './HeaderDesktopMenuItemDropDown'
import HeaderDesktopMenuTravelSearch from './HeaderDesktopMenuTravelSearch'

const HeaderDesktopMenuItem = ({
    content,
}) => {
    const {
        text,
        href,
        dropdowns,
        hasDropdown,
        openInNewTab,
        linkStoryComponent,
    } = content
    const ref = useRef()
    const isActive = useLinkActive(href)
    const [dropdownIsOpen, setDropdownOpen] = useState(false)
    useOnClickOutside(ref, () => setDropdownOpen(false))

    const [dropdown = null] = dropdowns
    const hasTravelSearch = linkStoryComponent === 'TravelSearchPage'
    const hasLink = typeof href !== 'undefined' && !hasTravelSearch

    return (
        <SbEditable content={content}>
            <li
                ref={ref}
                onFocus={() => setDropdownOpen(true)}
                onMouseLeave={() => {
                    if (!hasTravelSearch) {
                        setDropdownOpen(false)
                    }
                }}
                onMouseDown={() => {
                    if (!hasTravelSearch || !dropdownIsOpen) {
                        setDropdownOpen(!dropdownIsOpen)
                    }
                }}
            >
                <ConditionalWrapper
                    condition={hasLink}
                    wrapper={(wrapperChildren) => (
                        <NextLink href={href} className="block" target={openInNewTab ? '_blank' : '_self'}>
                            {wrapperChildren}
                        </NextLink>
                    )}
                >
                    <span
                        className={clsx(
                            'inline-flex items-center px-2 py-5 -my-px mr-4 group text-black border-b-3 border-t-3 border-transparent cursor-pointer',
                            hasLink && 'hover:text-blue-bright',
                            hasLink && isActive && 'border-b-primary',
                        )}
                    >
                        {text}
                        {(hasDropdown || hasTravelSearch) && (
                            <svg
                                width="7px"
                                height="4px"
                                viewBox="0 0 7 4"
                                fill="currentColor"
                                className={clsx(
                                    'text-black ml-2',
                                    hasLink && 'group-hover:text-blue-bright',
                                )}
                            >
                                <path d="M0 0L3.5 4L7 0H0Z" />
                            </svg>
                        )}
                    </span>
                </ConditionalWrapper>
                {(() => {
                    if (hasTravelSearch) {
                        return <HeaderDesktopMenuTravelSearch isOpen={dropdownIsOpen} />
                    }
                    if (hasDropdown && dropdown !== null) {
                        return (
                            <HeaderDesktopMenuItemDropDown
                                content={dropdown}
                                isOpen={dropdownIsOpen}
                            />
                        )
                    }
                    return null
                })()}
            </li>
        </SbEditable>
    )
}

export default HeaderDesktopMenuItem
