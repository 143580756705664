import React, { useState } from 'react'
import { useQueryParam } from 'use-query-params'
import * as constants from '@connections/constants'
import useQuickSearchFlightFormState, {
    useFareTypeOptions,
} from './useQuickSearchFlightFormState'
import QuickSearchSectionMobileFlights from './QuickSearchSectionMobileFlights'

const { FLIGHT_MODE_ONE_WAY: ONE_WAY, FLIGHT_MODE_MULTI_CITY: MULTI_CITY } =
    constants

const QuickSearchSectionMobileFlightsWithState = ({
    uid,
    onOpen,
    values,
    onSearch,
    isLoading,
    isSearching,
    promoValues,
    isCollapsed,
}) => {
    const [hasChosenTo, setHasChosenTo] = useState(false)
    const [hasChosenDates, setHasChosenDates] = useState(false)
    const isPromo =
        promoValues.promoToIataCode ||
        promoValues.promoFromIataCode ||
        promoValues.promoToDefaultIataCodes?.length > 0 ||
        promoValues.promoFromDefaultIataCodes?.length > 0
    const fareType = promoValues.promoCabinClass
    const airlineIataCodes = promoValues.promoAirlineIataCodes
    const minNumberOfTravelers = promoValues.promoMinTravelers || 1
    const toIataCode = promoValues.promoToIataCode || values.toIataCode
    const fromIataCode = promoValues.promoFromIataCode || values.fromIataCode
    const toDefaultIataCodes =
        promoValues.promoToIataCode ||
        promoValues.promoToDefaultIataCodes?.length > 0
            ? promoValues.promoToDefaultIataCodes
            : values.toDefaultIataCodes
    const fromDefaultIataCodes =
        promoValues.promoFromIataCode ||
        promoValues.promoFromDefaultIataCodes?.length > 0
            ? promoValues.promoFromDefaultIataCodes
            : values.fromDefaultIataCodes
    const canSearchTo =
        !isPromo ||
        (!promoValues.promoToIataCode &&
            promoValues.promoToDefaultIataCodes?.length === 0)
    const canSearchFrom =
        !isPromo ||
        (!promoValues.promoFromIataCode &&
            promoValues.promoFromDefaultIataCodes?.length === 0)
    const fareTypes = useFareTypeOptions(fareType)
    const [journeyUid] = useQueryParam('journeyUid')
    const formState = useQuickSearchFlightFormState(
        fromIataCode,
        toIataCode,
        minNumberOfTravelers,
        fareType
    )
    const { flightMode } = formState.values
    const hasOnlyDepartureDate =
        flightMode === MULTI_CITY || flightMode === ONE_WAY

    return (
        <>
            <QuickSearchSectionMobileFlights
                uid={uid}
                onOpen={onOpen}
                isPromo={isPromo}
                formState={formState}
                fareTypes={fareTypes}
                isLoading={isLoading}
                hasChosenTo={hasChosenTo}
                isSearching={isSearching}
                canSearchTo={canSearchTo}
                isCollapsed={isCollapsed}
                canSearchFrom={canSearchFrom}
                hasChosenDates={hasChosenDates}
                toDefaultIataCodes={toDefaultIataCodes}
                onAddExtraSegment={formState.addSegment}
                hasOnlyDepartureDate={hasOnlyDepartureDate}
                fromDefaultIataCodes={fromDefaultIataCodes}
                minNumberOfTravelers={minNumberOfTravelers}
                onRemoveExtraSegment={formState.removeSegment}
                onConfirmFlightMode={formState.handleChangeFlightMode}
                onConfirmToDestination={(destination) => {
                    formState.handleChange('toDestination', destination)
                    setHasChosenTo(true)
                }}
                onChangeExtraSegmentFromDestination={(index, value) => {
                    formState.handleChangeSegment(
                        index,
                        'fromDestination',
                        value
                    )
                }}
                onConfirmFromDestination={(destination) => {
                    formState.handleChange('fromDestination', destination)
                }}
                onConfirmDates={(departureDate, returnDate) => {
                    formState.handleChange('departureDate', departureDate)
                    formState.handleChange('returnDate', returnDate)
                    if (
                        (hasOnlyDepartureDate && !!departureDate) ||
                        (!!departureDate && !!returnDate)
                    ) {
                        setHasChosenDates(true)
                    }
                }}
                onConfirmDepartureDate={(departureDate) => {
                    formState.handleChange('departureDate', departureDate)
                    setHasChosenDates(true)
                }}
                onConfirmPassengers={(adults, children, infants, type) => {
                    formState.handleChangeNumberOfAdults(adults)
                    formState.handleChangeNumberOfChildren(children)
                    formState.handleChangeNumberOfInfants(infants)
                    formState.handleChangeFareType(type)
                }}
                onChangeExtraSegmentToDestination={(index, value) => {
                    formState.handleChangeSegment(index, 'toDestination', value)
                }}
                onChangeExtraSegmentDeparture={(index, value) => {
                    formState.handleChangeSegment(index, 'departureDate', value)
                }}
                onSearch={() => {
                    if (formState.validate()) {
                        onSearch({
                            ...formState.values,
                            journeyUid,
                            airlineIataCodes,
                        })
                    }
                }}
            />
        </>
    )
}

export default QuickSearchSectionMobileFlightsWithState
