import React, { useEffect, useState } from 'react'
import PassengerSelectButton from '../utils/PassengerSelectButton'
import QuickSearchSectionMobileFlightsPassengersModal from './QuickSearchSectionMobileFlightsPassengersModal'

const QuickSearchSectionMobileFlightsPassengersSelect = ({
    errors,
    fareType,
    className,
    fareTypes,
    onConfirm,
    isSearching,
    numberOfAdults,
    numberOfInfants,
    numberOfChildren,
    minNumberOfTravelers,
    isOpen: isOpenFromProps,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasError = errors.length > 0
    const fareTypeOption = fareTypes.find(({ value }) => value === fareType) || null

    useEffect(() => {
        setIsOpen(isOpenFromProps)
    }, [isOpenFromProps])

    return (
        <>
            {isOpen && (
                <QuickSearchSectionMobileFlightsPassengersModal
                    fareTypes={fareTypes}
                    initialFareType={fareType}
                    onClose={() => setIsOpen(false)}
                    initialNumberOfAdults={numberOfAdults}
                    initialNumberOfInfants={numberOfInfants}
                    initialNumberOfChildren={numberOfChildren}
                    minNumberOfTravelers={minNumberOfTravelers}
                    onConfirm={(adults, children, infants, type) => {
                        onConfirm(adults, children, infants, type)
                        setIsOpen(false)
                    }}
                />
            )}
            <PassengerSelectButton
                hasError={hasError}
                className={className}
                disabled={isSearching}
                onClick={() => setIsOpen(true)}
                numberOfAdults={numberOfAdults}
                numberOfInfants={numberOfInfants}
                numberOfChildren={numberOfChildren}
            >
                {fareTypeOption.label}
            </PassengerSelectButton>
        </>
    )
}

export default QuickSearchSectionMobileFlightsPassengersSelect
