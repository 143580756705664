import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import SelectButton from '../utils/SelectButton'
import SearchListbox, { SearchListboxError, SearchListboxLoading } from '../utils/SearchListbox'
import QuickSearchSectionTravelFormulas from './QuickSearchSectionTravelFormulas'
import QuickSearchSectionTravelSelectPillValue from './QuickSearchSectionTravelSelectPillValue'

const QuickSearchSectionDesktopTravelFormulaSelect = ({
    error,
    testId,
    loading,
    formState,
    className,
    travelFormulas,
    travelThemes,
}) => {
    const placeholder = <Trans id="All travel formulas" />
    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)
    const { values } = formState
    const { formulas, themes } = values
    const count = formulas.length + themes.length
    const hasValues = count > 0
    const pillValues = [...formulas, ...themes]
    const pillOptions = [...travelFormulas, ...travelThemes]
    return (
        <div className={clsx('relative', className)}>
            <SelectButton
                hasError={error}
                data-testid={testId}
                isPlaceholder={false}
                onClick={() => setIsOpen(true)}
            >
                <div className="flex items-center">
                    <Icon
                        color="blue"
                        name="mountains"
                        className="mr-1.5 flex-shrink-0"
                    />
                    {hasValues
                        ? (
                            <QuickSearchSectionTravelSelectPillValue
                                options={pillOptions}
                                values={pillValues}
                                onReset={() => {
                                    formState.handleChange('formulas', [])
                                    formState.handleChange('themes', [])
                                }}
                                truncateValue={count > 2 && (
                                    <Trans
                                        id="<0>{count} {count, plural, =1 {travel formula} other {travel formulas}}</0> selected"
                                        values={{ count }}
                                    />
                                )}
                            />
                        )
                        : placeholder}
                </div>
            </SelectButton>
            {isOpen && (
                <SearchListbox
                    hasInput={false}
                    placeholder={placeholder}
                    testId={`${testId}Input`}
                    onPressEscape={handleClose}
                    onClickOutside={handleClose}
                    className="w-[500px] absolute z-10 -top-2 -left-2 p-2 rounded-md shadow-lg"
                    value={hasValues && (
                        <QuickSearchSectionTravelSelectPillValue
                            className="flex-wrap"
                            values={pillValues}
                            options={pillOptions}
                        />
                    )}
                    iconBeforeInput={(
                        <Icon
                            color="blue"
                            name="mountains"
                        />
                    )}
                >
                    {(() => {
                        if (error) {
                            return (
                                <SearchListboxError>
                                    <Trans id="Failed to fetch travel formulas." />
                                </SearchListboxError>
                            )
                        }
                        if (loading) {
                            return (
                                <SearchListboxLoading>
                                    <Trans id="Loading travel formulas..." />
                                </SearchListboxLoading>
                            )
                        }
                        if (travelFormulas?.length <= 0 && travelThemes?.length <= 0) {
                            return (
                                <SearchListboxError>
                                    <Trans id="No travel formulas available." />
                                </SearchListboxError>
                            )
                        }
                        return (
                            <QuickSearchSectionTravelFormulas
                                travelFormulas={travelFormulas}
                                travelThemes={travelThemes}
                                values={values}
                                onSelectDefault={() => {
                                    formState.handleChange('formulas', [])
                                    handleClose()
                                }}
                            />
                        )
                    })()}
                </SearchListbox>
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopTravelFormulaSelect
