import React from 'react'
import { useLingui, Trans } from '@lingui/react'
import useFormState, { createFormValidation } from 'use-form-state'
import {
    useQueryParams,
    StringParam,
    withDefault,
} from 'use-query-params'
import useAppState from '../hooks/useAppState'
import { isRequired } from '@connections/utils'

const validation = createFormValidation([{
    path: 'destination',
    validate: isRequired,
    message: <Trans id="The destination is required" />,
}])

export const useExperienceTypeOptions = () => {
    const { i18n } = useLingui()
    return [{
        value: '',
        label: i18n._(/*i18n*/'All types')
    }, {
        value: 'adventure',
        label: i18n._(/*i18n*/'Adventure')
    }, {
        value: 'romance',
        label: i18n._(/*i18n*/'Romance')
    }, {
        value: 'sports',
        label: i18n._(/*i18n*/'Sports')
    }, {
        value: 'culture',
        label: i18n._(/*i18n*/'Culture')
    }]
}

export const useExperienceSearchQueryParams = () => (
    useQueryParams({
        continentCode: withDefault(StringParam, null),
        countryCode: withDefault(StringParam, null),
        iataCode: withDefault(StringParam, null),
        type: withDefault(StringParam, null),
    })
)

const useQuickSearchExperienceFormState = () => {
    const { destinations } = useAppState()
    const [query] = useExperienceSearchQueryParams()
    const destination = destinations.find(({ value }) => (
        query.continentCode === value
        || query.countryCode === value
        || query.iataCode === value
    )) || null
    const formState = useFormState({
        destination,
        type: query.type || '',
    }, { validation })
    return formState
}

export default useQuickSearchExperienceFormState
