import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans, useLingui } from '@lingui/react'
import SwitchButton from '../utils/SwitchButton'
import QuickSearchSectionMobileDestinationButton from './QuickSearchSectionMobileDestinationButton'
import QuickSearchSectionMobileFlightsDestinationModal from './QuickSearchSectionMobileFlightsDestinationModal'

const QuickSearchSectionMobileFlightsDestinationButtons = ({
    errors,
    isPromo,
    className,
    canSearchTo,
    isSearching,
    canSearchFrom,
    toDestination,
    fromDestination,
    onSwitchFromAndTo,
    toDefaultIataCodes,
    fromDefaultIataCodes,
    onConfirmToDestination,
    onConfirmFromDestination,
    toLabel = <Trans id="To" />,
    fromLabel = <Trans id="From" />,
    toPlaceholder = <Trans id="Where to?" />,
    fromPlaceholder = <Trans id="Where from?" />,
}) => {
    const { i18n } = useLingui()
    const [isToOpen, setToOpen] = useState(false)
    const [isFromOpen, setFromOpen] = useState(false)
    const hasError = errors.length > 0
    const toInputDefaultIataCodes = canSearchTo && toDestination ? [] : toDefaultIataCodes
    const fromInputDefaultIataCodes = canSearchFrom && fromDestination ? [] : fromDefaultIataCodes

    return (
        <>
            {isFromOpen && (
                <QuickSearchSectionMobileFlightsDestinationModal
                    canSearch={canSearchFrom}
                    title={<Trans id="From" />}
                    onClose={() => setFromOpen(false)}
                    initialDestination={fromDestination}
                    inputPlaceholder={i18n._('Where from?')}
                    defaultIataCodes={fromInputDefaultIataCodes}
                    resultsPlaceholder={fromInputDefaultIataCodes.length > 0 ? '' : i18n._('Search an airport')}
                    onConfirm={(destination) => {
                        onConfirmFromDestination(destination)
                        setFromOpen(false)
                        setToOpen(true)
                    }}
                />
            )}
            {isToOpen && (
                <QuickSearchSectionMobileFlightsDestinationModal
                    canSearch={canSearchTo}
                    title={<Trans id="To" />}
                    onClose={() => setToOpen(false)}
                    initialDestination={toDestination}
                    inputPlaceholder={i18n._('Where to?')}
                    defaultIataCodes={toInputDefaultIataCodes}
                    listBoxTitle={!canSearchTo || toDestination ? null : <Trans id="Our top destinations" />}
                    resultsPlaceholder={toInputDefaultIataCodes.length > 0 ? '' : i18n._('Search an airport')}
                    onConfirm={(destination) => {
                        onConfirmToDestination(destination)
                        setToOpen(false)
                    }}
                />
            )}
            <div
                className={clsx(
                    'border w-full rounded relative',
                    hasError ? 'border-primary' : 'border-gray-100',
                    className,
                )}
            >
                <QuickSearchSectionMobileDestinationButton
                    label={fromLabel}
                    placeholder={fromPlaceholder}
                    isPlaceholder={!fromDestination}
                    onClick={() => setFromOpen(true)}
                    destination={fromDestination?.name}
                    data-testid="QuickSearchSectionMobileDestinationButtonFrom"
                    disabled={isSearching || (!canSearchFrom && fromInputDefaultIataCodes?.length === 0)}
                />
                {!isSearching && !isPromo && (
                    <SwitchButton
                        onClick={onSwitchFromAndTo}
                        className="absolute right-2 top-1/2 -translate-y-1/2 rotate-90"
                    />
                )}
                <hr className={clsx('border-t border-gray-100', !isPromo && 'mr-12')} />
                <QuickSearchSectionMobileDestinationButton
                    label={toLabel}
                    placeholder={toPlaceholder}
                    isPlaceholder={!toDestination}
                    onClick={() => setToOpen(true)}
                    destination={toDestination?.name}
                    data-testid="QuickSearchSectionMobileDestinationButtonTo"
                    disabled={isSearching || (!canSearchTo && toInputDefaultIataCodes?.length === 0)}
                />
            </div>
        </>
    )
}

export default QuickSearchSectionMobileFlightsDestinationButtons
