import React from 'react'
import TextLink from '../utils/TextLink'

const HeaderMobileTopBarMenuItem = ({
    href,
    iconBeforeName,
    iconAfterName,
    children
}) => (
    <li>
        <TextLink
            href={href}
            className="text-sm px-4 py-3 text-blue-bright"
            iconBeforeName={iconBeforeName}
            iconAfterName={iconAfterName}
        >
            {children}
        </TextLink>
    </li>
)

export default HeaderMobileTopBarMenuItem
