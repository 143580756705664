import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { variant } from '../../util/index'

const QuickSearchSectionMobileContainer = ({
    spacing,
    children,
    background,
    isCollapsed,
    isStandalone,
}) => {
    if (isCollapsed) {
        return children
    }

    return (
        <AnimatePresence>
            <motion.div
                transition={{ duration: 0.3 }}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                className={clsx(
                    'z-10 relative lg:hidden',
                    isStandalone && variant(spacing, {
                        smaller: 'py-8',
                        small: 'py-12',
                        medium: 'py-24',
                        large: 'py-36',
                    }),
                    isStandalone && variant(background, {
                        blue: 'bg-blue',
                        white: 'bg-white',
                        black: 'bg-black',
                        'gray-50': 'bg-gray-50',
                        'gray-100': 'bg-gray-100',
                    }),
                )}
            >
                <div className={clsx('px-4', !isStandalone && '-mt-14')}>
                    <div className="bg-white shadow-md rounded flex flex-col justify-between py-5 px-4">
                        {children}
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

export default QuickSearchSectionMobileContainer
