import { gql } from 'graphql-request'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query carRentalLocationsSearchResults($iataCodes: [String]!) {
        carRentalLocationsSearchResults(iataCodes: $iataCodes) {
            id
            name
            fullName
            countryName
            countryCode
            locationId
            airportCode
        }
    }
`

const useCarRentalLocationsSearchResults = (iataCodes = [], options) => {
    const variables = { iataCodes }
    const allOptions = {
        enabled: iataCodes.length > 0,
        ...options
    }
    const { data } = useGraphqlQuery('carRentalLocationsSearchResults', QUERY, variables, allOptions)
    return data?.carRentalLocationsSearchResults || []
}

export default useCarRentalLocationsSearchResults
