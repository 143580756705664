import React from 'react'
import Link from 'next/link'
import { Menu } from '@headlessui/react'
import { usePathname, useSearchParams } from 'next/navigation'
import { getSimpleLocaleFromPathname, whereFullLocale } from '@connections/utils'
import { getLinkLocale } from '../../util/routes'
import Icon from '../utils/Icon'
import useCurrentPageSlugs from '../hooks/useCurrentPageSlugs'
import QueryParamProvider from '../providers/QueryParamProvider'

const TopBarLanguageSelect = () => {
    const pathname = usePathname()
    const slugs = useCurrentPageSlugs()
    const searchParams = useSearchParams()
    const queryString = searchParams.toString()
    const currentLocale = getSimpleLocaleFromPathname(pathname)

    return (
        <Menu as="div" className="relative inline-flex items-center">
            <Menu.Button className="flex items-center mx-4 text-sm text-white">
                {currentLocale.toUpperCase()}
                <Icon name="chevron-down" />
            </Menu.Button>
            <Menu.Items className="absolute rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-header mt-24 p-1">
                {slugs.map(({ simpleLocale, fullLocale }) => {
                    const { path } = slugs.find(whereFullLocale(fullLocale))
                    const href = `/${getLinkLocale(fullLocale, path)}${path}${queryString ? `?${queryString}` : ''}`

                    return (
                        <Menu.Item
                            key={simpleLocale}
                            className="grid grid-cols-2 items-center rounded-md px-2 py-2 text-sm text-black hover:bg-blue-bright hover:text-white"
                        >
                            <Link href={href}>
                                {simpleLocale === currentLocale ? (
                                    <Icon size="small" name="check" className="mr-1" />
                                ) : <div />}
                                {simpleLocale}
                            </Link>
                        </Menu.Item>
                    )
                })}
            </Menu.Items>
        </Menu>
    )
}

export default (props) => (
    <QueryParamProvider>
        <TopBarLanguageSelect {...props} />
    </QueryParamProvider>
)
