import React from 'react'

export const formatCustomText = (
    text,
    {
        begin = /\{/g,
        end = /\}/g,
        className = '',
    }
) => {
    if (
        typeof text !== 'string'
        || typeof text.replace !== 'function'
    ) {
        return ''
    }
    return (
        text.replace(begin, `<span class="${className}">`)
            .replace(end, '</span>')
    )
}

const CustomText = ({
    as = 'span',
    beginSelector = '{',
    endSelector = '}',
    customText,
    customTextClassName,
    ...props
}) => {
    const Component = as
    return (
        <Component
            dangerouslySetInnerHTML={{
                __html: formatCustomText(
                    customText,
                    {
                        className: customTextClassName,
                        begin: beginSelector,
                        end: endSelector,
                    }
                )
            }}
            {...props}
        />
    )
}

export default CustomText
