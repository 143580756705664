import { useQuery } from 'react-query'
import usePathnameLocale from './usePathnameLocale'
import useStoryblokClient from './useStoryblokClient'

const useStoryblokQuery = (key, url, params = {}, options = {}, method = 'get') => {
    const locale = usePathnameLocale()
    const finalParams = {
        language: locale,
        ...params,
    }
    const storyblokClient = useStoryblokClient()
    return useQuery(
        [key, finalParams],
        () => storyblokClient[method](url, finalParams),
        options,
    )
}

export default useStoryblokQuery
