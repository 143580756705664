import React from 'react'
import Link from 'next/link'
import SbEditable from 'storyblok-react'
import Logo from '../utils/Logo'
import usePathnameLocale from '../hooks/usePathnameLocale'
import TopBar from './TopBar'
import HeaderMenuItem from './HeaderDesktopMenuItem'
import HeaderGlobalSearch from './HeaderDesktopGlobalSearch'
import AnnouncementBarSliderWithState from './AnnouncementBarSliderWithState'

const HeaderDesktop = ({
    content,
}) => {
    const fullLocale = usePathnameLocale(true)
    const {
        menuItems,
        contactText,
        topBarMenuItems,
    } = content

    return (
        <SbEditable content={content}>
            <div className="hidden lg:block z-header" data-testid="HeaderDesktop">
                <AnnouncementBarSliderWithState />
                <TopBar
                    contactText={contactText}
                    menuItems={topBarMenuItems}
                />
                <div className="bg-white border border-gray-100 relative">
                    <div className="flex container items-center">
                        <Link href={`/${fullLocale}`}>
                            <Logo className="h-11 mr-6" />
                        </Link>
                        <div className="flex flex-1">
                            <nav className="mr-auto">
                                <ul className="flex items-center">
                                    {menuItems.map((item) => (
                                        <HeaderMenuItem
                                            content={item}
                                            key={item._uid}
                                        />
                                    ))}
                                </ul>
                            </nav>
                            <nav className="flex items-center">
                                <ul>
                                    <li>
                                        <HeaderGlobalSearch />
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </SbEditable>
    )
}

export default HeaderDesktop
