/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
    getDayIsDisabledCondition,
    getPreviousSegmentDepartureDate,
    getMultiSelectDayIsDisabledCondition,
} from '@connections/utils'
import { useLingui, Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '../utils/Button'
import RadioInput from '../utils/RadioInput'
import SwitchButton from '../utils/SwitchButton'
import CheckboxInput from '../utils/CheckboxInput'
import useFlightSearchExperiment from '../hooks/useFlightSearchExperiment'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'
import QuickSearchSectionDesktopDestinationSelect from './QuickSearchSectionDesktopDestinationSelect'
import QuickSearchSectionDesktopFlightsDateSelect from './QuickSearchSectionDesktopFlightsDateSelect'
import QuickSearchSectionDesktopFlightsExtraSegment from './QuickSearchSectionDesktopFlightsExtraSegment'
import QuickSearchSectionDesktopFlightsDateRangeSelect from './QuickSearchSectionDesktopFlightsDateRangeSelect'
import QuickSearchSectionDesktopFlightsPassengersSelect from './QuickSearchSectionDesktopFlightsPassengersSelect'

const {
    FLIGHT_MODE_ONE_WAY: ONE_WAY,
    FLIGHT_MODE_ROUND_TRIP: ROUND_TRIP,
    FLIGHT_MODE_MULTI_CITY: MULTI_CITY,
} = constants

const QuickSearchSectionDesktopFlights = ({
    uid,
    isPromo,
    onSearch,
    isLoading,
    formState,
    fareTypes,
    isSearching,
    canSearchTo,
    hasChosenTo,
    hasChosenFrom,
    canSearchFrom,
    onChangeDates,
    hasChosenDates,
    onChangeFareType,
    onAddExtraSegment,
    toDefaultIataCodes,
    onChangeFlightMode,
    onRemoveExtraSegment,
    hasOnlyDepartureDate,
    minNumberOfTravelers,
    fromDefaultIataCodes,
    onChangeToDestination,
    onChangeNumberOfAdults,
    onChangeNumberOfInfants,
    onChangeFromDestination,
    onChangeNumberOfChildren,
    onChangeExtraSegmentDeparture,
    onChangeExtraSegmentToDestination,
    onChangeExtraSegmentFromDestination,
}) => {
    const { i18n } = useLingui()
    const { stopsInQsm, luggageInQsm } = useFlightSearchExperiment()
    const { values, errors, handleChange, handleSwitchFromAndTo } = formState
    const {
        fareType,
        flightMode,
        returnDate,
        toDestination,
        extraSegments,
        departureDate,
        alsoFindHotels,
        includeLuggage,
        isDirectFlight,
        numberOfAdults,
        fromDestination,
        numberOfInfants,
        numberOfChildren,
    } = values
    const count = extraSegments.length
    const uidStr = uid ? `-${uid}` : ''

    return (
        <div data-testid="QuickSearchSectionDesktopFlights">
            <div className="pb-3 flex items-center">
                <RadioInput
                    disabled={isSearching}
                    className="text-sm mr-8"
                    name={`flight-mode${uidStr}`}
                    id={`radio-${ROUND_TRIP}${uidStr}`}
                    data-testid={`radio-${ROUND_TRIP}`}
                    value={!isLoading && flightMode === ROUND_TRIP}
                    onChange={() => onChangeFlightMode(ROUND_TRIP)}
                >
                    <Trans id="Round trip" />
                </RadioInput>
                <RadioInput
                    disabled={isSearching}
                    className="text-sm mr-8"
                    name={`flight-mode${uidStr}`}
                    id={`radio-${ONE_WAY}${uidStr}`}
                    data-testid={`radio-${ONE_WAY}`}
                    value={!isLoading && flightMode === ONE_WAY}
                    onChange={() => onChangeFlightMode(ONE_WAY)}
                >
                    <Trans id="One way" />
                </RadioInput>
                {!isPromo && (
                    <RadioInput
                        className="text-sm"
                        disabled={isSearching}
                        name={`flight-mode${uidStr}`}
                        value={flightMode === MULTI_CITY}
                        id={`radio-${MULTI_CITY}${uidStr}`}
                        data-testid={`radio-${MULTI_CITY}`}
                        onChange={() => onChangeFlightMode(MULTI_CITY)}
                    >
                        <Trans id="Multi-city" />
                    </RadioInput>
                )}
            </div>
            <div className="flex flex-wrap gap-4">
                <div className="flex relative gap-4">
                    <QuickSearchSectionDesktopInputGroup
                        htmlFor={`from${uidStr}`}
                        label={<Trans id="From" />}
                        className="mt-3 flex-1 min-w-[14rem]"
                    >
                        <QuickSearchSectionDesktopDestinationSelect
                            id={`from${uidStr}`}
                            isLoading={isLoading}
                            disabled={isSearching}
                            selectClassName="!pr-4"
                            canSearch={canSearchFrom}
                            destination={fromDestination}
                            onChange={onChangeFromDestination}
                            defaultIataCodes={fromDefaultIataCodes}
                            inputPlaceholder={i18n._(/*i18n*/ 'From where?')}
                            resultsPlaceholder={
                                <Trans id="Search an airport" />
                            }
                            testId="QuickSearchSectionDesktopDestinationSelectFrom"
                            errors={errors.filter(
                                ({ path }) => path === 'fromDestination'
                            )}
                        />
                    </QuickSearchSectionDesktopInputGroup>
                    {!isPromo && !isSearching && (
                        <SwitchButton
                            onClick={() => handleSwitchFromAndTo()}
                            className="self-end absolute bottom-2 left-1/2 -translate-x-1/2 z-1"
                        />
                    )}
                    <QuickSearchSectionDesktopInputGroup
                        htmlFor={`to${uidStr}`}
                        label={<Trans id="To" />}
                        className="mt-3 flex-1 min-w-[14rem]"
                    >
                        <QuickSearchSectionDesktopDestinationSelect
                            id={`to${uidStr}`}
                            isLoading={isLoading}
                            disabled={isSearching}
                            isOpen={hasChosenFrom}
                            canSearch={canSearchTo}
                            selectClassName="pl-4 h-12"
                            destination={toDestination}
                            onChange={onChangeToDestination}
                            inputPlaceholder={i18n._(/*i18n*/ 'Where to?')}
                            resultsPlaceholder={
                                <Trans id="Search an airport" />
                            }
                            testId="QuickSearchSectionDesktopDestinationSelectTo"
                            errors={errors.filter(
                                ({ path }) => path === 'toDestination'
                            )}
                            defaultIataCodes={
                                canSearchTo && toDestination
                                    ? []
                                    : toDefaultIataCodes
                            }
                            title={
                                !canSearchTo || toDestination ? null : (
                                    <Trans id="Our top destinations" />
                                )
                            }
                        />
                    </QuickSearchSectionDesktopInputGroup>
                </div>
                <QuickSearchSectionDesktopInputGroup
                    htmlFor={`departure${uidStr}`}
                    label={
                        hasOnlyDepartureDate ? (
                            <Trans id="Departure" />
                        ) : (
                            <Trans id="Departure & Return" />
                        )
                    }
                    className="mt-3 flex-1 min-w-[13rem]"
                >
                    {(() => {
                        if (hasOnlyDepartureDate) {
                            return (
                                <QuickSearchSectionDesktopFlightsDateSelect
                                    date={departureDate}
                                    isOpen={hasChosenTo}
                                    isLoading={isLoading}
                                    disabled={isSearching}
                                    id={`departure${uidStr}`}
                                    onChange={(date) => {
                                        onChangeDates(date, null)
                                    }}
                                    dayIsDisabledCondition={
                                        getDayIsDisabledCondition
                                    }
                                    errors={errors.filter(
                                        ({ path }) => path === 'departureDate'
                                    )}
                                />
                            )
                        }
                        return (
                            <QuickSearchSectionDesktopFlightsDateRangeSelect
                                isLoading={isLoading}
                                disabled={isSearching}
                                returnDate={returnDate}
                                onChange={onChangeDates}
                                id={`departure${uidStr}`}
                                departureDate={departureDate}
                                isOpen={hasChosenFrom && hasChosenTo}
                                labelTextLastSelected={<Trans id="Return" />}
                                labelTextFirstSelected={
                                    <Trans id="Departure" />
                                }
                                dayIsDisabledCondition={
                                    getDayIsDisabledCondition
                                }
                                errors={errors.filter(
                                    ({ path }) =>
                                        path === 'departureDate' ||
                                        path === 'returnDate'
                                )}
                            />
                        )
                    })()}
                </QuickSearchSectionDesktopInputGroup>
                <QuickSearchSectionDesktopInputGroup
                    htmlFor={`travelers${uidStr}`}
                    className="mt-3 flex-1 min-w-[10rem]"
                    label={<Trans id="Travelers & Class" />}
                >
                    <QuickSearchSectionDesktopFlightsPassengersSelect
                        fareType={fareType}
                        fareTypes={fareTypes}
                        isLoading={isLoading}
                        disabled={isSearching}
                        isOpen={hasChosenDates}
                        id={`travelers${uidStr}`}
                        numberOfAdults={numberOfAdults}
                        numberOfInfants={numberOfInfants}
                        numberOfChildren={numberOfChildren}
                        onChangeFareType={onChangeFareType}
                        minNumberOfTravelers={minNumberOfTravelers}
                        onChangeNumberOfAdults={onChangeNumberOfAdults}
                        onChangeNumberOfInfants={onChangeNumberOfInfants}
                        onChangeNumberOfChildren={onChangeNumberOfChildren}
                        errors={errors.filter(
                            ({ path }) =>
                                path === 'fareTypes' ||
                                path === 'numberOfAdults' ||
                                path === 'numberOfInfants' ||
                                path === 'numberOfChildren'
                        )}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <div className="mt-3 flex-1 flex items-end">
                    <Button
                        size="large"
                        onClick={onSearch}
                        className="flex-1"
                        iconBeforeName="search"
                        variant="primary-filled"
                        isLoading={isSearching}
                        data-testid="QuickSearchSectionDesktopFlightsSearchButton"
                    >
                        <Trans id="Search flights" />
                    </Button>
                </div>
            </div>
            <AnimatePresence key={flightMode}>
                {flightMode === MULTI_CITY && (
                    <motion.div
                        initial={{ height: 0 }}
                        animate={{ height: 'auto' }}
                        transition={{ type: 'tween' }}
                    >
                        {extraSegments.map((segment, index) => (
                            <QuickSearchSectionDesktopFlightsExtraSegment
                                key={index}
                                index={index}
                                isSearching={isSearching}
                                toDestination={segment.toDestination}
                                departureDate={segment.departureDate}
                                numberOfSegments={extraSegments.length}
                                fromDestination={segment.fromDestination}
                                onRemove={() => onRemoveExtraSegment(index)}
                                onSwitchFromAndTo={() => {
                                    handleSwitchFromAndTo(index)
                                }}
                                errors={errors.filter(({ path }) =>
                                    path.includes(`extraSegments.${index}`)
                                )}
                                dayIsDisabledCondition={(day) =>
                                    getMultiSelectDayIsDisabledCondition(
                                        day,
                                        index,
                                        departureDate,
                                        extraSegments
                                    )
                                }
                                onChangeToDestination={(to) => {
                                    onChangeExtraSegmentToDestination(index, to)
                                }}
                                onChangeFromDestination={(from) => {
                                    onChangeExtraSegmentFromDestination(
                                        index,
                                        from
                                    )
                                }}
                                onChangeDeparture={(departure) => {
                                    onChangeExtraSegmentDeparture(
                                        index,
                                        departure
                                    )
                                }}
                                viewDate={getPreviousSegmentDepartureDate(
                                    index,
                                    departureDate,
                                    extraSegments
                                )}
                            />
                        ))}
                        <Button
                            variant="link"
                            iconBeforeName="plus"
                            onClick={onAddExtraSegment}
                            className="text-blue-bright"
                            disabled={isSearching || count >= 3}
                            data-testid="QuickSearchSectionDesktopFlightsAddFlightButton"
                        >
                            <Trans id="Add another flight" />
                        </Button>
                    </motion.div>
                )}
            </AnimatePresence>
            <div className="flex gap-x-4 mt-4">
                {stopsInQsm ? (
                    <CheckboxInput
                        className="text-sm"
                        disabled={isSearching}
                        value={isDirectFlight}
                        onChange={() => {
                            handleChange('isDirectFlight', !isDirectFlight)
                        }}
                    >
                        <Trans id="Show only direct flights" />
                    </CheckboxInput>
                ) : null}
                {luggageInQsm ? (
                    <CheckboxInput
                        className="text-sm"
                        disabled={isSearching}
                        value={includeLuggage}
                        onChange={() => {
                            handleChange('includeLuggage', !includeLuggage)
                        }}
                    >
                        <Trans id="I’m travelling with checked luggage" />
                    </CheckboxInput>
                ) : null}
                <CheckboxInput
                    className="text-sm"
                    disabled={isSearching}
                    value={alsoFindHotels}
                    onChange={() => {
                        handleChange('alsoFindHotels', !alsoFindHotels)
                    }}
                >
                    <Trans id="Also find hotels" />
                </CheckboxInput>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopFlights
