import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import QuickSearchSectionTravelSelectPillValue from './QuickSearchSectionTravelSelectPillValue'
import QuickSearchSectionMobileTravelPeriodModal from './QuickSearchSectionMobileTravelPeriodModal'
import QuickSearchSectionMobileDestinationButton from './QuickSearchSectionMobileDestinationButton'

const QuickSearchSectionMobileTravelPeriodSelect = ({
    error,
    months,
    loading,
    vacations,
    formState,
    className,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const { values } = formState
    const selectedPeriods = [...values.months, ...values.vacations]
    const allOptions = vacations ? [...months, ...vacations] : months
    const hasValues = selectedPeriods.length > 0
    return (
        <>
            {isOpen && (
                <QuickSearchSectionMobileTravelPeriodModal
                    error={error}
                    months={months}
                    loading={loading}
                    formState={formState}
                    hasValues={hasValues}
                    vacations={vacations}
                    onClose={() => setIsOpen(false)}
                />
            )}
            <div
                className={clsx(
                    'w-full min-h-[56px] flex flex-col justify-center border rounded',
                    error && 'border-primary',
                    !error && 'border-gray-100',
                    className,
                )}
            >
                <QuickSearchSectionMobileDestinationButton
                    label={<Trans id="When" />}
                    isPlaceholder={!hasValues}
                    onClick={() => setIsOpen(true)}
                    placeholder={<Trans id="All travel dates" />}
                    destination={hasValues && (
                        <QuickSearchSectionTravelSelectPillValue
                            options={allOptions}
                            values={selectedPeriods}
                            onReset={() => {
                                formState.handleChange('months', [])
                                formState.handleChange('vacations', [])
                            }}
                            truncateValue={selectedPeriods.length > 2 && (
                                <Trans
                                    id="<0>{count} {count, plural, =1 {period} other {periods}}</0> selected"
                                    values={{ count: selectedPeriods.length }}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>
    )
}

export default QuickSearchSectionMobileTravelPeriodSelect
