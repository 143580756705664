/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import useScreenSize from '../hooks/useScreenSize'
import QuickSearchSectionMobile from './QuickSearchSectionMobile'
import QuickSearchSectionDesktop from './QuickSearchSectionDesktop'

const QuickSearchSection = ({
    anchorId,
    onScrollIntoView,
    ...props
}) => {
    const { isSm, isMd } = useScreenSize()
    const isMobile = isSm || isMd

    return (
        <section
            id={anchorId}
            onClick={onScrollIntoView}
            data-testid="QuickSearchSection"
        >
            {isMobile
                ? <QuickSearchSectionMobile {...props} />
                : <QuickSearchSectionDesktop {...props} />}
        </section>
    )
}

export default QuickSearchSection
