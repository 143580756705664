import React from 'react'
import SbEditable from 'storyblok-react'
import Icon from '../utils/Icon'
import FooterLink from './FooterLink'

const FooterMobileMenuItem = ({
    content,
    isOpen,
    onToggle,
}) => {
    const { title, links } = content
    return (
        <SbEditable content={content}>
            <li>
                <button
                    type="button"
                    className="py-6 w-full text-left flex justify-between"
                    onClick={() => onToggle(content)}
                >
                    <span className="uppercase text-xs text-white font-medium tracking-widest pl-3 border-l-3 border-white">
                        {title}
                    </span>
                    <Icon name="chevron-right" />
                </button>
                {isOpen && (
                    <ul className="pb-8">
                        {links.map((link) => (
                            <FooterLink
                                key={link._uid}
                                content={link}
                            />
                        ))}
                    </ul>
                )}
            </li>
        </SbEditable>
    )
}

export default FooterMobileMenuItem
