import React, { useMemo } from 'react'
import { Trans } from '@lingui/react'
import debounce from 'lodash.debounce'
import useSearchAirports from '../hooks/useSearchAirports'
import useAirportSearchResults from '../hooks/useAirportSearchResults'
import SearchListbox, {
    SearchListboxEmpty,
    SearchListboxLoading,
    SearchListboxError,
    SearchListboxResults
} from './SearchListbox'
import SearchListItem from './SearchListItem'

const getIconName = ({
    isMetropolitan,
    isTrainStation
}) => {
    if (isMetropolitan) {
        return 'sign-right'
    }
    if (isTrainStation) {
        return 'train'
    }
    return 'flight'
}

const SearchAirportsListBox = ({
    title,
    testId,
    onChange,
    className,
    canSearch,
    destination,
    onPressEscape,
    onClickOutside,
    defaultIataCodes,
    listboxClassName,
    inputPlaceholder,
    resultsPlaceholder,
}) => {
    const {
        data,
        error,
        isLoading,
        mutate: searchDestinations,
    } = useSearchAirports()
    const destinations = useAirportSearchResults(defaultIataCodes)

    const onSearch = async (e) => {
        const { value = '' } = e.target
        if (value.length >= 3) {
            const variables = { query: value }
            searchDestinations(variables)
        }
    }
    const debouncedOnSearch = useMemo(() => debounce(onSearch, 300), [])

    return (
        <SearchListbox
            canSearch={canSearch}
            className={className}
            testId={`${testId}Input`}
            onChange={debouncedOnSearch}
            onPressEscape={onPressEscape}
            placeholder={inputPlaceholder}
            onClickOutside={onClickOutside}
        >
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to find airports." />
                        </SearchListboxError>
                    )
                }
                if (isLoading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Searching airports..." />
                        </SearchListboxLoading>
                    )
                }
                if (!data) {
                    if (destinations.length > 0) {
                        return (
                            <SearchListboxResults
                                className={listboxClassName}
                                data-testid={`${testId}List`}
                            >
                                {title ? (
                                    <div className="text-xs text-gray-600 font-medium uppercase mb-4 ml-2 mt-2">
                                        {title}
                                    </div>
                                ) : null}
                                {destinations.map((dest) => (
                                    <SearchListItem
                                        key={dest.id}
                                        iconName={getIconName(dest)}
                                        onClick={() => onChange(dest)}
                                        isSelected={destination && dest.code === destination.code}
                                    >
                                        {dest.fullName} ({dest.code})
                                    </SearchListItem>
                                ))}
                            </SearchListboxResults>
                        )
                    }
                    if (destination) {
                        return (
                            <SearchListboxResults
                                className={listboxClassName}
                                data-testid={`${testId}List`}
                            >
                                <SearchListItem
                                    isSelected
                                    iconName={getIconName(destination)}
                                    onClick={() => onChange(destination)}
                                >
                                    {destination.fullName} ({destination.code})
                                </SearchListItem>
                            </SearchListboxResults>
                        )
                    }
                    return (
                        <SearchListboxEmpty>
                            {resultsPlaceholder}
                        </SearchListboxEmpty>
                    )
                }
                const airports = data.searchAirports.nodes
                return (
                    <SearchListboxResults
                        className={listboxClassName}
                        data-testid={`${testId}List`}
                    >
                        {airports.map((airport) => {
                            const isPartOfGroup = airports.filter(({ cityCode }) => (
                                cityCode === airport.cityCode
                            )).length > 1
                            return (
                                <SearchListItem
                                    key={airport.id}
                                    iconName={getIconName(airport)}
                                    onClick={() => onChange(airport)}
                                    isSelected={destination && airport.id === destination.id}
                                    indentLevel={(isPartOfGroup && !airport.isMetropolitan) ? 1 : 0}
                                >
                                    {airport.fullName} ({airport.code})
                                </SearchListItem>
                            )
                        })}
                    </SearchListboxResults>
                )
            })()}
        </SearchListbox>
    )
}

export default SearchAirportsListBox
