import React from 'react'
import IconPayment from '../utils/IconPayment'

const FooterPaymentIcons = () => (
    <div className="flex items-center space-x-2 md:space-x-6 mb-4">
        <IconPayment name="america-express" className="h-8" />
        <IconPayment name="bancontact" className="h-7" />
        <IconPayment name="ideal" className="h-8" />
        <IconPayment name="kbc" className="h-8" />
        <IconPayment name="maestro" className="h-8" />
        <IconPayment name="mastercard" className="h-8" />
        <IconPayment name="visa" className="h-12" />
    </div>
)

export default FooterPaymentIcons
