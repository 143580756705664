import React, { forwardRef } from 'react'
import { Trans } from '@lingui/react'
import SelectButton from './SelectButton'

const PassengerSelectButton = forwardRef(({
    numberOfAdults,
    numberOfChildren,
    numberOfRooms,
    numberOfInfants,
    children,
    ...props
}, ref) => (
    <SelectButton
        ref={ref}
        data-testid="PassengerSelectButton"
        {...props}
    >
        {(() => {
            const hasChildren = numberOfChildren > 0 || numberOfInfants > 0
            if (!hasChildren && numberOfAdults > 0) {
                return (
                    <span className="mr-1">
                        {numberOfAdults}
                        {' '}
                        {(numberOfAdults === 1
                            ? <Trans id="Adult" />
                            : <Trans id="Adults" />
                        )}
                    </span>
                )
            }
            return (
                <span className="mr-1">
                    {numberOfAdults + numberOfChildren + numberOfInfants}
                    {' '}
                    <Trans id="Passengers" />
                </span>
            )
        })()}

        {numberOfRooms > 1 && (
            <span className="mr-1">
                {', '}
                {numberOfRooms}
                {' '}
                {(numberOfRooms === 1
                    ? <Trans id="Room" />
                    : <Trans id="Rooms" />
                )}
            </span>
        )}
        {children && (
            <>{', '} {children}</>
        )}
    </SelectButton>
))

export default PassengerSelectButton
