import clsx from 'clsx'
import React from 'react'
import { useLingui, Trans } from '@lingui/react'
import Button from '../utils/Button'
import CheckboxInput from '../utils/CheckboxInput'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'
import QuickSearchSectionDesktopCarsTimesSelect from './QuickSearchSectionDesktopCarsTimesSelect'
import QuickSearchSectionDesktopDestinationSelect from './QuickSearchSectionDesktopDestinationSelect'
import QuickSearchSectionDesktopFlightsDateRangeSelect from './QuickSearchSectionDesktopFlightsDateRangeSelect'

const QuickSearchSectionDesktopCars = ({
    uid,
    onSearch,
    formState,
    onChangeDates,
    onChangeTimes,
    onChangePickUpDestination,
    onChangeDropOffDestination,
    onChangePickupIsSameAsDropOff,
}) => {
    const { i18n } = useLingui()
    const { values, errors } = formState
    const {
        pickUpTime,
        pickUpDate,
        dropOffDate,
        dropOffTime,
        pickUpDestination,
        dropOffDestination,
        pickupIsSameAsDropOff,
    } = values

    return (
        <div>
            <div className="flex flex-wrap space-x-4">
                <QuickSearchSectionDesktopInputGroup
                    label={<Trans id="Pick-up" />}
                    htmlFor={`cars-pickupDestination-${uid}`}
                    className={clsx(
                        'flex-1',
                        pickupIsSameAsDropOff ? 'min-w-[22rem]' : 'min-w-[13rem]',
                    )}
                >
                    <QuickSearchSectionDesktopDestinationSelect
                        variant="car-rental-locations"
                        destination={pickUpDestination}
                        id={`cars-pickupDestination-${uid}`}
                        onChange={onChangePickUpDestination}
                        inputPlaceholder={i18n._(/*i18n*/'Pick-up location')}
                        testId="QuickSearchSectionDesktopCarPickupDestinationSelect"
                        resultsPlaceholder={<Trans id="Search a pick-up location" />}
                        errors={errors.filter(({ path }) => path === 'pickUpDestination')}
                    />
                </QuickSearchSectionDesktopInputGroup>
                {!pickupIsSameAsDropOff && (
                    <QuickSearchSectionDesktopInputGroup
                        label={<Trans id="Drop-off" />}
                        className="flex-1 min-w-[13rem]"
                        htmlFor={`cars-dropOffDestination-${uid}`}
                    >
                        <QuickSearchSectionDesktopDestinationSelect
                            variant="car-rental-locations"
                            destination={dropOffDestination}
                            id={`cars-dropOffDestination-${uid}`}
                            onChange={onChangeDropOffDestination}
                            inputPlaceholder={i18n._(/*i18n*/'Drop-off location')}
                            resultsPlaceholder={<Trans id="Search a drop-off location" />}
                            errors={errors.filter(({ path }) => path === 'dropOffDestination')}
                        />
                    </QuickSearchSectionDesktopInputGroup>
                )}
                <QuickSearchSectionDesktopInputGroup
                    label={<Trans id="Dates" />}
                    className="flex-1 min-w-[13rem]"
                    htmlFor={`cars-departure-${uid}`}
                >
                    <QuickSearchSectionDesktopFlightsDateRangeSelect
                        returnDate={dropOffDate}
                        onChange={onChangeDates}
                        departureDate={pickUpDate}
                        id={`cars-departure-${uid}`}
                        labelTextLastSelected={<Trans id="Drop" />}
                        labelTextFirstSelected={<Trans id="Pickup" />}
                        errors={errors.filter(({ path }) => (
                            path === 'pickUpDate'
                            || path === 'dropOffDate'
                        ))}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <QuickSearchSectionDesktopInputGroup
                    label={<Trans id="Times" />}
                    className="flex-1 min-w-[10rem]"
                    htmlFor={`cars-departure-${uid}`}
                >
                    <QuickSearchSectionDesktopCarsTimesSelect
                        pickUpTime={pickUpTime}
                        onChange={onChangeTimes}
                        dropOffTime={dropOffTime}
                        id={`cars-departure-${uid}`}
                        errors={errors.filter(({ path }) => (
                            path === 'pickUpTime'
                            || path === 'dropOffTime'
                        ))}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <div className="flex-1 flex items-end min-w-[13rem]">
                    <Button
                        size="large"
                        className="w-full"
                        onClick={onSearch}
                        iconBeforeName="search"
                        variant="primary-filled"
                        data-testid="QuickSearchSectionDesktopCarsSearchButton"
                    >
                        <Trans id="Search cars" />
                    </Button>
                </div>
            </div>
            <div className="mt-4">
                <CheckboxInput
                    className="text-sm"
                    value={pickupIsSameAsDropOff}
                    id={`cars-pickupIsSameAsDropOff-${uid}`}
                    onChange={onChangePickupIsSameAsDropOff}
                >
                    <Trans id="Pick up location same as drop off location" />
                </CheckboxInput>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopCars
