import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import SearchAirportsListBox from '../utils/SearchAirportsListBox'

const QuickSearchSectionMobileFlightsDestinationModal = ({
    title,
    onClose,
    onConfirm,
    listBoxTitle,
    canSearch = true,
    defaultIataCodes,
    inputPlaceholder,
    resultsPlaceholder,
    initialDestination,
    ...props
}) => {
    const [selectedDestination, setSelectedDestination] = useState(initialDestination)

    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={title}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <SearchAirportsListBox
                    title={listBoxTitle}
                    canSearch={canSearch}
                    destination={selectedDestination}
                    inputPlaceholder={inputPlaceholder}
                    defaultIataCodes={defaultIataCodes}
                    resultsPlaceholder={resultsPlaceholder}
                    className="flex-1 flex flex-col h-full"
                    listboxClassName="h-[500px] overflow-y-scroll" // TODO how can we make the height fill
                    testId="QuickSearchSectionMobileFlightsSelect"
                    onChange={(dest) => {
                        setSelectedDestination(dest)
                        onConfirm(dest)
                    }}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    onClick={onClose}
                    className="mr-4 w-full"
                    variant="blue-outlined"
                >
                    <Trans id="Close" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default QuickSearchSectionMobileFlightsDestinationModal
