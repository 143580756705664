import React from 'react'
import SbEditable from 'storyblok-react'
import TextLink from '../utils/TextLink'

const HeaderDesktopMenuItemDropdownLink = ({
    content,
}) => {
    const { href, text, openInNewTab } = content
    return (
        <li className="mb-2">
            <SbEditable content={content}>
                <TextLink
                    href={href}
                    openInNewTab={openInNewTab}
                    className="px-2 -ml-2 hover:underline"
                >
                    {text}
                </TextLink>
            </SbEditable>
        </li>
    )
}

export default HeaderDesktopMenuItemDropdownLink
