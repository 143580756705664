import { i18n } from '@lingui/core'
import {
    VARIANT_BLUE_FILLED,
    VARIANT_PRIMARY_FILLED,
    SIZE_LARGE,
} from '../components/utils/Tag'
import * as constants from '@connections/constants'
import {
    formatFullSlug,
    getHrefFromLink,
} from './index'

const {
    TOUR_CARD,
    BREAK_CARD,
    HOTEL_CARD,
    ACTIVITY_CARD,
} = constants

const getDefaultEntityTag = (component) => {
    switch (component) {
        case ACTIVITY_CARD:
            return {
                text: i18n._(/*i18n*/'Activity'),
                variant: VARIANT_BLUE_FILLED,
                size: SIZE_LARGE
            }
        case BREAK_CARD:
            return {
                text: i18n._(/*i18n*/'Break'),
                variant: VARIANT_BLUE_FILLED,
                size: SIZE_LARGE
            }
        case HOTEL_CARD:
            return {
                text: i18n._(/*i18n*/'Hotel'),
                variant: VARIANT_PRIMARY_FILLED,
                size: SIZE_LARGE

            }
        case TOUR_CARD:
            return {
                text: i18n._(/*i18n*/'Tour'),
                variant: VARIANT_BLUE_FILLED,
                size: SIZE_LARGE
            }
        default:
            return {}
    }
}

export const getEntityContentOverrides = (overwriteContent, entity, locale) => {
    const tag = getDefaultEntityTag(overwriteContent.component)
    if (overwriteContent?.tag?.length > 0) {
        const overwriteTag = overwriteContent.tag[0]
        if (overwriteTag.variant) {
            tag.variant = overwriteTag.variant
        }
        if (overwriteTag.size) {
            tag.size = overwriteTag.size
        }
        if (overwriteTag.text) {
            tag.text = overwriteTag.text
        }
    }
    if (!entity?.content) {
        let href
        if (overwriteContent.link) {
            href = getHrefFromLink(overwriteContent.link, locale)
        }
        return {
            ...overwriteContent,
            tag,
            href,
        }
    }
    let image = {}
    if (entity.content.headImage && entity.content.headImage.id !== null) {
        image = entity.content.headImage
    }
    if (typeof overwriteContent.image !== 'undefined' && overwriteContent.image.id !== null) {
        image = overwriteContent.image
    }
    let video = {}
    if (entity.content.headVideo && entity.content.headVideo.id !== null) {
        video = entity.content.headVideo
    }
    if (typeof overwriteContent.video !== 'undefined' && overwriteContent.video.id !== null) {
        video = overwriteContent.video
    }
    let href = formatFullSlug(entity.full_slug, { locale })
    if (overwriteContent.link && overwriteContent.link.url) {
        href = getHrefFromLink(overwriteContent.link, locale)
    }
    let { stars } = entity.content
    if (overwriteContent.stars) {
        stars = overwriteContent.stars
    }
    let iataCode = null
    let destination = null
    if (entity.content.iataCodes && entity.content.iataCodes.length > 0) {
        [iataCode] = entity.content.iataCodes
    }
    if (overwriteContent.destination) {
        iataCode = null;
        ({ destination } = overwriteContent)
    }
    return {
        tag,
        href,
        image,
        video,
        videoStartOffset: overwriteContent.videoStartOffset,
        videoEndOffset: overwriteContent.videoEndOffset,
        stars,
        iataCode,
        destination,
        title: overwriteContent.title || entity.content.title,
        description: overwriteContent.description || entity.content.description,
    }
}

export const getEntityContentPrice = (entity) => {
    if (entity === null || typeof entity?.content === 'undefined') {
        return null
    }
    const { price } = entity.content
    return price
}
