import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import SelectButton from '../utils/SelectButton'
import SearchListbox, { SearchListboxError, SearchListboxLoading } from '../utils/SearchListbox'
import QuickSearchSectionTravelDestinations from './QuickSearchSectionTravelDestinations'
import QuickSearchSectionTravelSelectPillValue from './QuickSearchSectionTravelSelectPillValue'

export const getAllOptions = (countries, continents) => {
    let options = []
    if (continents) {
        options = continents
    }
    if (countries) {
        options = [...options, ...countries]
    }
    return options
}

const QuickSearchSectionDesktopTravelDestinationSelect = ({
    error,
    testId,
    loading,
    formState,
    className,
    countries,
    continents,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleClose = () => setIsOpen(false)

    const { values } = formState
    const hasValues = values.continentCodes.length > 0 || values.countryCode !== ''
    const selectedDestinations = [...values.continentCodes]
    if (values.countryCode !== '') {
        selectedDestinations.push(values.countryCode)
    }
    const allOptions = getAllOptions(countries, continents)
    const placeholder = <Trans id="All destinations" />

    return (
        <div className={clsx('relative', className)}>
            <SelectButton
                hasError={error}
                data-testid={testId}
                isPlaceholder={false}
                onClick={() => setIsOpen(true)}
            >
                <div className="flex items-center">
                    <Icon
                        color="blue"
                        className="mr-1.5"
                        name="location-point"
                    />
                    {hasValues
                        ? (
                            <QuickSearchSectionTravelSelectPillValue
                                options={allOptions}
                                values={selectedDestinations}
                                onReset={() => {
                                    formState.handleChange('countryCode', '')
                                    formState.handleChange('continentCodes', [])
                                }}
                                truncateValue={selectedDestinations.length > 2 && (
                                    <Trans
                                        id="<0>{count} {count, plural, =1 {destination} other {destinations}}</0> selected"
                                        values={{ count: selectedDestinations.length }}
                                    />
                                )}
                            />
                        )
                        : placeholder}
                </div>
            </SelectButton>
            {isOpen && (
                <SearchListbox
                    hasInput={false}
                    placeholder={placeholder}
                    testId={`${testId}Input`}
                    onPressEscape={handleClose}
                    onClickOutside={handleClose}
                    className="w-[500px] absolute z-10 -top-2 -left-2 p-2 rounded-md shadow-lg"
                    value={hasValues && (
                        <QuickSearchSectionTravelSelectPillValue
                            options={allOptions}
                            className="flex-wrap"
                            values={selectedDestinations}
                        />
                    )}
                    iconBeforeInput={(
                        <Icon
                            color="blue"
                            name="location-point"
                        />
                    )}
                >
                    {(() => {
                        if (error) {
                            return (
                                <SearchListboxError>
                                    <Trans id="Failed to fetch destinations." />
                                </SearchListboxError>
                            )
                        }
                        if (loading) {
                            return (
                                <SearchListboxLoading>
                                    <Trans id="Loading destinations..." />
                                </SearchListboxLoading>
                            )
                        }
                        if (continents?.length <= 0 && countries?.length <= 0) {
                            return (
                                <SearchListboxError>
                                    <Trans id="No destinations available." />
                                </SearchListboxError>
                            )
                        }
                        return (
                            <QuickSearchSectionTravelDestinations
                                countries={countries}
                                continents={continents}
                                selectedCountryCode={values.countryCode}
                                selectedContinentCodes={values.continentCodes}
                                onChangeCountryCode={formState.onChange.countryCode}
                                onSelectDefault={() => {
                                    formState.handleChange('countryCode', '')
                                    formState.handleChange('continentCodes', [])
                                    handleClose()
                                }}
                            />
                        )
                    })()}
                </SearchListbox>
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopTravelDestinationSelect
