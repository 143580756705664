import React from 'react'
import usePageProps from '../hooks/usePageProps'
import FooterDesktop from './FooterDesktop'
import FooterMobile from './FooterMobile'

const FooterWithContent = ({
    content
}) => {
    const { data } = usePageProps()
    const finalContent = content || data.footerStory
    if (!finalContent) {
        return null
    }
    return (
        <>
            <FooterDesktop content={finalContent} />
            <FooterMobile content={finalContent} />
        </>
    )
}

export default FooterWithContent
