import React from 'react'
import SbEditable from 'storyblok-react'
import HeaderMobileMenuItem from './HeaderMobileMenuItem'

const HeaderMobileMenuItemWithContent = ({
    content,
    onOpenMenuItem,
}) => {
    const {
        text,
        href,
        hasDropdown,
        openInNewTab,
        linkStoryComponent,
    } = content

    return (
        <SbEditable content={content}>
            <HeaderMobileMenuItem
                href={href}
                openInNewTab={openInNewTab}
                onOpenMenuItem={() => onOpenMenuItem(content)}
                hasDropdown={hasDropdown || linkStoryComponent === 'TravelSearchPage'}
            >
                {text}
            </HeaderMobileMenuItem>
        </SbEditable>
    )
}

export default HeaderMobileMenuItemWithContent
