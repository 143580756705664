import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import useAppState from '../hooks/useAppState'
import SearchListItem from './SearchListItem'
import { SearchListboxResults } from './SearchListbox'

const useDestinationTreeState = () => {
    const [openCountries, setOpenCountries] = useState([])
    const [openContinents, setOpenContinents] = useState([])

    const toggleContinent = (code) => {
        if (openContinents.includes(code)) {
            setOpenContinents(openContinents.filter((value) => value !== code))
        } else {
            setOpenContinents([...openContinents, code])
        }
    }
    const toggleCountry = (code) => {
        if (openCountries.includes(code)) {
            setOpenCountries(openCountries.filter((value) => value !== code))
        } else {
            setOpenCountries([...openCountries, code])
        }
    }

    return {
        openCountries,
        toggleCountry,
        openContinents,
        toggleContinent,
    }
}

const SearchDestinationsTreeList = ({
    onChange,
    listboxClassName,
    cityCodesAvailable = [],
    countryCodesAvailable = [],
    continentCodesAvailable = [],
}) => {
    const {
        openCountries,
        toggleCountry,
        openContinents,
        toggleContinent,
    } = useDestinationTreeState()
    const { destinationTreeList } = useAppState()

    return (
        <SearchListboxResults className={listboxClassName}>
            {destinationTreeList
                .filter(({ value }) => continentCodesAvailable.includes(value))
                .map((continent) => {
                    const continentIsOpen = openContinents.includes(continent.value)
                    return (
                        <>
                            <SearchListItem
                                canCollapse
                                iconName="globe"
                                isOpen={continentIsOpen}
                                actionText={<Trans id="All countries" />}
                                onClick={() => toggleContinent(continent.value)}
                                onClickAction={(e) => {
                                    e.preventDefault()
                                    onChange(continent)
                                }}
                            >
                                {continent.name}
                            </SearchListItem>
                            {continentIsOpen && continent.countries
                                .filter(({ value }) => countryCodesAvailable.includes(value))
                                .map((country) => {
                                    const countryIsOpen = openCountries.includes(country.value)
                                    return (
                                        <>
                                            <SearchListItem
                                                canCollapse
                                                iconName="pin"
                                                indentLevel={1}
                                                isOpen={countryIsOpen}
                                                actionText={<Trans id="All cities" />}
                                                onClick={() => toggleCountry(country.value)}
                                                onClickAction={(e) => {
                                                    e.preventDefault()
                                                    onChange(country)
                                                }}
                                            >
                                                {country.name}
                                            </SearchListItem>
                                            {countryIsOpen && country.cities
                                                .filter(({ value }) => (cityCodesAvailable.includes(value)))
                                                .map((city) => (
                                                    <SearchListItem
                                                        iconName="city"
                                                        indentLevel={3}
                                                        isOpen={countryIsOpen}
                                                        onClick={() => onChange(city)}
                                                    >
                                                        {city.name}
                                                    </SearchListItem>
                                                ))}
                                        </>
                                    )
                                })}
                        </>
                    )
                })}
        </SearchListboxResults>
    )
}

export default SearchDestinationsTreeList
