/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { useOnClickOutside } from '@wappla/react-hooks'
import usePressEscape from '../hooks/usePressEscape'
import Select from './Select'
import PassengersCountInput from './PassengersCountInput'
import PassengerSelectButton from './PassengerSelectButton'

const PassengersListbox = ({
    fareType,
    fareTypes,
    className,
    numberOfAdults,
    numberOfInfants,
    numberOfChildren,
    onChangeFareType,
    minNumberOfTravelers,
    onChangeNumberOfAdults,
    onChangeNumberOfInfants,
    onChangeNumberOfChildren,
    onPressEscape = () => {},
    onClickOutside = () => {},
    isReplacingSelectButton = true,
}) => {
    const includesType = typeof fareTypes !== 'undefined'
    const inputRef = useRef()
    const containerRef = useRef()
    useEffect(() => isReplacingSelectButton && inputRef.current.focus(), [])
    useOnClickOutside(containerRef, onClickOutside)
    usePressEscape(onPressEscape)
    const fareTypeOption = includesType
        ? fareTypes.find(({ value }) => value === fareType) || null
        : null
    return (
        <div
            ref={containerRef}
            className={clsx(
                'bg-white',
                className,
            )}
        >
            {isReplacingSelectButton && (
                <PassengerSelectButton
                    ref={inputRef}
                    className="mb-2"
                    numberOfAdults={numberOfAdults}
                    numberOfChildren={numberOfChildren}
                    numberOfInfants={numberOfInfants}
                >
                    {includesType && fareTypeOption.label}
                </PassengerSelectButton>
            )}
            <div className="px-2 pb-2">
                <PassengersCountInput
                    value={numberOfAdults}
                    minValue={minNumberOfTravelers}
                    title={<Trans id="Adult(s)" />}
                    onChange={onChangeNumberOfAdults}
                    subTitle={<Trans id="+ 12 years old" />}
                    info={<Trans id="There should at least be 1 adult present." />}
                    testId="AdultCount"
                />
                <PassengersCountInput
                    title={<Trans id="Child(ren)" />}
                    subTitle={<Trans id="From 2 to 11 years old" />}
                    value={numberOfChildren}
                    onChange={onChangeNumberOfChildren}
                    testId="ChildrenCount"
                />
                <PassengersCountInput
                    value={numberOfInfants}
                    maxValue={numberOfAdults}
                    title={<Trans id="Infant(s)" />}
                    onChange={onChangeNumberOfInfants}
                    subTitle={<Trans id="Younger than 2 years" />}
                    info={<Trans id="Each infant should be accompanied with 1 adult." />}
                    testId="InfantCount"
                />
                {includesType && (
                    <div className="mt-3">
                        <label
                            htmlFor="fareType"
                            className="block text-sm mb-2"
                        >
                            <Trans id="Class" />
                        </label>
                        <Select
                            id="fareType"
                            value={fareType}
                            disabled={fareTypes.length === 1}
                            onChange={(e) => onChangeFareType(e.target.value)}
                        >
                            {fareTypes.map(({ value, label }) => (
                                <option value={value} key={value}>
                                    {label}
                                </option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PassengersListbox
