import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import TravelSearchListInput from '../pages/travel-search/TravelSearchListInput'

const QuickSearchSectionTravelPeriods = ({
    months,
    vacations,
    className,
    selectedMonths,
    onSelectDefault,
    selectedVacations,
}) => (
    <div
        className={clsx(
            'flex flex-col py-4',
            className,
        )}
    >
        <button
            type="button"
            onClick={onSelectDefault}
            className="flex-1 flex justify-between items-center bg-gray-50 -mx-2 mb-3 px-5 py-3"
        >
            <div className="flex items-center">
                <Icon
                    size="smaller"
                    name="calendar"
                />
                <div className="text-sm text-start ml-2">
                    <p className="font-medium">
                        <Trans id="Show all travel dates" />
                    </p>
                </div>
            </div>
            <Icon
                color="gray"
                name="arrow-right"
            />
        </button>
        <div className="px-3.5">
            <TravelSearchListInput
                columns={2}
                options={months}
                selectedOptions={selectedMonths}
            >
                <Trans id="Months" />
            </TravelSearchListInput>
            <TravelSearchListInput
                options={vacations}
                className="mt-6 mb-4"
                selectedOptions={selectedVacations}
            >
                <Trans id="Vacation periods" />
            </TravelSearchListInput>
        </div>
    </div>
)

export default QuickSearchSectionTravelPeriods
