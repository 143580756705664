import React from 'react'
import QuickSearchSectionDesktopExperts from './QuickSearchSectionDesktopExperts'
import useQuickSearchExpertsFormState from './useQuickSearchExpertsFormState'

const QuickSearchSectionDesktopExpertsWithState = ({
    iataCode,
    onSearch,
    ...props
}) => {
    const formState = useQuickSearchExpertsFormState(iataCode)
    return (
        <QuickSearchSectionDesktopExperts
            formState={formState}
            onChangeDestination={(destination) => {
                formState.handleChange('destination', destination)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
            {...props}
        />
    )
}

export default QuickSearchSectionDesktopExpertsWithState
