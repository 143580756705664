import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import PassengersListbox from '../utils/PassengersListbox'

const QuickSearchSectionMobileFlightsPassengersModal = ({
    title,
    onClose,
    fareTypes,
    onConfirm,
    initialFareType,
    minNumberOfTravelers,
    initialNumberOfAdults,
    initialNumberOfInfants,
    initialNumberOfChildren,
    ...props
}) => {
    const [numberOfAdults, setNumberOfAdults] = useState(initialNumberOfAdults)
    const [numberOfInfants, setNumberOfInfants] = useState(initialNumberOfInfants)
    const [numberOfChildren, setNumberOfChildren] = useState(initialNumberOfChildren)
    const [fareType, setFareType] = useState(initialFareType)
    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={<Trans id="Dates" />}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <PassengersListbox
                    fareType={fareType}
                    fareTypes={fareTypes}
                    numberOfAdults={numberOfAdults}
                    numberOfInfants={numberOfInfants}
                    numberOfChildren={numberOfChildren}
                    minNumberOfTravelers={minNumberOfTravelers}
                    onChangeFareType={(type) => setFareType(type)}
                    onChangeNumberOfAdults={(number) => {
                        if (number <= 9 && number >= minNumberOfTravelers) {
                            setNumberOfAdults(number)
                            if (numberOfInfants >= number) {
                                setNumberOfInfants(number)
                            }
                        }
                    }}
                    onChangeNumberOfChildren={(number) => {
                        if (number <= 9 && number >= 0) {
                            setNumberOfChildren(number)
                        }
                    }}
                    onChangeNumberOfInfants={(number) => {
                        if (number <= 9 && number >= 0) {
                            setNumberOfInfants(number)
                        }
                    }}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    className="mr-4 w-full"
                    variant="blue-outlined"
                    onClick={onClose}
                >
                    <Trans id="Close" />
                </Button>
                <Button
                    className="w-full"
                    variant="blue-filled"
                    iconAfterName="arrow-right"
                    onClick={() => onConfirm(
                        numberOfAdults,
                        numberOfChildren,
                        numberOfInfants,
                        fareType
                    )}
                    data-testid="PassengersConfirmButtonMobile"
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default QuickSearchSectionMobileFlightsPassengersModal
