import React from 'react'
import Popover from './Popover'

const Tooltip = ({
    content,
    enabled = true,
    event = 'hover',
    position = 'top',
    ...props
}) => {
    if (!enabled) {
        return props.children
    }
    return (
        <Popover
            hasArrow
            event={event}
            target="#tooltips"
            position={position}
            arrowClassName="tooltip-arrow bg-black"
            content={(
                <div className="bg-black text-white text-sm rounded px-4 py-2">
                    {content}
                </div>
            )}
            {...props}
        />
    )
}

export default Tooltip
