/* eslint-disable max-len */
import React from 'react'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import TextLink from '../utils/TextLink'
import QuickSearchSectionWithState from '../content/QuickSearchSectionWithState'
import Page from './Page'
import HeaderWithContent from './HeaderWithContent'
import FooterWithContent from './FooterWithContent'

const { CONTAINER_MEDIUM } = constants

const PageNotFound = () => (
    <Page noIndex>
        <HeaderWithContent />
        <main className="h-screen bg-blue">
            <section className="bg-blue-bright has-bottom-arc w-full min-h-[50vh] md:h-[70vh] text-white text-center px-4 flex items-center justify-center pb-11">
                <div className="max-w-2xl -mt-14">
                    <div className="font-accent text-5xl mb-4">
                        404
                    </div>
                    <h1 className="text-3xl font-medium mb-4 md:text-6xl md:mb-8">
                        <Trans id="Woops, it looks like the page you are looking for does not exist!" />
                    </h1>
                    <p className="text-base">
                        <Trans
                            id="<0>Go back to home</0> or start a new search from here"
                            components={[
                                <TextLink href="/" className="underline" />
                            ]}
                        />
                    </p>
                </div>
            </section>
            <QuickSearchSectionWithState
                openTab="flight"
                container={CONTAINER_MEDIUM}
                anchorId="page-not-found-qsm"
                tabs={['flight', 'hotel', 'car']}
            />
        </main>
        <FooterWithContent />
    </Page>
)

export default PageNotFound
