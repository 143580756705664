/* eslint-disable jsx-a11y/heading-has-content */
import React, { useRef, useEffect } from 'react'
import clsx from 'clsx'
import { useOnClickOutside } from '@wappla/react-hooks'
import Input from './Input'
import Icon from './Icon'
import Spinner from './Spinner'
import useKeyPress from '../hooks/useKeyPress'

const CenterContainer = (props) => (
    <div className="flex flex-col items-center justify-center h-[350px] text-sm" {...props} />
)

export const SearchListboxError = ({
    children,
    ...props
}) => (
    <CenterContainer {...props}>
        <Icon
            name="warning"
            color="primary"
        />
        <div className="mt-2">
            {children}
        </div>
    </CenterContainer>
)

export const SearchListboxLoading = ({
    children,
    ...props
}) => (
    <CenterContainer {...props}>
        <Spinner size="large" color="blue-bright" />
        <div className="mt-2">
            {children}
        </div>
    </CenterContainer>
)

export const SearchListboxEmpty = ({
    children,
    ...props
}) => (
    <CenterContainer {...props}>
        <Icon
            name="search"
            color="blue-bright"
        />
        <div className="mt-2">
            {children}
        </div>
    </CenterContainer>
)

export const SearchListboxResults = ({ className, ...props }) => (
    <ul
        className={clsx(
            'flex-1 mt-4 w-full divide-y divide-gray-100',
            className,
        )}
        {...props}
    />
)

export const SearchListboxTitle = ({
    className,
    children,
    ...props
}) => (
    <li
        className={clsx(
            'uppercase text-xs text-blue font-bold tracking-widest px-4 py-2',
            className,
        )}
        {...props}
    >
        <h5>
            {children}
        </h5>
    </li>
)

const SearchListbox = ({
    id,
    value,
    testId,
    onChange,
    children,
    className,
    placeholder,
    iconBeforeInput,
    hasInput = true,
    canSearch = true,
    onPressEscape = () => { },
    onClickOutside = () => { },
}) => {
    const inputRef = useRef()
    const containerRef = useRef()
    useEffect(() => inputRef.current.focus(), [])
    useOnClickOutside(containerRef, onClickOutside)
    useKeyPress(({ keyCode }) => {
        if (keyCode === 27) {
            onPressEscape()
        }
    })
    return (
        <div
            ref={containerRef}
            className={clsx(
                'bg-white',
                className,
            )}
        >
            <Input
                id={id}
                value={value}
                ref={inputRef}
                onChange={onChange}
                data-testid={testId}
                disabled={!canSearch}
                placeholder={placeholder}
                // This disables auto zoom on IOS devices
                inputClassName="!text-base"
                iconBefore={iconBeforeInput}
                as={hasInput ? 'input' : 'div'}
            />
            {children}
        </div>
    )
}

export default SearchListbox
