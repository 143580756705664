import React from 'react'
import QuickSearchSectionMobileShops from './QuickSearchSectionMobileShops'
import useQuickSearchShopsFormState from './useQuickSearchShopsFormState'

const QuickSearchSectionMobileShopsWithState = ({
    onSearch,
}) => {
    const formState = useQuickSearchShopsFormState()
    return (
        <QuickSearchSectionMobileShops
            formState={formState}
            onConfirmLocation={(location) => {
                formState.handleChange('location', location)
            }}
            onChangePostCode={(postCode) => {
                formState.handleChange('postCode', postCode)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionMobileShopsWithState
