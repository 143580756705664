import React, { useState } from 'react'
import clsx from 'clsx'
import QuickSearchSectionMobileDestinationModal from './QuickSearchSectionMobileDestinationModal'
import QuickSearchSectionMobileDestinationButton from './QuickSearchSectionMobileDestinationButton'

const QuickSearchSectionMobileDestinationSelect = ({
    errors,
    title,
    className,
    destination,
    settingsSlug,
    inputPlaceholder,
    onConfirmDestination,
}) => {
    const [isOpen, setOpen] = useState(false)
    const hasError = errors.length > 0

    return (
        <>
            {isOpen && (
                <QuickSearchSectionMobileDestinationModal
                    title={title}
                    settingsSlug={settingsSlug}
                    onClose={() => setOpen(false)}
                    inputPlaceholder={inputPlaceholder}
                    onConfirm={(dest) => {
                        onConfirmDestination(dest)
                        setOpen(false)
                    }}
                />
            )}
            <div
                className={clsx(
                    'border  w-full rounded',
                    hasError ? 'border-primary' : 'border-gray-100',
                    className,
                )}
            >
                <QuickSearchSectionMobileDestinationButton
                    label={title}
                    isPlaceholder={!destination}
                    onClick={() => setOpen(true)}
                    placeholder={inputPlaceholder}
                    destination={destination?.name}
                />
            </div>
        </>
    )
}

export default QuickSearchSectionMobileDestinationSelect
