import React from 'react'
import clsx from 'clsx'
import NextLink from 'next/link'
import Icon from '../utils/Icon'
import ConditionalWrapper from '../utils/ConditionalWrapper'

const HeaderMobileMenuItem = ({
    href,
    children,
    as = 'li',
    className,
    hasDropdown,
    openInNewTab,
    onOpenMenuItem,
}) => {
    const Component = as
    let LinkComponent = 'span'
    if (hasDropdown) {
        LinkComponent = 'button'
    }

    return (
        <Component>
            <ConditionalWrapper
                condition={!hasDropdown}
                wrapper={(wrapperChildren) => (
                    <NextLink href={href} target={openInNewTab ? '_blank' : '_self'}>
                        {wrapperChildren}
                    </NextLink>
                )}
            >
                <LinkComponent
                    className={clsx(
                        'flex items-center justify-between px-4 py-5 font-medium w-full',
                        className,
                    )}
                    onClick={(e) => {
                        if (hasDropdown) {
                            e.preventDefault()
                            onOpenMenuItem()
                        }
                    }}
                >
                    {children}
                    <Icon
                        size="small"
                        name="arrow-right"
                        color="blue-bright"
                    />
                </LinkComponent>
            </ConditionalWrapper>
        </Component>
    )
}

export default HeaderMobileMenuItem
