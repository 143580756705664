import { gql } from 'graphql-request'
import useLazyGraphqlQuery from './useGraphqlMutation'

const QUERY = gql`
    query searchCarRentalLocations($query: String!) {
        searchCarRentalLocations(query: $query) {
            nodes {
                id
                name
                fullName
                countryName
                countryCode
                locationId
                airportCode
            }
        }
    }
`

const useSearchCarRentalLocations = (options) => (
    useLazyGraphqlQuery(QUERY, options)
)

export default useSearchCarRentalLocations
