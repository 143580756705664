import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import Input from '../utils/Input'
import Button from '../utils/Button'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'

const QuickSearchSectionDesktopShops = ({
    uid,
    onSearch,
    formState,
    onChangePostCode,
}) => {
    const { i18n } = useLingui()
    const { postCode } = formState.values

    return (
        <div>
            <div className="grid grid-cols-7 gap-4">
                <QuickSearchSectionDesktopInputGroup
                    className="col-span-4"
                    htmlFor={`shops-type-${uid}`}
                    label={<Trans id="Postal Code" />}
                >
                    <Input
                        value={postCode}
                        id={`shops-type-${uid}`}
                        placeholder={i18n._('Postal Code')}
                        onChange={(e) => onChangePostCode(e.target.value)}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <div className="flex items-end col-span-3">
                    <Button
                        size="large"
                        className="w-full"
                        onClick={onSearch}
                        iconBeforeName="search"
                        variant="primary-filled"
                        data-testid="QuickSearchSectionDesktopShopsSearchButton"
                    >
                        <Trans id="Search shops" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopShops
