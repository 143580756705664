import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import useTravelSearchSettingsQuery from '../hooks/useTravelSearchSettingsQuery'
import useTravelSearchFilterFormState from '../pages/travel-search/useTravelSearchFilterFormState'
import QuickSearchSectionMobileTravelPeriodSelect from './QuickSearchSectionMobileTravelPeriodSelect'
import QuickSearchSectionMobileTravelFormulaSelect from './QuickSearchSectionMobileTravelFormulaSelect'
import QuickSearchSectionMobileTravelDestinationSelect from './QuickSearchSectionMobileTravelDestinationSelect'

const QuickSearchSectionMobileTravelWithState = ({
    onSearch,
}) => {
    const formState = useTravelSearchFilterFormState()
    const {
        error,
        months,
        loading,
        countries,
        vacations,
        continents,
        travelThemes,
        travelFormulas,
    } = useTravelSearchSettingsQuery(formState)

    return (
        <>
            <QuickSearchSectionMobileTravelDestinationSelect
                error={error}
                className="mb-4"
                loading={loading}
                countries={countries}
                formState={formState}
                continents={continents}
            />
            <QuickSearchSectionMobileTravelFormulaSelect
                error={error}
                className="mb-4"
                loading={loading}
                formState={formState}
                travelFormulas={travelFormulas}
                travelThemes={travelThemes}
            />
            <QuickSearchSectionMobileTravelPeriodSelect
                error={error}
                months={months}
                className="mb-4"
                loading={loading}
                vacations={vacations}
                formState={formState}
            />
            <Button
                size="large"
                className="w-full"
                iconAfterName="search"
                variant="primary-filled"
                onClick={() => onSearch(formState.valuesToQueryString())}
            >
                <Trans id="Search trips" />
            </Button>
        </>
    )
}

export default QuickSearchSectionMobileTravelWithState
