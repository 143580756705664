import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import SearchDestinationsListBox from '../utils/SearchDestinationsListBox'

const QuickSearchSectionMobileDestinationModal = ({
    onClose,
    onConfirm,
    settingsSlug,
    inputPlaceholder,
    initialDestination,
    title = <Trans id="Destination" />,
    ...props
}) => {
    const [selectedDestination, setSelectedDestination] = useState(initialDestination)

    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={title}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <SearchDestinationsListBox
                    settingsSlug={settingsSlug}
                    destination={selectedDestination}
                    inputPlaceholder={inputPlaceholder}
                    className="flex-1 flex flex-col h-full"
                    listboxClassName="h-[500px] overflow-y-scroll" // TODO how can we make the height fill
                    onChange={(dest) => {
                        setSelectedDestination(dest)
                        onConfirm(dest)
                    }}
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    onClick={onClose}
                    className="mr-4 w-full"
                    variant="blue-outlined"
                >
                    <Trans id="Close" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default QuickSearchSectionMobileDestinationModal
