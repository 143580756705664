import useFormState from 'use-form-state'

const INITIAL_VALUES = {
    months: [],
    formulas: [],
    themes: [],
    vacations: [],
    countryCode: '',
    continentCodes: [],
}

const getUpdatedArray = (currentArray, newValue) => (currentArray.includes(newValue)
    ? currentArray.filter((value) => value !== newValue)
    : [...currentArray, newValue])

const queryToValues = ({
    months,
    formulas,
    themes,
    vacations,
    countryCode,
    continentCodes,
    ...query
}) => ({
    ...query,
    months: months || INITIAL_VALUES.months,
    formulas: formulas || INITIAL_VALUES.formulas,
    themes: themes || INITIAL_VALUES.themes,
    vacations: vacations || INITIAL_VALUES.vacations,
    countryCode: countryCode || INITIAL_VALUES.countryCode,
    continentCodes: continentCodes || INITIAL_VALUES.continentCodes,
})

const valuesToQueryString = ({
    months,
    formulas,
    themes,
    vacations,
    countryCode,
    continentCodes,
}) => ({
    countryCode,
    months: months.join(','),
    formulas: formulas.join(','),
    themes: themes.join(','),
    vacations: vacations.join(','),
    continentCodes: continentCodes.join(','),
})

const useTravelSearchFilterFormState = (query = {}) => {
    const formState = useFormState(queryToValues(query))
    const { values } = formState
    return {
        ...formState,
        destroy: () => formState.setValues(INITIAL_VALUES),
        onChange: {
            months: (value) => {
                const { months } = values
                formState.handleChange('months', getUpdatedArray(months, value))
            },
            vacations: (value) => {
                const { vacations } = values
                formState.handleChange('vacations', getUpdatedArray(vacations, value))
            },
            formulas: (value) => {
                const { formulas } = values
                formState.handleChange('formulas', getUpdatedArray(formulas, value))
            },
            themes: (value) => {
                const { themes } = values
                formState.handleChange('themes', getUpdatedArray(themes, value))
            },
            countryCode: (value) => {
                const { countryCode, continentCodes } = values
                if (continentCodes.length > 0) {
                    formState.handleChange('continentCodes', [])
                }
                formState.handleChange('countryCode', countryCode === value ? '' : value)
            },
            continentCodes: (value) => {
                const { continentCodes, countryCode } = values
                if (countryCode !== '') {
                    formState.handleChange('countryCode', '')
                }
                formState.handleChange('continentCodes', getUpdatedArray(continentCodes, value))
            },
        },
        valuesToQueryString: () => valuesToQueryString(values),
        amountSelected: values.months.length
            + values.vacations.length
            + values.formulas.length
            + values.themes.length
            + values.continentCodes.length
            + (values.countryCode === '' ? 0 : 1),
    }
}

export default useTravelSearchFilterFormState
