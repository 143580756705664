import React, { useState } from 'react'
import SelectButton from '../utils/SelectButton'
import QuickSearchSectionMobileSelectModal from './QuickSearchSectionMobileSelectModal'

const QuickSearchSectionMobileSelect = ({
    title,
    value,
    options,
    disabled,
    onConfirm,
    className,
    modalClassName,
}) => {
    const [isOpen, setOpen] = useState(false)
    const selectedOption = options.find((option) => option.value === value) || {}
    return (
        <>
            {isOpen && (
                <QuickSearchSectionMobileSelectModal
                    title={title}
                    className={modalClassName}
                    value={value}
                    options={options}
                    onClose={() => setOpen(false)}
                    onConfirm={(option) => {
                        onConfirm(option)
                        setOpen(false)
                    }}
                />
            )}
            <SelectButton
                disabled={disabled}
                className={className}
                onClick={() => setOpen(true)}
                data-testid="QuickSearchSectionMobileSelect"
            >
                {selectedOption.label || null}
            </SelectButton>
        </>
    )
}

export default QuickSearchSectionMobileSelect
