import React from 'react'
import SbEditable from 'storyblok-react'
import HeaderMobileTopBarMenuItem from './HeaderMobileTopBarMenuItem'

const HeaderMobileTopBarMenuItemWithContent = ({
    content
}) => {
    const {
        href,
        text,
        iconBeforeName,
        iconAfterName
    } = content
    return (
        <SbEditable content={content}>
            <HeaderMobileTopBarMenuItem
                href={href}
                iconBeforeName={iconBeforeName}
                iconAfterName={iconAfterName}
            >
                {text}
            </HeaderMobileTopBarMenuItem>
        </SbEditable>
    )
}

export default HeaderMobileTopBarMenuItemWithContent
