import React from 'react'
import clsx from 'clsx'

const CarouselDots = ({
    size = 'default',
    color = 'white',
    count,
    activeIndex,
    onChangeIndex,
    className,
    dotClassName,
    ...props
}) => (
    <div
        className={clsx(
            'w-16 h-full flex md:flex-col items-center justify-center gap-8 md:gap-0',
            className,
        )}
        {...props}
    >
        {Array.from(Array(count).keys()).map((item, index) => (
            <button
                type="button"
                key={item}
                onClick={() => typeof onChangeIndex === 'function' && onChangeIndex(index)}
            >
                <div
                    className={clsx(
                        'bg-white',
                        color === 'white' && 'bg-white',
                        color === 'blue' && 'bg-blue-bright',
                        size === 'small' && 'w-[2px] h-4 my-1',
                        size === 'default' && 'w-[3px] h-7 my-[6px]',
                        index === activeIndex ? 'opacity-100' : 'opacity-40',
                        dotClassName
                    )}
                />
            </button>
        ))}
    </div>
)

export default CarouselDots
