/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
    getDayIsDisabledCondition,
    getPreviousSegmentDepartureDate,
    getMultiSelectDayIsDisabledCondition,
} from '@connections/utils'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import Button from '../utils/Button'
import CheckboxInput from '../utils/CheckboxInput'
import { Label } from './QuickSearchSectionMobileDestinationButton'
import QuickSearchSectionMobileSelect from './QuickSearchSectionMobileSelect'
import QuickSearchSectionMobileFlightsCollapsed from './QuickSearchSectionMobileFlightsCollapsed'
import QuickSearchSectionMobileFlightsDateSelect from './QuickSearchSectionMobileFlightsDateSelect'
import QuickSearchSectionMobileFlightsExtraSegment from './QuickSearchSectionMobileFlightsExtraSegment'
import QuickSearchSectionMobileFlightsDateRangeSelect from './QuickSearchSectionMobileFlightsDateRangeSelect'
import QuickSearchSectionMobileFlightsPassengersSelect from './QuickSearchSectionMobileFlightsPassengersSelect'
import QuickSearchSectionMobileFlightsDestinationButtons from './QuickSearchSectionMobileFlightsDestinationButtons'

const {
    FLIGHT_MODE_ONE_WAY: ONE_WAY,
    FLIGHT_MODE_ROUND_TRIP: ROUND_TRIP,
    FLIGHT_MODE_MULTI_CITY: MULTI_CITY,
} = constants

const flightModes = [
    {
        value: ROUND_TRIP,
        label: <Trans id="Round trip" />,
    },
    {
        value: ONE_WAY,
        label: <Trans id="One way" />,
    },
    {
        value: MULTI_CITY,
        label: <Trans id="Multi-city" />,
    },
]

const QuickSearchSectionMobileFlights = ({
    onOpen,
    isPromo,
    onSearch,
    isLoading,
    formState,
    fareTypes,
    isSearching,
    canSearchTo,
    isCollapsed,
    hasChosenTo,
    canSearchFrom,
    onConfirmDates,
    hasChosenDates,
    onAddExtraSegment,
    toDefaultIataCodes,
    onConfirmFlightMode,
    onConfirmPassengers,
    hasOnlyDepartureDate,
    minNumberOfTravelers,
    fromDefaultIataCodes,
    onRemoveExtraSegment,
    onConfirmToDestination,
    onConfirmDepartureDate,
    onConfirmFromDestination,
    onChangeExtraSegmentDeparture,
    onChangeExtraSegmentToDestination,
    onChangeExtraSegmentFromDestination,
}) => {
    const { values, errors, handleChange, handleSwitchFromAndTo } = formState
    const {
        fareType,
        flightMode,
        returnDate,
        extraSegments,
        toDestination,
        departureDate,
        alsoFindHotels,
        includeLuggage,
        isDirectFlight,
        numberOfAdults,
        fromDestination,
        numberOfInfants,
        numberOfChildren,
    } = values
    const count = extraSegments.length

    return (
        <div data-testid="QuickSearchSectionMobileFlights">
            {isCollapsed ? (
                <QuickSearchSectionMobileFlightsCollapsed
                    onOpen={onOpen}
                    formState={formState}
                    isSearching={isSearching}
                    flightModes={flightModes}
                />
            ) : (
                <>
                    <QuickSearchSectionMobileSelect
                        className="mb-4"
                        value={flightMode}
                        disabled={isSearching}
                        modalClassName="h-[300px]"
                        onConfirm={onConfirmFlightMode}
                        title={<Trans id="Flight Mode" />}
                        options={flightModes.filter(
                            ({ value }) => !(isPromo && value === MULTI_CITY)
                        )}
                    />
                    {flightMode === MULTI_CITY && (
                        <>
                            <QuickSearchSectionMobileFlightsPassengersSelect
                                className="mb-4"
                                fareType={fareType}
                                fareTypes={fareTypes}
                                isSearching={isSearching}
                                onConfirm={onConfirmPassengers}
                                numberOfAdults={numberOfAdults}
                                numberOfInfants={numberOfInfants}
                                numberOfChildren={numberOfChildren}
                                minNumberOfTravelers={minNumberOfTravelers}
                                errors={errors.filter(
                                    ({ path }) =>
                                        path === 'fareTypes' ||
                                        path === 'numberOfAdults' ||
                                        path === 'numberOfChildren' ||
                                        path === 'numberOfInfants'
                                )}
                            />
                            <Label className="mb-4">
                                <Trans id="Trip 1" />
                            </Label>
                        </>
                    )}
                    {isLoading ? (
                        <div className="skeleton h-24 rounded-sm mb-4" />
                    ) : (
                        <QuickSearchSectionMobileFlightsDestinationButtons
                            className="mb-4"
                            isPromo={isPromo}
                            canSearchTo={canSearchTo}
                            isSearching={isSearching}
                            canSearchFrom={canSearchFrom}
                            toDestination={toDestination}
                            fromDestination={fromDestination}
                            toDefaultIataCodes={toDefaultIataCodes}
                            fromDefaultIataCodes={fromDefaultIataCodes}
                            onConfirmToDestination={onConfirmToDestination}
                            onSwitchFromAndTo={() => handleSwitchFromAndTo()}
                            onConfirmFromDestination={onConfirmFromDestination}
                            errors={errors.filter(
                                ({ path }) =>
                                    path === 'fromDestination' ||
                                    path === 'toDestination'
                            )}
                        />
                    )}

                    {isLoading ? (
                        <div className="skeleton h-12 rounded-sm mb-4" />
                    ) : (
                        (() => {
                            if (hasOnlyDepartureDate) {
                                return (
                                    <QuickSearchSectionMobileFlightsDateSelect
                                        className="mb-4"
                                        date={departureDate}
                                        isOpen={hasChosenTo}
                                        disabled={isSearching}
                                        onConfirm={onConfirmDepartureDate}
                                        dayIsDisabledCondition={
                                            getDayIsDisabledCondition
                                        }
                                        errors={errors.filter(
                                            ({ path }) =>
                                                path === 'departureDate'
                                        )}
                                    />
                                )
                            }
                            return (
                                <QuickSearchSectionMobileFlightsDateRangeSelect
                                    className="mb-4"
                                    isOpen={hasChosenTo}
                                    disabled={isSearching}
                                    returnDate={returnDate}
                                    onConfirm={onConfirmDates}
                                    departureDate={departureDate}
                                    dayIsDisabledCondition={
                                        getDayIsDisabledCondition
                                    }
                                    errors={errors.filter(
                                        ({ path }) =>
                                            path === 'departureDate' ||
                                            path === 'returnDate'
                                    )}
                                />
                            )
                        })()
                    )}
                    {isLoading ? (
                        <div className="skeleton h-12 rounded-sm mb-4" />
                    ) : (
                        flightMode !== MULTI_CITY && (
                            <QuickSearchSectionMobileFlightsPassengersSelect
                                className="mb-4"
                                fareType={fareType}
                                fareTypes={fareTypes}
                                isOpen={hasChosenDates}
                                isSearching={isSearching}
                                numberOfAdults={numberOfAdults}
                                onConfirm={onConfirmPassengers}
                                numberOfInfants={numberOfInfants}
                                numberOfChildren={numberOfChildren}
                                minNumberOfTravelers={minNumberOfTravelers}
                                errors={errors.filter(
                                    ({ path }) =>
                                        path === 'fareTypes' ||
                                        path === 'numberOfAdults' ||
                                        path === 'numberOfInfants' ||
                                        path === 'numberOfChildren'
                                )}
                            />
                        )
                    )}
                    {flightMode === MULTI_CITY && (
                        <>
                            {extraSegments.map((segment, index) => (
                                <QuickSearchSectionMobileFlightsExtraSegment
                                    key={index}
                                    index={index}
                                    currentIndex={index}
                                    isSearching={isSearching}
                                    canSearchTo={canSearchTo}
                                    canSearchFrom={canSearchFrom}
                                    toDestination={segment.toDestination}
                                    departureDate={segment.departureDate}
                                    numberOfSegments={extraSegments.length}
                                    fromDestination={segment.fromDestination}
                                    onRemove={() => onRemoveExtraSegment(index)}
                                    onSwitchFromAndTo={() => {
                                        handleSwitchFromAndTo(index)
                                    }}
                                    viewDate={getPreviousSegmentDepartureDate(
                                        index,
                                        departureDate,
                                        extraSegments
                                    )}
                                    errors={errors.filter(({ path }) =>
                                        path.includes(`extraSegments.${index}`)
                                    )}
                                    dayIsDisabledCondition={(day) =>
                                        getMultiSelectDayIsDisabledCondition(
                                            day,
                                            index,
                                            departureDate,
                                            extraSegments
                                        )
                                    }
                                    onConfirmToDestination={(to) => {
                                        onChangeExtraSegmentToDestination(
                                            index,
                                            to
                                        )
                                    }}
                                    onConfirmDepartureDate={(departure) => {
                                        onChangeExtraSegmentDeparture(
                                            index,
                                            departure
                                        )
                                    }}
                                    onConfirmFromDestination={(from) => {
                                        onChangeExtraSegmentFromDestination(
                                            index,
                                            from
                                        )
                                    }}
                                />
                            ))}
                            <Button
                                variant="link"
                                iconBeforeName="plus"
                                onClick={onAddExtraSegment}
                                className="text-blue-bright mb-4"
                                disabled={isSearching || count >= 3}
                                data-testid="QuickSearchSectionMobileFlightsAddFlightButton"
                            >
                                Add another flight
                            </Button>
                        </>
                    )}
                    <div className="flex flex-col gap-y-4 mb-4">
                        <CheckboxInput
                            className="text-sm"
                            disabled={isSearching}
                            value={isDirectFlight}
                            onChange={() => {
                                handleChange('isDirectFlight', !isDirectFlight)
                            }}
                        >
                            <Trans id="Show only direct flights" />
                        </CheckboxInput>
                        <CheckboxInput
                            className="text-sm"
                            disabled={isSearching}
                            value={includeLuggage}
                            onChange={() => {
                                handleChange('includeLuggage', !includeLuggage)
                            }}
                        >
                            <Trans id="I’m travelling with checked luggage" />
                        </CheckboxInput>
                        <CheckboxInput
                            className="text-sm"
                            value={alsoFindHotels}
                            disabled={isSearching}
                            onChange={() => {
                                handleChange('alsoFindHotels', !alsoFindHotels)
                            }}
                        >
                            <Trans id="Also find hotels" />
                        </CheckboxInput>
                    </div>
                    <Button
                        type="button"
                        size="large"
                        className="w-full"
                        onClick={onSearch}
                        iconAfterName="search"
                        variant="primary-filled"
                        isLoading={isSearching}
                        data-testid="QuickSearchSectionMobileFlightsSearchButton"
                    >
                        <Trans id="Search flights" />
                    </Button>
                </>
            )}
        </div>
    )
}

export default QuickSearchSectionMobileFlights
