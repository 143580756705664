import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import Select from '../../utils/Select'

const TravelSearchCountryInput = ({
    className,
    countries,
    onChangeCountryCode,
    selectedCountryCode,
}) => {
    const { i18n } = useLingui()

    if (!countries || countries.length <= 0) {
        return null
    }

    return (
        <div className={className}>
            <h5 className="text-xs text-gray-600 font-medium uppercase mb-4">
                <Trans id="Or choose a country" />
            </h5>
            <Select
                value={selectedCountryCode}
                onChange={({ target }) => onChangeCountryCode(target.value)}
            >
                <option key="defaultCountryValue" value="">
                    {i18n._(/*i18n*/'All countries')}
                </option>
                {countries.map(({ value, label }) => (
                    <option
                        key={value}
                        value={value}
                    >
                        {label}
                    </option>
                ))}
            </Select>
        </div>
    )
}

export default TravelSearchCountryInput
