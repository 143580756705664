import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import Input from '../utils/Input'
import Button from '../utils/Button'
import FormGroup from '../utils/FormGroup'

const QuickSearchSectionMobileShops = ({
    onSearch,
    formState,
    onChangePostCode,
}) => {
    const { i18n } = useLingui()

    return (
        <div>
            <FormGroup htmlFor="postalCode">
                <Input
                    id="postalCode"
                    value={formState.values.type}
                    placeholder={i18n._(/*i18n*/'Postal code')}
                    onChange={(e) => onChangePostCode(e.target.value)}
                />
            </FormGroup>
            <Button
                size="large"
                type="button"
                onClick={onSearch}
                iconAfterName="search"
                className="w-full mt-6"
                variant="primary-filled"
            >
                <Trans id="Search shops" />
            </Button>
        </div>
    )
}

export default QuickSearchSectionMobileShops
