import React from 'react'
import QuickSearchSectionMobileCars from './QuickSearchSectionMobileCars'
import useQuickSearchCarFormState from './useQuickSearchCarFormState'

const QuickSearchSectionMobileCarsWithState = ({
    onSearch
}) => {
    const formState = useQuickSearchCarFormState()
    return (
        <QuickSearchSectionMobileCars
            formState={formState}
            onChangePickupIsSameAsDropOff={(e) => {
                formState.handleChange('pickupIsSameAsDropOff', e.target.checked)
            }}
            onConfirmPickUpDestination={(dest) => {
                formState.handleChange('pickUpDestination', dest)
            }}
            onConfirmDropOffDestination={(dest) => {
                formState.handleChange('dropOffDestination', dest)
            }}
            onConfirmDates={(pickUp, dropOff) => {
                formState.handleChange('pickUpDate', pickUp)
                formState.handleChange('dropOffDate', dropOff)
            }}
            onConfirmTimes={(pickUp, dropOff) => {
                formState.handleChange('pickUpTime', pickUp)
                formState.handleChange('dropOffTime', dropOff)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionMobileCarsWithState
