import React from 'react'
import Link from 'next/link'
import usePathnameLocale from '../hooks/usePathnameLocale'
import Logo from '../utils/Logo'
import Icon from '../utils/Icon'

const HeaderMobileMainNavBar = ({
    menuIsOpen,
    onToggleMenu,
}) => {
    const fullLocale = usePathnameLocale(true)

    return (
        <nav className="flex justify-between items-center h-full relative">
            <Link href={`/${fullLocale}`}>
                <Logo className="h-8 ml-4" />
            </Link>
            <button
                type="button"
                className="block h-full px-6 border-l border-gray-100"
                onClick={onToggleMenu}
            >
                <Icon
                    name={menuIsOpen ? 'cross' : 'menu'}
                    color="black"
                    size="small"
                />
            </button>
        </nav>
    )
}

export default HeaderMobileMainNavBar
