import React from 'react'
import useQuickSearchShopsFormState from './useQuickSearchShopsFormState'
import QuickSearchSectionDesktopShops from './QuickSearchSectionDesktopShops'

const QuickSearchSectionDesktopShopsWithState = ({
    uid,
    onSearch
}) => {
    const formState = useQuickSearchShopsFormState()

    return (
        <QuickSearchSectionDesktopShops
            uid={uid}
            formState={formState}
            onChangePostCode={(postCode) => {
                formState.handleChange('postCode', postCode)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionDesktopShopsWithState
