import { gql } from 'graphql-request'
import useGraphqlQuery from './useGraphqlQuery'

const QUERY = gql`
    query airportSearchResults($iataCodes: [String]!) {
        airportSearchResults(iataCodes: $iataCodes) {
            id
            code
            name
            fullName
            countryName
            countryCode
            regionCode
            isMetropolitan
            isTrainStation
        }
    }
`

const useAirportSearchResults = (iataCodes = [], options) => {
    const variables = {
        iataCodes: iataCodes.map((iataCode) => (iataCode.includes('-')
            ? iataCode.split('-')[1]
            : iataCode)),
    }
    const allOptions = {
        enabled: iataCodes.length > 0,
        ...options
    }
    const { data } = useGraphqlQuery('airportSearchResults', QUERY, variables, allOptions)
    return data?.airportSearchResults || []
}

export default useAirportSearchResults
