import React, { useEffect, useRef } from 'react'
import {
    addDays,
    isAfter,
    isBefore,
    closestTo,
    compareAsc,
    isFirstDayOfMonth,
} from 'date-fns'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { useOnClickOutside } from '@wappla/react-hooks'
import useKeyPress from '../hooks/useKeyPress'
import useCalendar from '../hooks/useCalendar'
import Button from './Button'
import Calendar from './Calendar'
import DateRangeButton from './DateRangeButton'
import DateCalendarMonthButtons from './DateCalendarMonthButtons'

const DateRangeCalendar = ({
    onChange,
    className,
    returnDate,
    departureDate,
    rangeStartDate,
    inputClassName,
    labelTextToday,
    clickOutsideRef,
    labelTextLastSelected,
    labelTextFirstSelected,
    dayIsDisabledCondition,
    numberOfMonthButtons = 10,
    onPressEscape = () => { },
    onClickOutside = () => { },
    testId = 'DateRangeCalendar',
}) => {
    const departureInputRef = useRef()
    const returnInputRef = useRef()
    const containerRef = useRef()
    useEffect(() => departureInputRef.current.focus(), [])
    useOnClickOutside(clickOutsideRef || containerRef, onClickOutside)
    useKeyPress(({ keyCode }) => {
        if (keyCode === 27) {
            onPressEscape()
        }
    })
    const initialSelected = [departureDate, returnDate].filter((date) => date !== null)
    const {
        calendar,
        deselect,
        inRange,
        isSelected,
        select,
        selected,
        selectRange,
        viewing,
        viewMonth,
        viewNextMonth,
        viewPreviousMonth,
        viewToday,
        clearSelected,
    } = useCalendar({
        weekStartsOn: 1,
        numberOfMonths: 2,
        selected: initialSelected,
        viewing: departureDate || returnDate || rangeStartDate || undefined,
    })
    const selectedStored = selected.sort((a, b) => compareAsc(a, b))
    const [firstMonth, secondMonth] = calendar
    const allDaysFirstMonth = firstMonth.flatMap((week) => week)
    const allDaysSecondMonth = secondMonth.flatMap((week) => week)
    const firstDayFirstMonth = allDaysFirstMonth.find((day) => isFirstDayOfMonth(day))
    const firstDaySecondMonth = allDaysSecondMonth.find((day) => isFirstDayOfMonth(day))
    const [firstSelected = null] = selectedStored
    const lastSelected = selectedStored.length > 1 ? selectedStored[selected.length - 1] : null
    useEffect(() => {
        if (firstSelected === null && lastSelected === null) {
            departureInputRef.current.focus()
        }
        if (firstSelected && lastSelected === null) {
            returnInputRef.current.focus()
        }
    }, [firstSelected, lastSelected])
    useEffect(() => {
        onChange(firstSelected, lastSelected)
    }, [firstSelected, lastSelected])
    return (
        <div
            ref={containerRef}
            className={clsx(
                'bg-white',
                className
            )}
            data-testid={testId}
        >
            <div className="lg:flex lg:justify-between">
                <DateRangeButton
                    className={inputClassName}
                    departureRef={departureInputRef}
                    returnRef={returnInputRef}
                    departureDate={firstSelected}
                    returnDate={lastSelected}
                    departureIsFocused={firstSelected === null && lastSelected === null}
                    returnIsFocused={firstSelected && lastSelected === null}
                />
                <div className="flex justify-center mt-4 lg:mt-0">
                    <Button
                        variant="link"
                        className="text-blue-bright"
                        iconBeforeName="rotate"
                        onClick={() => clearSelected()}
                    >
                        <Trans id="Reset" />
                    </Button>
                </div>
            </div>
            <div
                className={clsx(
                    'mt-4 w-full',
                )}
            >
                <DateCalendarMonthButtons
                    numberOfMonths={numberOfMonthButtons}
                    firstDayFirstMonth={firstDayFirstMonth}
                    firstDaySecondMonth={firstDaySecondMonth}
                    onClickMonth={(monthIndex, monthDate) => {
                        viewMonth(monthIndex, monthDate)
                    }}
                    onClickPreviousMonth={() => viewPreviousMonth()}
                    onClickNextMonth={() => viewNextMonth()}
                />
                <div className="md:grid md:grid-cols-2 md:gap-8 max-w-[632px] mx-auto">
                    {calendar.map((month) => (
                        <Calendar
                            key={month[0][0]}
                            className="mb-8 lg:mb-4"
                            month={month}
                            onDeselectDay={(day) => deselect(day)}
                            onSelectDay={(day) => select(day)}
                            inRange={inRange}
                            isSelected={isSelected}
                            selected={selectedStored}
                            selectRange={selectRange}
                            viewing={viewing}
                            viewNextMonth={viewNextMonth}
                            viewPreviousMonth={viewPreviousMonth}
                            viewToday={viewToday}
                            dayIsDisabledCondition={dayIsDisabledCondition}
                            labelTextToday={labelTextToday}
                            labelTextFirstSelected={labelTextFirstSelected}
                            labelTextLastSelected={labelTextLastSelected}
                            onClickDay={(day) => {
                                if (isSelected(day)) {
                                    deselect(day)
                                } else if (firstSelected === null || lastSelected === null) {
                                    select(day)
                                } else if (inRange(day, firstSelected, lastSelected)) {
                                    const closetDay = closestTo(day, [
                                        lastSelected,
                                        firstSelected
                                    ])
                                    deselect(closetDay)
                                    select(day)
                                } else if (isAfter(day, lastSelected)) {
                                    const moreThan2days = isAfter(day, addDays(lastSelected, 2))
                                    if (moreThan2days) {
                                        deselect(firstSelected)
                                    }
                                    deselect(lastSelected)
                                    select(day)
                                } else if (isBefore(day, firstSelected)) {
                                    const lessThan2days = isBefore(day, addDays(firstSelected, -2))
                                    if (lessThan2days) {
                                        deselect(lastSelected)
                                    }
                                    deselect(firstSelected)
                                    select(day)
                                } else {
                                    select(day)
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DateRangeCalendar
