import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'

const Countdown = ({ deadlineString, className }) => {
    const deadline = new Date(deadlineString)
    const [remainingTime, setRemainingTime] = useState(deadline.getTime() - new Date().getTime())

    useEffect(() => {
        const intervalId = setInterval(() => {
            const timeLeft = deadline.getTime() - new Date().getTime()
            if (timeLeft <= 0) {
                clearInterval(intervalId)
            } else {
                setRemainingTime(timeLeft)
            }
        }, 1000)

        return () => clearInterval(intervalId)
    }, [])

    if (remainingTime < 1000) {
        return null
    }

    const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000))
    let remainingMs = remainingTime - (days * 24 * 60 * 60 * 1000)
    const hours = Math.floor(remainingMs / (60 * 60 * 1000))
    remainingMs -= hours * 60 * 60 * 1000
    const minutes = Math.floor(remainingMs / (60 * 1000))
    remainingMs -= minutes * 60 * 1000
    const seconds = Math.floor(remainingMs / 1000)

    return (
        <p className={clsx('text-sm min-w-[110px]', className)}>
            {days > 0 && (
                <span className="mr-0.5">
                    {days}
                    <Trans id="d" />
                </span>
            )}
            {hours > 0 && (
                <span className="mr-0.5">
                    {hours}
                    <Trans id="h" />
                </span>
            )}
            {minutes > 0 && (
                <span className="mr-0.5">
                    {minutes}
                    m
                </span>
            )}
            <span className="mr-1">
                {seconds}
                s
            </span>
        </p>
    )
}

export default Countdown
