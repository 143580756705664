import React from 'react'

const ConditionalWrapper = ({
    condition,
    wrapper,
    children
}) => {
    if (condition) {
        return wrapper(children)
    }
    return children
}

export default ConditionalWrapper
