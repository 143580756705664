/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans, useLingui } from '@lingui/react'
import Button from '../utils/Button'
import QuickSearchSectionMobileDestinationSelect from './QuickSearchSectionMobileDestinationSelect'

const QuickSearchSectionMobileExperts = ({
    formState,
    onSearch,
    onConfirmDestination,
}) => {
    const { i18n } = useLingui()
    const { values, errors } = formState
    const { destination } = values

    return (
        <div>
            <QuickSearchSectionMobileDestinationSelect
                className="mb-4"
                destination={destination}
                title={<Trans id="Expertise" />}
                onConfirmDestination={onConfirmDestination}
                inputPlaceholder={i18n._('What location?')}
                settingsSlug="algemeen/expert-zoeken-instellingen"
                errors={errors.filter(({ path }) => path === 'destination')}
            />
            <Button
                size="large"
                type="button"
                onClick={onSearch}
                className="w-full"
                iconAfterName="search"
                variant="primary-filled"
            >
                <Trans id="Search Travel Designers" />
            </Button>
        </div>
    )
}

export default QuickSearchSectionMobileExperts
