import React from 'react'
import { Trans } from '@lingui/react'
import useAppState from './useAppState'
import useStoryblokQuery from './useStoryblokQuery'

export const MONTHS = [{
    month: 0,
    value: 'jan',
    label: <Trans id="January" />,
}, {
    month: 1,
    value: 'feb',
    label: <Trans id="February" />,
}, {
    month: 2,
    value: 'mar',
    label: <Trans id="March" />,
}, {
    month: 3,
    value: 'apr',
    label: <Trans id="April" />,
}, {
    month: 4,
    value: 'may',
    label: <Trans id="May" />,
}, {
    month: 5,
    value: 'jun',
    label: <Trans id="June" />,
}, {
    month: 6,
    value: 'jul',
    label: <Trans id="July" />,
}, {
    month: 7,
    value: 'aug',
    label: <Trans id="August" />,
}, {
    month: 8,
    value: 'sep',
    label: <Trans id="September" />,
}, {
    month: 9,
    value: 'oct',
    label: <Trans id="October" />,
}, {
    month: 10,
    value: 'nov',
    label: <Trans id="November" />,
}, {
    month: 11,
    value: 'dec',
    label: <Trans id="December" />,
}]

const useTravelSearchSettingsQuery = (formState) => {
    const { destinationCountryCodes, destinationContinentCodes } = useAppState()

    const {
        data: settingsData,
        error: settingsError,
        isLoading: isLoadingSettings,
    } = useStoryblokQuery(
        'travel-search-settings',
        'cdn/stories/reizen/zoeken',
        { resolve_relations: ['TimePeriod', 'TimeSearchSettings.timePeriods'] }
    )
    const {
        data: formulaData,
        error: formulaError,
        isLoading: isLoadingFormulas,
    } = useStoryblokQuery('travel-formulas', 'cdn/stories/', { content_type: 'TravelType' })
    const {
        data: themesData,
        error: themesError,
        isLoading: isLoadingThemes,
    } = useStoryblokQuery('travel-themes', 'cdn/stories/', { content_type: 'TravelTheme' })

    const themesStories = themesData?.data?.stories || []
    const formulaStories = formulaData?.data?.stories || []
    const timeSettings = settingsData?.data.story.content.settings.find(({ component }) => component === 'TimeSearchSettings')
    const destinationSettings = settingsData?.data.story.content.settings.find(({ component }) => component === 'DestinationSearchSettings')

    return {
        error: settingsError || formulaError || themesError,
        loading: isLoadingSettings || isLoadingFormulas || isLoadingThemes,
        months: MONTHS.map((month) => ({
            ...month,
            onChange: formState.onChange.months,
        })),
        travelThemes: themesStories?.map(({ content }) => ({
            value: content.name,
            label: content.name,
            onChange: formState.onChange.themes
        })),
        travelFormulas: formulaStories?.map(({ content }) => ({
            value: content.name,
            label: content.name,
            onChange: formState.onChange.formulas
        })),
        vacations: timeSettings?.timePeriods?.map(({ content }) => ({
            value: content.name,
            label: content.name,
            onChange: formState.onChange.vacations,
        })),
        countries: destinationSettings?.countryCodes?.map((code) => ({
            value: code,
            label: destinationCountryCodes[code],
            onChange: formState.onChange.countryCode,
        })),
        continents: destinationSettings?.continentCodes?.map((code) => ({
            value: code,
            label: destinationContinentCodes[code],
            onChange: formState.onChange.continentCodes,
        })),
    }
}

export default useTravelSearchSettingsQuery
