import React, { useMemo } from 'react'
import { Trans } from '@lingui/react'
import debounce from 'lodash.debounce'
import SearchListbox, {
    SearchListboxEmpty,
    SearchListboxLoading,
    SearchListboxError,
    SearchListboxResults
} from './SearchListbox'
import SearchListItem from './SearchListItem'
import useSearchCities from '../hooks/useSearchCities'

const SearchCitiesListBox = ({
    placeholder,
    destination,
    onChange,
    onClickOutside,
    onPressEscape,
    className,
    listboxClassName,
    testId,
}) => {
    const {
        data,
        error,
        isLoading,
        mutate: searchCities,
    } = useSearchCities()
    const onSearch = async (e) => {
        const { value = '' } = e.target
        if (value.length >= 3) {
            const variables = { query: value }
            searchCities(variables)
        }
    }
    const debouncedOnSearch = useMemo(() => debounce(onSearch, 1000), [])
    return (
        <SearchListbox
            className={className}
            onClickOutside={onClickOutside}
            onPressEscape={onPressEscape}
            onChange={debouncedOnSearch}
            placeholder={placeholder}
            testId={`${testId}Input`}
        >
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to find destination." />
                        </SearchListboxError>
                    )
                }
                if (isLoading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Searching destination..." />
                        </SearchListboxLoading>
                    )
                }
                if (!data) {
                    if (destination) {
                        return (
                            <SearchListboxResults className={listboxClassName}>
                                <SearchListItem
                                    isSelected
                                    iconName="pin"
                                    onClick={() => onChange(destination)}
                                >
                                    {destination.fullName}
                                </SearchListItem>
                            </SearchListboxResults>
                        )
                    }
                    return (
                        <SearchListboxEmpty>
                            <Trans id="Start typing to search destination." />
                        </SearchListboxEmpty>
                    )
                }
                const cities = data.searchCities.nodes
                return (
                    <SearchListboxResults className={listboxClassName}>
                        {cities.map((city) => (
                            <SearchListItem
                                key={city.id}
                                onClick={() => onChange(city)}
                                isSelected={destination && city.id === destination.id}
                                iconName="pin"
                            >
                                {city.fullName}
                            </SearchListItem>
                        ))}
                    </SearchListboxResults>
                )
            })()}
        </SearchListbox>
    )
}

export default SearchCitiesListBox
