import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans, useLingui } from '@lingui/react'
import QuickSearchSectionMobileDestinationButton from './QuickSearchSectionMobileDestinationButton'
import QuickSearchSectionMobileCarsDestinationModal from './QuickSearchSectionMobileCarsDestinationModal'

const QuickSearchSectionMobileCarsDestinationButtons = ({
    errors,
    className,
    toDestination,
    fromDestination,
    onConfirmToDestination,
    onConfirmFromDestination,
    hideToDestination = false,
    toLabel = <Trans id="Drop-off" />,
    fromLabel = <Trans id="Pick-up" />,
    toPlaceholder = <Trans id="Search a drop-off location" />,
    fromPlaceholder = <Trans id="Search a pick-up location" />,
}) => {
    const { i18n } = useLingui()
    const [isToOpen, setToOpen] = useState(false)
    const [isFromOpen, setFromOpen] = useState(false)
    const hasError = errors.length > 0

    return (
        <>
            {isFromOpen && (
                <QuickSearchSectionMobileCarsDestinationModal
                    title={fromLabel}
                    inputPlaceholder={i18n._('City')}
                    onClose={() => setFromOpen(false)}
                    initialDestination={fromDestination}
                    resultsPlaceholder={fromPlaceholder}
                    onConfirm={(destination) => {
                        onConfirmFromDestination(destination)
                        setFromOpen(false)
                    }}
                />
            )}
            {isToOpen && (
                <QuickSearchSectionMobileCarsDestinationModal
                    title={toLabel}
                    onClose={() => setToOpen(false)}
                    inputPlaceholder={i18n._('City')}
                    initialDestination={toDestination}
                    resultsPlaceholder={toPlaceholder}
                    onConfirm={(destination) => {
                        onConfirmToDestination(destination)
                        setToOpen(false)
                    }}
                />
            )}
            <div
                className={clsx(
                    'border w-full rounded',
                    hasError ? 'border-primary' : 'border-gray-100',
                    className,
                )}
            >
                <QuickSearchSectionMobileDestinationButton
                    placeholder={fromLabel}
                    isPlaceholder={!fromDestination}
                    onClick={() => setFromOpen(true)}
                    destination={fromDestination?.name}
                />
                {!hideToDestination && (
                    <>
                        <hr className="border-t border-gray-100" />
                        <QuickSearchSectionMobileDestinationButton
                            placeholder={toLabel}
                            isPlaceholder={!toDestination}
                            onClick={() => setToOpen(true)}
                            destination={toDestination?.name}
                        />
                    </>
                )}
            </div>
        </>
    )
}

export default QuickSearchSectionMobileCarsDestinationButtons
