import { gql } from 'graphql-request'
import useGraphqlMutation from './useGraphqlMutation'

const MUTATION = gql`
    mutation subscribeToNewsletter($email: String!, $firstName: String!, $lastName: String!) {
        subscribeToNewsletter(email: $email, firstName: $firstName, lastName: $lastName)
    }
`

const useSubscribeToNewsletterMutation = (options) => (
    useGraphqlMutation(MUTATION, options)
)

export default useSubscribeToNewsletterMutation
