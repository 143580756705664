import { gql } from 'graphql-request'
import useLazyGraphqlQuery from './useGraphqlMutation'

const QUERY = gql`
    query searchAirports($query: String!) {
        searchAirports(query: $query) {
            nodes {
                id
                code
                name
                fullName
                countryName
                countryCode
                regionCode
                isMetropolitan
                isTrainStation
            }
        }
    }
`

const useSearchAirports = (options) => (
    useLazyGraphqlQuery(QUERY, options)
)

export default useSearchAirports
