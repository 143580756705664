import React from 'react'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import usePageProps from '../hooks/usePageProps'

const HeaderWithContent = ({
    content,
}) => {
    const { data } = usePageProps()
    const finalContent = content || data.headerStory
    if (!finalContent) {
        return null
    }
    return (
        <header className="h-full">
            <HeaderDesktop content={finalContent} />
            <HeaderMobile content={finalContent} />
        </header>
    )
}

export default HeaderWithContent
