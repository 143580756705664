import React, { useState } from 'react'
import clsx from 'clsx'
import SbEditable from 'storyblok-react'
import { motion, AnimatePresence } from 'framer-motion'
import useInterval from '../hooks/useInterval'
import CarouselDots from '../utils/CarouselDots'
import HeaderDesktopMenuItemDropdownCard from './HeaderDesktopMenuItemDropdownCard'
import HeaderDesktopMenuItemDropdownLinkGroup from './HeaderDesktopMenuItemDropdownLinkGroup'

const MainWrapper = ({ isOpen, children }) => {
    if (!isOpen) {
        return null
    }

    return (
        <AnimatePresence>
            <motion.div
                exit="closed"
                animate="open"
                initial="closed"
                className="absolute top-full w-full left-0 bg-white z-50 overflow-hidden"
                variants={{
                    open: { height: 'auto' },
                    closed: { height: '22rem' }
                }}
            >
                <div className="flex container min-h-[22rem] justify-between space-x-8">
                    {children}
                </div>
            </motion.div>
        </AnimatePresence>
    )
}

const ContentWrapper = ({ children, className }) => (
    <motion.div
        exit="closed"
        animate="open"
        initial="closed"
        className={clsx('h-full flex flex-grow xl:pl-32 pt-9 pb-4', className)}
        variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 }
        }}
    >
        {children}
    </motion.div>
)

const HeaderDesktopMenuItemDropDown = ({
    content,
    isOpen,
}) => {
    const { cards, linkGroups } = content
    const [currentIndex, setCurrentIndex] = useState(0)
    const [hasDisabledAutoplay, setDisableAutoplay] = useState(false)
    useInterval(() => {
        if (currentIndex < cards.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            setCurrentIndex(0)
        }
    }, hasDisabledAutoplay ? null : 4000)
    const card = cards[currentIndex]
    return (
        <AnimatePresence>
            {isOpen && (
                <SbEditable content={content}>
                    <motion.div
                        className="absolute top-full w-full left-0 bg-white z-50 overflow-hidden"
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={{
                            open: { height: 'auto' },
                            closed: { height: '22rem' }
                        }}
                    >
                        {isOpen && (
                            <div className="flex container min-h-[22rem] justify-between space-x-8">
                                <motion.div
                                    className="h-full flex flex-grow xl:pl-32 pt-9 pb-4"
                                    initial="closed"
                                    animate="open"
                                    exit="closed"
                                    variants={{
                                        open: { opacity: 1 },
                                        closed: { opacity: 0 }
                                    }}
                                >
                                    {linkGroups.map((group) => (
                                        <HeaderDesktopMenuItemDropdownLinkGroup
                                            key={group._uid}
                                            content={group}
                                        />
                                    ))}
                                </motion.div>
                                {card && (
                                    <div className="w-[560px] bg-black relative">
                                        <AnimatePresence initial={false}>
                                            <motion.div
                                                key={currentIndex}
                                                className="w-full h-full absolute"
                                                initial="closed"
                                                animate="open"
                                                exit="closed"
                                                variants={{
                                                    open: { opacity: 1 },
                                                    closed: { opacity: 0 }
                                                }}
                                            >
                                                <HeaderDesktopMenuItemDropdownCard
                                                    content={card}
                                                />
                                            </motion.div>
                                        </AnimatePresence>
                                        {cards.length > 1 && (
                                            <CarouselDots
                                                size="small"
                                                count={cards.length}
                                                activeIndex={currentIndex}
                                                className="absolute top-0 right-0 z-10"
                                                onChangeIndex={(index) => {
                                                    setDisableAutoplay(true)
                                                    setCurrentIndex(index)
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </motion.div>
                </SbEditable>
            )}
        </AnimatePresence>
    )
}

HeaderDesktopMenuItemDropDown.MainWrapper = MainWrapper
HeaderDesktopMenuItemDropDown.ContentWrapper = ContentWrapper

export default HeaderDesktopMenuItemDropDown
