import React from 'react'
import QuickSearchSectionMobileExperts from './QuickSearchSectionMobileExperts'
import useQuickSearchExpertsFormState from './useQuickSearchExpertsFormState'

const QuickSearchSectionMobileExpertsWithState = ({
    iataCode,
    onSearch,
}) => {
    const formState = useQuickSearchExpertsFormState(iataCode)
    return (
        <QuickSearchSectionMobileExperts
            formState={formState}
            onConfirmDestination={(destination) => {
                formState.handleChange('destination', destination)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionMobileExpertsWithState
