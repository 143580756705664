import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import DateRangeButton from '../utils/DateRangeButton'
import DateRangeCalendar from '../utils/DateRangeCalendar'

const QuickSearchSectionDesktopFlightsDateRangeSelect = ({
    errors,
    disabled,
    onChange,
    className,
    isLoading,
    returnDate,
    departureDate,
    labelTextToday,
    labelTextLastSelected,
    dayIsDisabledCondition,
    labelTextFirstSelected,
    isOpen: isOpenFromProps,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasError = errors?.length > 0

    useEffect(() => {
        setIsOpen(isOpenFromProps)
    }, [isOpenFromProps])
    useEffect(() => {
        if (!!returnDate && !!departureDate) {
            setIsOpen(false)
        }
    }, [returnDate, departureDate])

    if (isLoading) {
        return <div className="skeleton h-12 rounded-sm" />
    }

    return (
        <div className={clsx('relative', className)}>
            <DateRangeButton
                disabled={disabled}
                hasError={hasError}
                returnDate={returnDate}
                departureDate={departureDate}
                onClickReturnDate={() => setIsOpen(true)}
                onClickDepartureDate={() => setIsOpen(true)}
            />
            {isOpen && (
                <div className="absolute z-10 -top-2 -left-2 px-2 pt-2 rounded-md w-[600px] shadow-2xl bg-white">
                    <DateRangeCalendar
                        returnDate={returnDate}
                        inputClassName="w-[444px]"
                        departureDate={departureDate}
                        labelTextToday={labelTextToday}
                        onConfirm={() => setIsOpen(false)}
                        onPressEscape={() => setIsOpen(false)}
                        onClickOutside={() => setIsOpen(false)}
                        labelTextLastSelected={labelTextLastSelected}
                        labelTextFirstSelected={labelTextFirstSelected}
                        dayIsDisabledCondition={dayIsDisabledCondition}
                        onChange={(departure, arrival) => {
                            onChange(departure, arrival)
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopFlightsDateRangeSelect
