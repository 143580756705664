import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import { SearchListboxError, SearchListboxLoading } from '../utils/SearchListbox'
import QuickSearchSectionTravelFormulas from './QuickSearchSectionTravelFormulas'

const QuickSearchSectionMobileTravelFormulaModal = ({
    error,
    loading,
    onClose,
    formState,
    hasValues,
    travelThemes,
    travelFormulas,
    ...props
}) => (
    <ModalSlideUp
        isFullScreen
        onClose={onClose}
        {...props}
    >
        <ModalSlideUpHeader
            onClose={onClose}
            title={<Trans id="What" />}
        />
        <ModalSlideUpContent className="px-2">
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to fetch travel formulas." />
                        </SearchListboxError>
                    )
                }
                if (loading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Loading travel formulas..." />
                        </SearchListboxLoading>
                    )
                }
                if (travelFormulas?.length <= 0 && travelThemes?.length <= 0) {
                    return (
                        <SearchListboxError>
                            <Trans id="No travel formulas available." />
                        </SearchListboxError>
                    )
                }
                return (
                    <QuickSearchSectionTravelFormulas
                        className="py-0"
                        values={formState.values}
                        travelThemes={travelThemes}
                        travelFormulas={travelFormulas}
                        onSelectDefault={() => {
                            formState.handleChange('formulas', [])
                            onClose()
                        }}
                    />
                )
            })()}
        </ModalSlideUpContent>
        <ModalSlideUpFooter className="flex items-center">
            <Button
                onClick={onClose}
                className="mr-4 w-full"
                variant="blue-outlined"
            >
                {hasValues
                    ? <Trans id="Select" />
                    : <Trans id="Close" />}
            </Button>
        </ModalSlideUpFooter>
    </ModalSlideUp>
)

export default QuickSearchSectionMobileTravelFormulaModal
