import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import useTravelSearchSettingsQuery from '../hooks/useTravelSearchSettingsQuery'
import useTravelSearchFilterFormState from '../pages/travel-search/useTravelSearchFilterFormState'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'
import QuickSearchSectionDesktopTravelPeriodSelect from './QuickSearchSectionDesktopTravelPeriodSelect'
import QuickSearchSectionDesktopTravelFormulaSelect from './QuickSearchSectionDesktopTravelFormulaSelect'
import QuickSearchSectionDesktopTravelDestinationSelect from './QuickSearchSectionDesktopTravelDestinationSelect'

const QuickSearchSectionDesktopTravelWithState = ({
    uid,
    onSearch,
}) => {
    const formState = useTravelSearchFilterFormState()
    const {
        error,
        months,
        loading,
        vacations,
        countries,
        continents,
        travelThemes,
        travelFormulas,
    } = useTravelSearchSettingsQuery(formState)

    return (
        <div>
            <div className="flex flex-wrap space-x-4">
                <QuickSearchSectionDesktopInputGroup
                    className="flex-1"
                    htmlFor={`travel-destination-${uid}`}
                    label={<Trans id="Where do you want to go?" />}
                >
                    <QuickSearchSectionDesktopTravelDestinationSelect
                        id={`travel-destination-${uid}`}
                        error={error}
                        loading={loading}
                        countries={countries}
                        formState={formState}
                        continents={continents}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <QuickSearchSectionDesktopInputGroup
                    className="flex-1"
                    htmlFor={`travel-travelFormula-${uid}`}
                    label={<Trans id="What are you looking for?" />}
                >
                    <QuickSearchSectionDesktopTravelFormulaSelect
                        id={`travel-travelFormula-${uid}`}
                        error={error}
                        loading={loading}
                        formState={formState}
                        travelFormulas={travelFormulas}
                        travelThemes={travelThemes}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <QuickSearchSectionDesktopInputGroup
                    htmlFor={`travel-period-${uid}`}
                    className="flex-1"
                    label={<Trans id="When do you want to travel?" />}
                >
                    <QuickSearchSectionDesktopTravelPeriodSelect
                        id={`travel-period-${uid}`}
                        error={error}
                        months={months}
                        loading={loading}
                        vacations={vacations}
                        formState={formState}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <div className="flex items-end">
                    <Button
                        size="large"
                        className="w-full"
                        iconBeforeName="search"
                        variant="primary-filled"
                        onClick={() => onSearch(formState.valuesToQueryString())}
                        data-testid="QuickSearchSectionDesktopExperiencesSearchButton"
                    >
                        <Trans id="Search$travel" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopTravelWithState
