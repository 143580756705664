import React from 'react'
import { Trans } from '@lingui/react'
import useFormState, { createFormValidation } from 'use-form-state'
import {
    useQueryParams,
    StringParam,
    withDefault,
} from 'use-query-params'
import useCarRentalLocationsSearchResults from '../hooks/useCarRentalLocationsSearchResults'
import { isRequired } from '@connections/utils'
import { getDateFromQueryParam } from './useQuickSearchFlightFormState'

const validation = createFormValidation([{
    path: 'pickUpDestination',
    validate: isRequired,
    message: <Trans id="The pick-up destination is required" />,
}, {
    path: 'dropOffDestination',
    validate: (value, { pickupIsSameAsDropOff }) => {
        if (pickupIsSameAsDropOff) {
            return true
        }
        return isRequired(value)
    },
    message: <Trans id="The drop-off destination is required" />,
}, {
    path: 'pickUpDate',
    validate: isRequired,
    message: <Trans id="The pick-up date is required" />,
}, {
    path: 'dropOffDate',
    validate: isRequired,
    message: <Trans id="The drop-off date is required" />,
}, {
    path: 'pickUpTime',
    validate: isRequired,
    message: <Trans id="The drop-off time is required" />,
}, {
    path: 'dropOffTime',
    validate: isRequired,
    message: <Trans id="The pick-up time is required" />,
}])

const useQuickSearchCarFormState = (pickUpIataCode, dropOffIataCode) => {
    const [query] = useQueryParams({
        'pick-up': withDefault(StringParam, null),
        'drop-off': withDefault(StringParam, null),
        'pick-up-date': withDefault(StringParam, null),
        'drop-off-date': withDefault(StringParam, null),
    })
    const hasPickUpIataCode = !!(pickUpIataCode || query['pick-up'])
    const hasDropOffIataCode = !!(dropOffIataCode || query['drop-off'])
    const pickUpIataCodes = hasPickUpIataCode ? [pickUpIataCode || query['pick-up']] : []
    const dropOffIataCodes = hasDropOffIataCode ? [dropOffIataCode || query['drop-off']] : []
    const [pickUpDestination = null] = useCarRentalLocationsSearchResults(pickUpIataCodes)
    const [dropOffDestination = null] = useCarRentalLocationsSearchResults(dropOffIataCodes)
    const pickUpDate = getDateFromQueryParam(query['pick-up-date'])
    const dropOffDate = getDateFromQueryParam(query['drop-off-date'])
    return useFormState({
        pickupIsSameAsDropOff: true,
        pickUpDestination,
        dropOffDestination,
        pickUpDate,
        dropOffDate,
        pickUpTime: '12:00',
        dropOffTime: '12:00',
    }, { validation })
}

export default useQuickSearchCarFormState
