'use client'

import React, { Suspense } from 'react'
import NextAdapterApp from 'next-query-params/app'
import { QueryParamProvider as UseQueryParamProvider } from 'use-query-params'

export default function QueryParamProvider({ children }) {
    return (
        <Suspense>
            <UseQueryParamProvider adapter={NextAdapterApp}>
                {children}
            </UseQueryParamProvider>
        </Suspense>
    )
}
