/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { getLinkLocale } from '../../util/routes'
import useCurrentPageSlugs from '../hooks/useCurrentPageSlugs'

const HeaderMobileMainMenuLanguageSelect = () => {
    const pathname = usePathname()
    const slugs = useCurrentPageSlugs()
    const params = pathname.split('?')[1]
    return (
        <ul className="flex justify-center mb-6 gap-4 w-full">
            {slugs.map(({ path, fullLocale, simpleLocale }) => (
                <li key={simpleLocale}>
                    <Link
                        className="uppercase font-medium text-sm text-blue-bright underline"
                        href={`/${getLinkLocale(fullLocale, path)}${path}${params ? `?${params}` : ''}`}
                    >
                        {simpleLocale}
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default HeaderMobileMainMenuLanguageSelect
