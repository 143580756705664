import React from 'react'
import clsx from 'clsx'
import Icon from '../utils/Icon'

const PillButton = ({
    onClick,
    children,
}) => (
    <button
        type="button"
        onClick={onClick}
        className="h-full flex items-center bg-blue px-2 py-1 rounded"
    >
        <p className="text-xs text-white">
            {children}
        </p>
        <Icon
            name="plus"
            color="white"
            size="smaller"
            className="rotate-45 ml-1"
        />
    </button>
)

const QuickSearchSectionTravelSelectPillValue = ({
    values,
    options,
    onReset,
    className,
    truncateValue,
}) => (truncateValue
    ? (
        <PillButton
            onClick={(e) => {
                e.stopPropagation()
                onReset()
            }}
        >
            {truncateValue}
        </PillButton>
    ) : (
        <div className={clsx('flex flex-wrap gap-x-2 gap-y-1', className)}>
            {values.map((formValue) => {
                const option = options.find(({ value }) => value === formValue)
                return (
                    <PillButton
                        key={formValue}
                        onClick={(e) => {
                            e.stopPropagation()
                            option.onChange(formValue)
                        }}
                    >
                        {option.label}
                    </PillButton>
                )
            })}
        </div>
    ))

export default QuickSearchSectionTravelSelectPillValue
