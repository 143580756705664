import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/react'
import PassengersListbox from '../utils/PassengersListbox'
import Button, { VARIANT_BLUE_FILLED } from '../utils/Button'
import PassengerSelectButton from '../utils/PassengerSelectButton'

const QuickSearchSectionDesktopFlightsPassengersSelect = ({
    errors,
    fareType,
    disabled,
    fareTypes,
    isLoading,
    numberOfAdults,
    numberOfInfants,
    numberOfChildren,
    onChangeFareType,
    minNumberOfTravelers,
    onChangeNumberOfAdults,
    isOpen: isOpenFromProps,
    onChangeNumberOfInfants,
    onChangeNumberOfChildren,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasError = errors.length > 0

    useEffect(() => {
        setIsOpen(isOpenFromProps)
    }, [isOpenFromProps])

    if (isLoading) {
        return <div className="skeleton h-12 rounded-sm" />
    }

    return (
        <div className="relative">
            <PassengerSelectButton
                hasError={hasError}
                disabled={disabled}
                onClick={() => setIsOpen(true)}
                numberOfAdults={numberOfAdults}
                numberOfInfants={numberOfInfants}
                numberOfChildren={numberOfChildren}
            />
            {isOpen && (
                <div className="absolute z-10 -top-2 -left-2 p-2 rounded-md w-[20rem] shadow-lg bg-white">
                    <PassengersListbox
                        fareTypes={fareTypes}
                        minNumberOfTravelers={minNumberOfTravelers}
                        numberOfAdults={numberOfAdults}
                        numberOfChildren={numberOfChildren}
                        numberOfInfants={numberOfInfants}
                        fareType={fareType}
                        onClickOutside={() => setIsOpen(false)}
                        onPressEscape={() => setIsOpen(false)}
                        onChangeNumberOfAdults={onChangeNumberOfAdults}
                        onChangeNumberOfChildren={onChangeNumberOfChildren}
                        onChangeNumberOfInfants={onChangeNumberOfInfants}
                        onChangeFareType={onChangeFareType}
                    />
                    <div className="px-2 pt-2 pb-4">
                        <Button
                            className="w-full"
                            variant={VARIANT_BLUE_FILLED}
                            iconAfterName="arrow-right"
                            onClick={() => setIsOpen(false)}
                            data-testid="PassengersConfirmButtonDesktop"
                        >
                            <Trans id="Confirm" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopFlightsPassengersSelect
