import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const SwitchButton = ({
    onClick,
    className,
}) => (
    <button
        type="button"
        className={clsx(
            className,
            'border-gray-100 border rounded-full w-8 h-8 bg-white flex items-center justify-center text-gray-400',
            'transition-colors hover:bg-gray-50 hover:text-gray-600',
        )}
        onClick={onClick}
    >
        <Icon name="exchange-alt" />
    </button>
)

export default SwitchButton
