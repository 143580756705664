import React from 'react'
import SbEditable from 'storyblok-react'
import HeaderDesktopMenuItemDropdownLink from './HeaderDesktopMenuItemDropdownLink'

const HeaderDesktopMenuItemDropdownLinkGroup = ({
    content,
}) => {
    const { title, links } = content
    return (
        <SbEditable content={content}>
            <div className="flex-1 flex justify-start">
                <div>
                    <h3 className="uppercase text-xs text-blue font-bold tracking-widest pl-3 mb-5 border-l-3 border-blue">
                        {title}
                    </h3>
                    <ul>
                        {links.map((link) => (
                            <HeaderDesktopMenuItemDropdownLink
                                key={link._uid}
                                content={link}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </SbEditable>
    )
}

export default HeaderDesktopMenuItemDropdownLinkGroup
