import React, { useEffect, useState } from 'react'
import Icon from '../utils/Icon'
import useFormatDate from '../hooks/useFormatDate'

const QuickSearchSectionMobileFlightsCollapsed = ({
    onOpen,
    formState,
    flightModes,
    isSearching,
}) => {
    const format = useFormatDate()
    const [offset, setOffset] = useState(0)
    const { values } = formState
    const {
        flightMode,
        returnDate,
        extraSegments,
        toDestination,
        departureDate,
        fromDestination,
    } = values

    useEffect(() => {
        const callback = () => {
            const headerHeight = document.getElementById('HeaderMobile').scrollHeight
            if (window.scrollY < headerHeight || offset < headerHeight) {
                setOffset((window.scrollY > headerHeight ? headerHeight : window.scrollY) + 1)
            }
        }
        window.addEventListener('scroll', callback)

        return () => window.removeEventListener('scroll', callback)
    }, [offset])

    return (
        <button
            type="button"
            onClick={onOpen}
            disabled={isSearching}
            style={{ marginTop: -offset }}
            id="QuickSearchSectionMobileFlightsCollapsed"
            className="fixed w-screen z-10 flex justify-between bg-white px-5 py-2 text-sm border-b border-gray-100 gap-x-4"
        >
            <div className="text-left">
                <p className="font-medium text-blue-bright">
                    {fromDestination?.name}
                    {` [${fromDestination?.code}]`}
                </p>
                {departureDate ? (
                    <p className="text-gray-600 mt-1">
                        {format(departureDate, 'dd/MM/yyyy')}
                    </p>
                ) : null}
            </div>
            <div>
                <div className="flex items-center gap-x-1">
                    <div className="flex-1 h-px bg-blue-bright" />
                    <Icon
                        name="plane"
                        size="small"
                        color="blue-bright"
                        className="rotate-90"
                    />
                </div>
                <p className="mt-1 text-gray-600">
                    {flightModes.find(({ value }) => value === flightMode)?.label}
                </p>
            </div>
            <div className="text-left">
                {extraSegments?.length > 0 ? (
                    <p className="font-medium text-blue-bright">
                        {extraSegments[extraSegments.length - 1].toDestination?.name}
                        {` [${extraSegments[extraSegments.length - 1].toDestination?.code}]`}
                    </p>
                ) : (
                    <>
                        <p className="font-medium text-blue-bright">
                            {toDestination?.name}
                            {` [${toDestination?.code}]`}
                        </p>
                        {returnDate ? (
                            <p className="text-gray-600 mt-1">
                                {format(returnDate, 'dd/MM/yyyy')}
                            </p>
                        ) : null}
                    </>
                )}
            </div>
            <Icon
                name="pen"
                size="small"
                className="my-auto shrink-0"
                color={isSearching ? 'white' : 'gray'}
            />
        </button>
    )
}

export default QuickSearchSectionMobileFlightsCollapsed
