/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import SbEditable from 'storyblok-react'
import NextLink from 'next/link'
import usePageProps from '../hooks/usePageProps'
import Icon from '../utils/Icon'

const FooterSocialLink = ({
    content,
}) => {
    const { href, iconName, openInNewTab } = content
    const { isPreview } = usePageProps()
    return (
        <SbEditable content={content}>
            <li className="mr-6 hover:opacity-80">
                <NextLink legacyBehavior href={href}>
                    <a
                        target={openInNewTab ? '_blank' : undefined}
                        onClick={(e) => {
                            if (isPreview) {
                                e.preventDefault()
                            }
                        }}
                    >
                        <Icon name={iconName} />
                    </a>
                </NextLink>
            </li>
        </SbEditable>
    )
}

export default FooterSocialLink
