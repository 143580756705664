import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { useLingui } from '@lingui/react'
import useSearchState from '../hooks/useSearchState'
import useBlockBodyScrolling from '../hooks/useBlockBodyScrolling'
import HeaderMobileSubMenu from './HeaderMobileSubMenu'
import HeaderMobileMainMenu from './HeaderMobileMainMenu'
import HeaderMobileSubNavBar from './HeaderMobileSubNavBar'
import HeaderMobileMainNavBar from './HeaderMobileMainNavBar'
import HeaderMobileMenuTravelSearch from './HeaderMobileMenuTravelSearch'

const HeaderMobile = ({
    content,
}) => {
    const { i18n } = useLingui()
    const [menuIsOpen, setIsMenuOpen] = useState(false)
    const { formState, handleSearch } = useSearchState()
    const [activeMenuItem, setActiveMenuItem] = useState(null)
    useBlockBodyScrolling(menuIsOpen)

    const {
        menuItems,
        contactText,
        topBarMenuItems,
    } = content
    const subMenuIsOpen = activeMenuItem !== null

    return (
        <SbEditable content={content}>
            <div
                id="HeaderMobile"
                data-testid="HeaderMobile"
                className="bg-white border-b border-gray-100 relative h-full z-header lg:hidden"
            >
                {(() => {
                    if (subMenuIsOpen) {
                        return (
                            <HeaderMobileSubNavBar
                                activeMenuItem={activeMenuItem}
                                onClose={() => setActiveMenuItem(null)}
                            />
                        )
                    }
                    return (
                        <HeaderMobileMainNavBar
                            menuIsOpen={menuIsOpen}
                            onToggleMenu={() => setIsMenuOpen(!menuIsOpen)}
                        />
                    )
                })()}
            </div>
            {menuIsOpen && (
                <div className="fixed bg-white top-16 bottom-0 left-0 right-0 z-50 overflow-x-hidden overflow-y-scroll lg:hidden">
                    {(() => {
                        if (subMenuIsOpen) {
                            if (activeMenuItem.linkStoryComponent === 'TravelSearchPage') {
                                return (
                                    <HeaderMobileMenuTravelSearch
                                        onSearch={() => {
                                            setActiveMenuItem(null)
                                            setIsMenuOpen(false)
                                        }}
                                    />
                                )
                            }

                            return <HeaderMobileSubMenu activeMenuItem={activeMenuItem} />
                        }
                        return (
                            <HeaderMobileMainMenu
                                i18n={i18n}
                                menuItems={menuItems}
                                formState={formState}
                                onSearch={handleSearch}
                                contactText={contactText}
                                topBarMenuItems={topBarMenuItems}
                                onOpenMenuItem={(item) => setActiveMenuItem(item)}
                            />
                        )
                    })()}
                </div>
            )}
        </SbEditable>
    )
}

export default HeaderMobile
