import { useEffect, useRef } from 'react'

const useInterval = (callback, delay, startOnMount = true) => {
    const savedCallback = useRef(callback)
    const intervalRef = useRef(null)

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    const startInterval = () => {
        // Don't schedule if no delay is specified or interval is already running.
        if (!intervalRef.current && delay !== null) {
            intervalRef.current = setInterval(() => savedCallback.current(), delay)
        }
    }

    const stopInterval = () => {
        clearInterval(intervalRef.current)
        intervalRef.current = null
    }

    const resetInterval = () => {
        stopInterval()
        startInterval()
    }

    // Set up the interval.
    useEffect(() => {
        if (startOnMount) {
            startInterval()
        }
        return stopInterval
    }, [delay])

    return {
        resetInterval,
        stopInterval,
        startInterval,
    }
}

export default useInterval
