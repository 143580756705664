import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import useLocaleNavigateTo from '../hooks/useLocaleNavigateTo'
import useTravelSearchSettingsQuery from '../hooks/useTravelSearchSettingsQuery'
import TravelSearchListInput from '../pages/travel-search/TravelSearchListInput'
import TravelSearchCountryInput from '../pages/travel-search/TravelSearchCountryInput'
import useTravelSearchFilterFormState from '../pages/travel-search/useTravelSearchFilterFormState'
import HeaderDesktopMenuItemDropDown from './HeaderDesktopMenuItemDropDown'

const HeaderDesktopMenuTravelSearch = ({
    isOpen,
}) => {
    const navigateTo = useLocaleNavigateTo()
    const formState = useTravelSearchFilterFormState()
    const {
        months,
        vacations,
        countries,
        continents,
        travelThemes,
        travelFormulas,
    } = useTravelSearchSettingsQuery(formState)
    const { values } = formState

    const handleSearch = () => {
        navigateTo({ pathname: '/reizen/zoeken', params: formState.valuesToQueryString() })
    }

    return (
        <HeaderDesktopMenuItemDropDown.MainWrapper isOpen={isOpen}>
            <HeaderDesktopMenuItemDropDown.ContentWrapper className="!pt-4">
                <div className="grid grid-cols-4 gap-x-8">
                    <TravelSearchListInput
                        options={continents}
                        selectedOptions={values.continentCodes}
                    >
                        <Trans id="Regions" />
                    </TravelSearchListInput>
                    <TravelSearchListInput
                        columns={2}
                        options={months}
                        selectedOptions={values.months}
                    >
                        <Trans id="Months" />
                    </TravelSearchListInput>
                    <TravelSearchListInput
                        columns={2}
                        options={travelThemes}
                        selectedOptions={values.themes}
                    >
                        <Trans id="Travel themes" />
                    </TravelSearchListInput>
                    <TravelSearchListInput
                        options={travelFormulas}
                        selectedOptions={values.formulas}
                    >
                        <Trans id="Travel formulas" />
                    </TravelSearchListInput>
                    <TravelSearchCountryInput
                        countries={countries}
                        selectedCountryCode={values.countryCode}
                        onChangeCountryCode={formState.onChange.countryCode}
                    />
                    <TravelSearchListInput
                        options={vacations}
                        selectedOptions={values.vacations}
                    >
                        <Trans id="Vacation periods" />
                    </TravelSearchListInput>
                    <div />
                    <Button
                        size="large"
                        onClick={handleSearch}
                        iconBeforeName="search"
                        variant="primary-filled"
                        className="h-fit my-auto"
                    >
                        <Trans id="Search$travel" />
                    </Button>
                </div>
            </HeaderDesktopMenuItemDropDown.ContentWrapper>
        </HeaderDesktopMenuItemDropDown.MainWrapper>
    )
}

export default HeaderDesktopMenuTravelSearch
