import React, { forwardRef } from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const SelectButton = forwardRef(({
    onClear,
    disabled,
    hasError,
    children,
    className,
    isPlaceholder,
    ...props
}, ref) => (
    <button
        ref={ref}
        type="button"
        disabled={disabled}
        className={clsx([
            'flex justify-between border py-3 pl-4 pr-2 w-full leading-[22px] rounded text-sm text-left focus:outline-default-input',
            isPlaceholder && 'text-gray-200',
            hasError ? 'border-primary' : 'border-gray-100 hover:border-gray-300',
            className,
        ])}
        {...props}
    >
        <span className="w-10/12 truncate">
            {children}
        </span>
        {(() => {
            if (disabled) {
                return null
            }

            if (onClear) {
                return (
                    <button
                        type="button"
                        onClick={onClear}
                    >
                        <Icon
                            name="cross"
                            color="black"
                            size="smaller"
                            className="mt-px"
                        />
                    </button>
                )
            }

            return (
                <Icon
                    size="small"
                    color="black"
                    className="mt-px"
                    name="chevron-down"
                />
            )
        })()}
    </button>
))

export default SelectButton
