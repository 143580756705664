import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import SelectButton from '../utils/SelectButton'
import SearchCitiesListBox from '../utils/SearchCitiesListBox'
import SearchAirportsListBox from '../utils/SearchAirportsListBox'
import SearchDestinationsListBox from '../utils/SearchDestinationsListBox'
import SearchCarRentalLocationListBox from '../utils/SearchCarRentalLocationListBox'

const getDestinationName = (destination = null, placeholder) => {
    if (destination === null) {
        return placeholder
    }
    if (destination.isContinent) {
        return (
            <>
                {destination.name} <Trans id="(All countries)" />
            </>
        )
    }
    if (destination.isCountry) {
        return (
            <>
                {destination.name} <Trans id="(All cities)" />
            </>
        )
    }
    return destination.name
}

const QuickSearchSectionDesktopDestinationSelect = ({
    id,
    title,
    testId,
    errors,
    onChange,
    disabled,
    className,
    isLoading,
    destination,
    selectClassName,
    inputPlaceholder,
    canSearch = true,
    defaultIataCodes,
    resultsPlaceholder,
    variant = 'airports',
    isOpen: isOpenFromProps,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(isOpenFromProps)
    }, [isOpenFromProps])

    let settingsSlug = null
    const hasError = errors.length > 0
    let SearchListBox = SearchAirportsListBox

    if (variant === 'cities') {
        SearchListBox = SearchCitiesListBox
    }
    if (variant === 'car-rental-locations') {
        SearchListBox = SearchCarRentalLocationListBox
    }
    if (variant === 'destinations-experiences') {
        SearchListBox = SearchDestinationsListBox
        settingsSlug = 'algemeen/experience-zoeken-instellingen'
    }
    if (variant === 'destinations-experts') {
        SearchListBox = SearchDestinationsListBox
        settingsSlug = 'algemeen/expert-zoeken-instellingen'
    }

    if (isLoading) {
        return <div className="skeleton h-12 rounded-sm" />
    }

    return (
        <div className={clsx('relative', className)}>
            <SelectButton
                hasError={hasError}
                data-testid={testId}
                className={selectClassName}
                isPlaceholder={!destination}
                onClick={() => setIsOpen(true)}
                onClear={destination ? () => onChange(null) : null}
                disabled={disabled || (!canSearch && defaultIataCodes?.length === 0)}
            >
                {getDestinationName(destination, inputPlaceholder)}
            </SelectButton>
            {isOpen && (
                <SearchListBox
                    id={id}
                    title={title}
                    testId={testId}
                    canSearch={canSearch}
                    destination={destination}
                    settingsSlug={settingsSlug}
                    inputPlaceholder={inputPlaceholder}
                    defaultIataCodes={defaultIataCodes}
                    onPressEscape={() => setIsOpen(false)}
                    resultsPlaceholder={resultsPlaceholder}
                    onClickOutside={() => setIsOpen(false)}
                    onChange={(dest) => {
                        setIsOpen(false)
                        onChange(dest)
                    }}
                    listboxClassName={clsx(
                        'overflow-y-scroll',
                        variant === 'destinations' ? 'h-[375px]' : 'h-[350px]',
                    )}
                    className={clsx(
                        'absolute z-10 -top-2 -left-2 p-2 rounded-md shadow-lg',
                        variant === 'destinations' ? 'w-[550px]' : 'w-[450px]',
                    )}
                />
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopDestinationSelect
