import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import DateButton from '../utils/DateButton'
import DateCalendar from '../utils/DateCalendar'

const QuickSearchSectionDesktopFlightsDateSelect = ({
    date,
    errors,
    disabled,
    viewDate,
    onChange,
    isLoading,
    className,
    dayIsDisabledCondition,
    isOpen: isOpenFromProps,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasError = errors.length > 0

    useEffect(() => {
        setIsOpen(isOpenFromProps)
    }, [isOpenFromProps])
    useEffect(() => {
        if (date) {
            setIsOpen(false)
        }
    }, [date])

    if (isLoading) {
        return <div className="skeleton h-12 rounded-sm" />
    }

    return (
        <div className={clsx('relative', className)}>
            <DateButton
                date={date}
                hasError={hasError}
                disabled={disabled}
                testId="DateButtonDeparture"
                onClick={() => setIsOpen(true)}
                placeholder={<Trans id="Departure" />}
                className="text-center whitespace-nowrap"
            />
            {isOpen && (
                <div className="absolute z-10 -top-2 -left-2 px-2 pt-2 rounded-md w-[400px] shadow-2xl bg-white">
                    <DateCalendar
                        date={date}
                        viewDate={viewDate}
                        onChange={onChange}
                        testId="DateDepartureCalendar"
                        inputClassName="text-center w-[170px]"
                        onPressEscape={() => setIsOpen(false)}
                        onClickOutside={() => setIsOpen(false)}
                        dayIsDisabledCondition={dayIsDisabledCondition}
                    />
                </div>
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopFlightsDateSelect
