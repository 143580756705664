import React, { useState } from 'react'
import usePageProps from '../hooks/usePageProps'
import AnnouncementBarSlider from './AnnouncementBarSlider'

const AnnouncementBarSliderWithState = ({
    className,
    announcements: announcementsFromProps = [],
    showAnnouncementsOnAllPages: showAnnouncementsOnAllPagesFromProps = false,
}) => {
    const page = usePageProps()
    const [isDismissed, setIsDismissed] = useState(false)
    const isHome = page.data.pageStory?.full_slug?.includes('home')
    const announcements = announcementsFromProps.length > 0 ? announcementsFromProps : page.data.headerStory.announcements
    const showAnnouncementsOnAllPages = showAnnouncementsOnAllPagesFromProps || page.data.headerStory.showAnnouncementsOnAllPages

    const isShown = showAnnouncementsOnAllPages || isHome
    if (!isShown) {
        return null
    }

    const [firstAnnouncement] = announcements
    if (!firstAnnouncement) {
        return null
    }

    const now = new Date()
    const deadline = new Date(firstAnnouncement.deadline)
    const remainingTime = deadline.getTime() - now.getTime()
    if (remainingTime <= 0) {
        return null
    }

    const handleDismiss = () => {
        setIsDismissed(true)
    }

    return (
        <AnnouncementBarSlider
            className={className}
            // @TODO: When we implement the use of multiple announcements instead of one we have to
            // send an array of active announcements instead of isDismissed prop
            isDismissed={isDismissed}
            onDismiss={handleDismiss}
            activeAnnouncement={firstAnnouncement}
        />
    )
}

export default AnnouncementBarSliderWithState
