import React, { useMemo } from 'react'
import { Trans } from '@lingui/react'
import debounce from 'lodash.debounce'
import useSearchCarRentalLocations from '../hooks/useSearchCarRentalLocations'
import SearchListbox, {
    SearchListboxEmpty,
    SearchListboxError,
    SearchListboxLoading,
    SearchListboxResults,
} from './SearchListbox'
import SearchListItem from './SearchListItem'

const SearchCarRentalLocationListBox = ({
    testId,
    onChange,
    className,
    destination,
    onPressEscape,
    onClickOutside,
    listboxClassName,
    inputPlaceholder,
    resultsPlaceholder,
}) => {
    const {
        data,
        error,
        isLoading,
        mutate: searchCarRentalLocations,
    } = useSearchCarRentalLocations()

    const onSearch = async (e) => {
        const { value = '' } = e.target
        if (value.length >= 3) {
            const variables = { query: value }
            searchCarRentalLocations(variables)
        }
    }
    const debouncedOnSearch = useMemo(() => debounce(onSearch, 1000), [])

    return (
        <SearchListbox
            className={className}
            testId={`${testId}Input`}
            onChange={debouncedOnSearch}
            onPressEscape={onPressEscape}
            placeholder={inputPlaceholder}
            onClickOutside={onClickOutside}
        >
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to find location." />
                        </SearchListboxError>
                    )
                }
                if (isLoading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Searching location..." />
                        </SearchListboxLoading>
                    )
                }
                if (!data) {
                    if (destination) {
                        return (
                            <SearchListboxResults className={listboxClassName}>
                                <SearchListItem
                                    isSelected
                                    iconName="pin"
                                    onClick={() => onChange(destination)}
                                >
                                    {destination.fullName}
                                </SearchListItem>
                            </SearchListboxResults>
                        )
                    }
                    return (
                        <SearchListboxEmpty>
                            {resultsPlaceholder}
                        </SearchListboxEmpty>
                    )
                }
                const carRentalLocations = data.searchCarRentalLocations.nodes
                return (
                    <SearchListboxResults className={listboxClassName}>
                        {carRentalLocations.map((location) => (
                            <SearchListItem
                                iconName="pin"
                                key={location.id}
                                onClick={() => onChange(location)}
                                isSelected={destination && location.id === destination.id}
                            >
                                {location.fullName}
                            </SearchListItem>
                        ))}
                    </SearchListboxResults>
                )
            })()}
        </SearchListbox>
    )
}

export default SearchCarRentalLocationListBox
