import React from 'react'
import SbEditable from 'storyblok-react'
import TextLink from '../utils/TextLink'

const FooterDisclaimerLink = ({
    content,
}) => {
    const { href, text, openInNewTab } = content
    return (
        <SbEditable content={content}>
            <TextLink
                href={href}
                openInNewTab={openInNewTab}
                className="text-xs ml-4 hover:underline"
            >
                {text}
            </TextLink>
        </SbEditable>
    )
}

export default FooterDisclaimerLink
