import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import TravelSearchListInput from '../pages/travel-search/TravelSearchListInput'
import TravelSearchCountryInput from '../pages/travel-search/TravelSearchCountryInput'

const QuickSearchSectionTravelDestinations = ({
    className,
    countries,
    continents,
    onSelectDefault,
    onChangeCountryCode,
    selectedCountryCode,
    selectedContinentCodes,
}) => (
    <div
        className={clsx(
            'flex flex-col py-4',
            className,
        )}
    >
        <button
            type="button"
            onClick={onSelectDefault}
            className="flex-1 flex justify-between items-center bg-gray-50 -mx-2 mb-3 px-5 py-3"
        >
            <div className="flex items-center">
                <Icon
                    size="smaller"
                    name="location-point"
                />
                <div className="text-sm text-start ml-2">
                    <p className="font-medium">
                        <Trans id="Show me all destinations" />
                    </p>
                </div>
            </div>
            <Icon
                color="gray"
                name="arrow-right"
            />
        </button>
        <div className="px-3.5">
            <TravelSearchListInput
                className="mb-6"
                options={continents}
                selectedOptions={selectedContinentCodes}
            />
            <TravelSearchCountryInput
                countries={countries}
                selectedCountryCode={selectedCountryCode}
                onChangeCountryCode={onChangeCountryCode}
            />
        </div>
    </div>
)

export default QuickSearchSectionTravelDestinations
