import React from 'react'
import clsx from 'clsx'
import { variant } from '../../../util'
import CheckboxInput from '../../utils/CheckboxInput'

const TravelSearchListInput = ({
    options,
    children,
    className,
    columns = 1,
    selectedOptions,
}) => {
    if (!options || options.length <= 0) {
        return null
    }

    return (
        <div className={className}>
            <h5 className="text-xs text-gray-600 font-medium uppercase mb-4">
                {children}
            </h5>
            <div
                className={clsx('grid gap-y-1', variant(columns, {
                    1: 'grid-cols-1',
                    2: 'grid-cols-2'
                }))}
            >
                {options.map(({ value, label, onChange }) => (
                    <CheckboxInput
                        key={value}
                        onChange={() => onChange(value)}
                        value={selectedOptions.includes(value)}
                    >
                        <p className="text-sm mr-2 whitespace-nowrap truncate">
                            {label}
                        </p>
                    </CheckboxInput>
                ))}
            </div>
        </div>
    )
}

export default TravelSearchListInput
