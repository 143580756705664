import React from 'react'
import { Trans } from '@lingui/react'
import useFormState, { createFormValidation } from 'use-form-state'
import {
    useQueryParams,
    StringParam,
    withDefault,
} from 'use-query-params'
import useAppState from '../hooks/useAppState'
import { isRequired } from '@connections/utils'

const validation = createFormValidation([{
    path: 'destination',
    validate: isRequired,
    message: <Trans id="The destination is required" />,
}])

export const useExpertsSearchQueryParams = () => (
    useQueryParams({
        continentCode: withDefault(StringParam, null),
        countryCode: withDefault(StringParam, null),
        iataCode: withDefault(StringParam, null),
        type: withDefault(StringParam, null),
    })
)

const useQuickSearchExpertsFormState = () => {
    const { destinations } = useAppState()
    const [query] = useExpertsSearchQueryParams()
    const destination = destinations.find(({ value }) => (
        query.continentCode === value
        || query.countryCode === value
        || query.iataCode === value
    )) || null
    const formState = useFormState({
        destination,
    }, { validation })
    return formState
}

export default useQuickSearchExpertsFormState
