import React from 'react'
import QuickSearchSectionMobileExperiences from './QuickSearchSectionMobileExperiences'
import useQuickSearchExperienceFormState, { useExperienceTypeOptions } from './useQuickSearchExperienceFormState'

const QuickSearchSectionMobileExperiencesWithState = ({
    iataCode,
    onSearch,
}) => {
    const formState = useQuickSearchExperienceFormState(iataCode)
    const experienceTypeOptions = useExperienceTypeOptions()
    return (
        <QuickSearchSectionMobileExperiences
            formState={formState}
            experienceTypeOptions={experienceTypeOptions}
            onConfirmDestination={(destination) => {
                formState.handleChange('destination', destination)
            }}
            onConfirmType={(type) => {
                formState.handleChange('type', type)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionMobileExperiencesWithState
