/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import QuickSearchSectionMobileDestinationSelect from './QuickSearchSectionMobileDestinationSelect'
import QuickSearchSectionMobileSelect from './QuickSearchSectionMobileSelect'

const QuickSearchSectionMobileExperiences = ({
    formState,
    experienceTypeOptions,
    onConfirmDestination,
    onConfirmType,
    onSearch,
}) => {
    const { values, errors } = formState
    const { destination, type } = values
    return (
        <div>
            <QuickSearchSectionMobileDestinationSelect
                className="mb-4"
                placeholder={<Trans id="City" />}
                errors={errors.filter(({ path }) => path === 'destination')}
                destination={destination}
                onConfirmDestination={onConfirmDestination}
            />
            <QuickSearchSectionMobileSelect
                title={<Trans id="Experience types" />}
                className="mb-4"
                modalClassName="h-[500px]"
                value={type}
                options={experienceTypeOptions}
                onConfirm={onConfirmType}
            />
            <Button
                type="button"
                variant="primary-filled"
                size="large"
                className="w-full"
                iconAfterName="search"
                onClick={onSearch}
            >
                <Trans id="Search experiences" />
            </Button>
        </div>
    )
}

export default QuickSearchSectionMobileExperiences
