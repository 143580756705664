import React from 'react'
import SbEditable from 'storyblok-react'
import FooterLink from './FooterLink'

const FooterLinkGroup = ({
    content,
}) => {
    const { title, links } = content
    return (
        <SbEditable content={content}>
            <div className="flex-1">
                <h3 className="uppercase text-xs text-white font-bold tracking-widest pl-3 mb-5 border-l-3 border-white">
                    {title}
                </h3>
                <ul className="grid grid-cols-2 ">
                    {links.map((link) => (
                        <FooterLink
                            key={link._uid}
                            content={link}
                        />
                    ))}
                </ul>
            </div>
        </SbEditable>
    )
}

export default FooterLinkGroup
