import React from 'react'
import Icon from '../utils/Icon'
import ContactPhoneLink from '../utils/ContactPhoneLink'
import HeaderMobileMenuItemWithContent from './HeaderMobileMenuItemWithContent'
import HeaderMobileMainMenuLanguageSelect from './HeaderMobileMainMenuLanguageSelect'
import HeaderMobileTopBarMenuItemWithContent from './HeaderMobileTopBarMenuItemWithContent'

const HeaderMobileMainMenu = ({
    i18n,
    onSearch,
    formState,
    menuItems,
    contactText,
    onOpenMenuItem,
    topBarMenuItems,
}) => {
    const { getNativeInputProps } = formState

    return (
        <div className="min-h-full flex flex-col justify-between">
            <div>
                <ul className="divide-y divide-gray-100 mb-6">
                    <li>
                        <form
                            className="flex items-center px-4 py-3 font-medium"
                            onSubmit={onSearch}
                        >
                            <input
                                className="p-3 flex-1 mr-2"
                                placeholder={i18n._(/*i18n*/'Search...')}
                                {...getNativeInputProps('query')}
                            />
                            <button
                                type="submit"
                                className="bg-blue-bright rounded w-12 h-12 flex items-center justify-center text-white flex-shrink-0"
                            >
                                <Icon name="search" />
                            </button>
                        </form>
                    </li>
                    {menuItems.map((item) => (
                        <HeaderMobileMenuItemWithContent
                            content={item}
                            key={item._uid}
                            onOpenMenuItem={onOpenMenuItem}
                        />
                    ))}
                </ul>
                <ul>
                    {topBarMenuItems.map((item) => (
                        <HeaderMobileTopBarMenuItemWithContent
                            content={item}
                            key={item._uid}
                        />
                    ))}
                </ul>
            </div>
            <div>
                <HeaderMobileMainMenuLanguageSelect />
                <div className="flex text-sm mb-6 justify-center">
                    <div>{contactText}</div>
                    <div className="ml-1">
                        <ContactPhoneLink />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderMobileMainMenu
