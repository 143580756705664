import React from 'react'
import QuickSearchSectionDesktopExperiences from './QuickSearchSectionDesktopExperiences'
import useQuickSearchExperienceFormState, { useExperienceTypeOptions } from './useQuickSearchExperienceFormState'

const QuickSearchSectionDesktopExperiencesWithState = ({
    uid,
    iataCode,
    onSearch
}) => {
    const formState = useQuickSearchExperienceFormState(iataCode)
    const experienceTypeOptions = useExperienceTypeOptions()
    return (
        <QuickSearchSectionDesktopExperiences
            uid={uid}
            experienceTypeOptions={experienceTypeOptions}
            formState={formState}
            onChangeDestination={(destination) => {
                formState.handleChange('destination', destination)
            }}
            onChangeType={(type) => {
                formState.handleChange('type', type)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionDesktopExperiencesWithState
