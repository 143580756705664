/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import clsx from 'clsx'
import NextLink from 'next/link'
import { getLinkLocale } from '../../util/routes'
import usePageProps from '../hooks/usePageProps'
import usePathnameLocale from '../hooks/usePathnameLocale'
import Icon from './Icon'

const IconBefore = ({
    className,
    ...props
}) => (
    <Icon
        className={clsx(
            'mr-2',
            className,
        )}
        {...props}
    />
)

const IconAfter = ({
    className,
    ...props
}) => (
    <Icon
        size="smaller"
        className={clsx(
            'ml-2',
            className,
        )}
        {...props}
    />
)

const TextLink = ({
    as = 'a',
    href = '',
    iconBeforeName = null,
    iconBeforeColor = 'currentColor',
    iconBeforeSize = 'smaller',
    iconAfterName = null,
    iconAfterColor = 'currentColor',
    iconAfterSize = 'smaller',
    openInNewTab,
    className,
    onClick,
    children,
    ...props
}) => {
    const Component = as
    const { isPreview } = usePageProps()
    const locale = usePathnameLocale()
    return (
        <NextLink legacyBehavior href={href} locale={getLinkLocale(locale, href)}>
            <Component
                target={openInNewTab ? '_blank' : undefined}
                onClick={(e) => {
                    if (isPreview) {
                        e.preventDefault()
                    } else if (typeof onClick === 'function') {
                        onClick(e)
                    }
                }}
                className={clsx(
                    'inline-flex items-center',
                    className,
                )}
                {...props}
            >
                {iconBeforeName && (
                    <IconBefore
                        name={iconBeforeName}
                        color={iconBeforeColor}
                        size={iconBeforeSize}
                    />
                )}
                {children}
                {iconAfterName && (
                    <IconAfter
                        name={iconAfterName}
                        color={iconAfterColor}
                        size={iconAfterSize}
                    />
                )}
            </Component>
        </NextLink>
    )
}

export default TextLink
