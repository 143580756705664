import { usePathname } from 'next/navigation'
import { getSimpleLocaleFromPathname } from '@connections/utils'

export default function useLinkActive(href) {
    const asPath = usePathname()
    const locale = getSimpleLocaleFromPathname(asPath)
    const [currentPath] = asPath.split('?')
    if (locale === 'nl-be') {
        return href === currentPath
    }
    return href === `${locale}${asPath}`
}
