/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import CheckboxInput from '../utils/CheckboxInput'
import QuickSearchSectionDesktopCarsTimesSelect from './QuickSearchSectionDesktopCarsTimesSelect'
import QuickSearchSectionMobileCarsDestinationButtons from './QuickSearchSectionMobileCarsDestinationButtons'
import QuickSearchSectionMobileFlightsDateRangeSelect from './QuickSearchSectionMobileFlightsDateRangeSelect'

const QuickSearchSectionMobileCars = ({
    onSearch,
    formState,
    onConfirmDates,
    onConfirmTimes,
    onConfirmPickUpDestination,
    onConfirmDropOffDestination,
    onChangePickupIsSameAsDropOff,
}) => {
    const { values, errors } = formState
    const {
        pickUpDate,
        pickUpTime,
        dropOffDate,
        dropOffTime,
        pickUpDestination,
        dropOffDestination,
        pickupIsSameAsDropOff,
    } = values

    return (
        <div>
            <QuickSearchSectionMobileCarsDestinationButtons
                className="mb-4"
                toDestination={dropOffDestination}
                fromDestination={pickUpDestination}
                hideToDestination={pickupIsSameAsDropOff}
                onConfirmToDestination={onConfirmDropOffDestination}
                onConfirmFromDestination={onConfirmPickUpDestination}
                errors={errors.filter(({ path }) => (
                    path === 'pickUpDestination'
                    || path === 'dropOffDestination'
                ))}
            />
            <div className="mb-4">
                <CheckboxInput
                    className="text-sm"
                    value={pickupIsSameAsDropOff}
                    onChange={onChangePickupIsSameAsDropOff}
                >
                    <Trans id="Pick up location same as drop off location" />
                </CheckboxInput>
            </div>
            <QuickSearchSectionMobileFlightsDateRangeSelect
                className="mb-4"
                returnDate={dropOffDate}
                departureDate={pickUpDate}
                onConfirm={onConfirmDates}
                errors={errors.filter(({ path }) => (
                    path === 'pickUpDate'
                    || path === 'dropOffDate'
                ))}
            />
            <QuickSearchSectionDesktopCarsTimesSelect
                className="mb-4"
                pickUpTime={pickUpTime}
                dropOffTime={dropOffTime}
                onChange={onConfirmTimes}
                errors={errors.filter(({ path }) => (
                    path === 'pickUpTime'
                    || path === 'dropOffTime'
                ))}
            />
            <Button
                size="large"
                type="button"
                className="w-full"
                onClick={onSearch}
                iconAfterName="search"
                variant="primary-filled"
            >
                <Trans id="Search cars" />
            </Button>
        </div>
    )
}

export default QuickSearchSectionMobileCars
