import React from 'react'
import clsx from 'clsx'
import TimeSelect from '../utils/TimeSelect'

const options = [
    '07:00', '07:30',
    '08:00', '08:30',
    '09:00', '09:30',
    '10:00', '10:30',
    '11:00', '11:30',
    '12:00', '12:30',
    '13:00', '13:30',
    '14:00', '14:30',
    '15:00', '15:30',
    '16:00', '16:30',
    '17:00', '17:30',
    '18:00', '18:30',
    '19:00', '19:30',
    '20:00', '20:30',
    '21:00', '21:30',
    '22:00',
]

const QuickSearchSectionDesktopCarsTimesSelect = ({
    errors,
    pickUpTime,
    dropOffTime,
    onChange,
    className,
}) => {
    const hasError = errors.length > 0
    return (
        <div
            className={clsx(
                'relative flex rounded items-center border overflow-visible',
                !hasError && 'border-gray-100 hover:border-gray-300',
                hasError && 'border-primary',
                className
            )}
        >
            <TimeSelect
                value={pickUpTime}
                onChange={(e) => onChange(e.target.value, dropOffTime)}
                className="rounded-l text-center border-transparent "
            >
                {options.map((time) => (
                    <option key={time} value={time}>{time}</option>
                ))}
            </TimeSelect>
            <hr className="border-l border-gray-100 h-8 w-px mx-[2px]" />
            <TimeSelect
                value={dropOffTime}
                onChange={(e) => onChange(pickUpTime, e.target.value)}
                className="rounded-r text-center border-transparent "
            >
                {options.map((time) => (
                    <option key={time} value={time}>{time}</option>
                ))}
            </TimeSelect>
        </div>
    )
}

export default QuickSearchSectionDesktopCarsTimesSelect
