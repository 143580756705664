import React from 'react'
import { Trans } from '@lingui/react'
import NewsletterSignup from '../utils/NewsletterSignup'

const FooterNewsletterSignup = ({
    className,
}) => (
    <div className={className}>
        <div className="mb-4">
            <h3 className="text-sm uppercase font-medium tracking-widest text-black pl-2 border-l-2 border-black mb-2">
                <Trans id="Newsletter" />
            </h3>
            <p className="text-sm text-gray-600">
                <Trans id="Sign up for our newsletter and stay up-to-date about all thing connections related." />
            </p>
        </div>
        <NewsletterSignup />
    </div>
)

export default FooterNewsletterSignup
