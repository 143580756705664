import React from 'react'

export const Label = ({
    htmlFor,
    children,
}) => (
    <label
        htmlFor={htmlFor}
        className="block w-full mb-2 text-sm"
    >
        {children}
    </label>
)

const QuickSearchSectionDesktopInputGroup = ({
    htmlFor,
    label,
    className,
    children,
}) => (
    <div className={className}>
        <Label htmlFor={htmlFor}>
            {label}
        </Label>
        {children}
    </div>
)

export default QuickSearchSectionDesktopInputGroup
