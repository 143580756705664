import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import useLocaleNavigateTo from '../hooks/useLocaleNavigateTo'
import useTravelSearchSettingsQuery from '../hooks/useTravelSearchSettingsQuery'
import TravelSearchListInput from '../pages/travel-search/TravelSearchListInput'
import TravelSearchCountryInput from '../pages/travel-search/TravelSearchCountryInput'
import useTravelSearchFilterFormState from '../pages/travel-search/useTravelSearchFilterFormState'

const HeaderMobileMenuTravelSearch = ({ onSearch }) => {
    const navigateTo = useLocaleNavigateTo()
    const formState = useTravelSearchFilterFormState()
    const {
        months,
        vacations,
        countries,
        continents,
        travelThemes,
        travelFormulas,
    } = useTravelSearchSettingsQuery(formState)
    const { values } = formState

    const handleSearch = () => {
        navigateTo({ pathname: '/reizen/zoeken', params: formState.valuesToQueryString() })
        onSearch()
    }

    return (
        <div className="grid grid-cols-1 gap-y-6 py-6 px-4">
            <TravelSearchListInput
                options={continents}
                selectedOptions={values.continentCodes}
            >
                <Trans id="Regions" />
            </TravelSearchListInput>
            <TravelSearchCountryInput
                countries={countries}
                selectedCountryCode={values.countryCode}
                onChangeCountryCode={formState.onChange.countryCode}
            />
            <TravelSearchListInput
                columns={2}
                options={months}
                selectedOptions={values.months}
            >
                <Trans id="Months" />
            </TravelSearchListInput>
            <TravelSearchListInput
                options={vacations}
                selectedOptions={values.vacations}
            >
                <Trans id="Vacation periods" />
            </TravelSearchListInput>
            <TravelSearchListInput
                columns={2}
                options={travelThemes}
                selectedOptions={values.themes}
            >
                <Trans id="Travel themes" />
            </TravelSearchListInput>
            <TravelSearchListInput
                options={travelFormulas}
                selectedOptions={values.formulas}
            >
                <Trans id="Travel formulas" />
            </TravelSearchListInput>
            <Button
                size="large"
                onClick={handleSearch}
                className="w-full mt-2"
                iconBeforeName="search"
                variant="primary-filled"
            >
                <Trans id="Search$travel" />
            </Button>
        </div>
    )
}

export default HeaderMobileMenuTravelSearch
