import React from 'react'
import {
    StringParam,
    withDefault,
    NumberParam,
    BooleanParam,
    useQueryParams,
    DelimitedArrayParam,
} from 'use-query-params'
import dotProp from 'dot-prop-immutable'
import { parse, isMatch } from 'date-fns'
import { useLingui, Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import useFormState, { createFormValidation } from 'use-form-state'
import { isRequired, isGreaterOrEqualThan, isLessOrEqualThan } from '@connections/utils'
import useAirportSearchResults from '../hooks/useAirportSearchResults'

const {
    FARE_TYPE_FIRST: FIRST,
    FARE_TYPE_ECONOMY: ECONOMY,
    FARE_TYPE_BUSINESS: BUSINESS,
    FLIGHT_MODE_ROUND_TRIP: ROUND_TRIP,
    FLIGHT_MODE_MULTI_CITY: MULTI_CITY,
    FARE_TYPE_ECONOMY_PLUS: ECONOMY_PLUS,
} = constants

export const getDateFromQueryParam = (param) => {
    if (param !== null && isMatch(param, 'yyyy-MM-dd')) {
        return parse(param, 'yyyy-MM-dd', new Date())
    }
    return null
}

const validation = createFormValidation([{
    path: 'fromDestination',
    validate: isRequired,
    message: <Trans id="The departure destination is required" />,
}, {
    path: 'toDestination',
    validate: isRequired,
    message: <Trans id="The arrival destination is required" />,
}, {
    path: 'departureDate',
    validate: isRequired,
    message: <Trans id="The departure date is required" />,
}, {
    path: 'returnDate',
    validate: (value, { flightMode }) => (
        isRequired(value)
        || flightMode !== ROUND_TRIP
    ),
    message: <Trans id="The return date is required" />,
}, {
    path: 'numberOfAdults',
    validate: (value) => (
        isGreaterOrEqualThan(1)(value)
        && isLessOrEqualThan(9)(value)
    ),
    message: <Trans id="The number of adults must be between 1 & 9" />,
}, {
    path: 'numberOfChildren',
    validate: (value) => (
        isGreaterOrEqualThan(0)(value)
        && isLessOrEqualThan(9)(value)
    ),
    message: <Trans id="The number of children must be between 0 & 9" />,
}, {
    path: 'numberOfInfants',
    validate: (value) => (
        isGreaterOrEqualThan(0)(value)
        && isLessOrEqualThan(9)(value)
    ),
    message: <Trans id="The number of infants must be between 0 & 9" />,
}, {
    path: 'fareTypes',
    validate: isRequired,
    message: <Trans id="The fare type is required" />,
}, {
    path: 'extraSegments.*.fromDestination',
    validate: (value, { flightMode }) => (
        isRequired(value)
        || flightMode !== MULTI_CITY
    ),
    message: <Trans id="The departure destination is required" />,
}, {
    path: 'extraSegments.*.toDestination',
    validate: (value, { flightMode }) => (
        isRequired(value)
        || flightMode !== MULTI_CITY
    ),
    message: <Trans id="The arrival destination is required" />,
}, {
    path: 'extraSegments.*.departureDate',
    validate: (value, { flightMode }) => (
        isRequired(value)
        || flightMode !== MULTI_CITY
    ),
    message: <Trans id="The departure date is required" />,
}])

export const useFareTypeOptions = (promoFareType) => {
    const { i18n } = useLingui()
    const options = [{
        value: ECONOMY,
        label: i18n._(/*i18n*/'Economy')
    }, {
        value: ECONOMY_PLUS,
        label: i18n._(/*i18n*/'Economy Plus')
    }, {
        value: BUSINESS,
        label: i18n._(/*i18n*/'Business')
    }, {
        value: FIRST,
        label: i18n._(/*i18n*/'First')
    }]
    if (promoFareType) {
        return options.filter(({ value }) => value === promoFareType)
    }
    return options
}

const mapEmptyToNull = (arr) => (
    arr.map((value) => {
        if (value === '') {
            return null
        }
        return value
    })
)

export function getQuickSearchFlightQueryParamsConfig(fareType) {
    return {
        adults: withDefault(NumberParam, 1),
        infants: withDefault(NumberParam, 0),
        children: withDefault(NumberParam, 0),
        return: withDefault(StringParam, null),
        to: withDefault(DelimitedArrayParam, []),
        from: withDefault(DelimitedArrayParam, []),
        mode: withDefault(StringParam, ROUND_TRIP),
        alsoFindHotels: withDefault(BooleanParam, false),
        isDirectFlight: withDefault(BooleanParam, false),
        includeLuggage: withDefault(BooleanParam, false),
        departure: withDefault(DelimitedArrayParam, [null]),
        airlineIataCodes: withDefault(DelimitedArrayParam, []),
        fareType: withDefault(StringParam, fareType || ECONOMY),
    }
}

export function useQuickSearchFlightQueryParams(fareType) {
    return useQueryParams(getQuickSearchFlightQueryParamsConfig(fareType))
}

const useQuickSearchFlightFormState = (fromIataCode, toIataCode, minTravelers, cabinClass) => {
    const [query] = useQuickSearchFlightQueryParams(cabinClass)
    const {
        to,
        from,
        adults,
        infants,
        children,
        fareType,
        departure,
        includeLuggage,
        isDirectFlight,
        alsoFindHotels,
    } = query
    const hasToIataCode = !!(toIataCode)
    const hasFromIataCode = !!(fromIataCode)
    const minNumberOfAdults = Math.max(minTravelers, adults)
    const toIataCodes = hasToIataCode ? [toIataCode] : mapEmptyToNull(to)
    const fromIataCodes = hasFromIataCode ? [fromIataCode] : mapEmptyToNull(from)
    const returnDate = getDateFromQueryParam(query.return)
    const departureDate = getDateFromQueryParam(departure[0])
    const toDestinations = useAirportSearchResults(toIataCodes)
    const fromDestinations = useAirportSearchResults(fromIataCodes)
    const formState = useFormState({
        fareType,
        returnDate,
        departureDate,
        includeLuggage,
        isDirectFlight,
        alsoFindHotels,
        flightMode: query.mode,
        numberOfInfants: infants,
        numberOfChildren: children,
        numberOfAdults: minNumberOfAdults,
        toDestination: toDestinations[0] || null,
        fromDestination: fromDestinations[0] || null,
        extraSegments: [...fromDestinations].splice(1).map((fromDestination, index) => ({
            fromDestination,
            flightMode: query.mode,
            toDestination: toDestinations[index + 1],
            departureDate: getDateFromQueryParam(departure[index + 1]),
        })),
    }, { validation })
    return {
        ...formState,
        handleChangeNumberOfAdults: (number) => {
            if (number <= 9 && number >= 1) {
                formState.handleChange('numberOfAdults', number)
                if (formState.values.numberOfInfants >= number) {
                    formState.handleChange('numberOfInfants', number)
                }
            }
        },
        handleChangeNumberOfChildren: (number) => {
            if (number <= 9 && number >= 0) {
                formState.handleChange('numberOfChildren', number)
            }
        },
        handleChangeNumberOfInfants: (number) => {
            if (number <= 9 && number >= 0) {
                formState.handleChange('numberOfInfants', number)
            }
        },
        handleChangeFareType: (type) => {
            formState.handleChange('fareType', type)
        },
        handleChangeFlightMode: (flightMode) => {
            const { extraSegments } = formState.values
            formState.handleChange('flightMode', flightMode)
            if (flightMode === MULTI_CITY && extraSegments.length === 0) {
                extraSegments.push({
                    flightMode,
                    toDestination: null,
                    departureDate: null,
                    fromDestination: null,
                })
            }
            formState.handleChange('extraSegments', extraSegments.map((segment) => ({
                ...segment,
                flightMode,
            })))
        },
        handleChangeSegment: (index, key, value) => {
            const { extraSegments } = formState.values
            formState.handleChange('extraSegments', dotProp.set(extraSegments, `${index}.${key}`, value))
        },
        addSegment: () => {
            const { extraSegments, flightMode } = formState.values
            formState.handleChange('extraSegments', [...extraSegments, {
                flightMode,
                toDestination: null,
                departureDate: null,
                fromDestination: null,
            }])
        },
        removeSegment: (index) => {
            const { extraSegments } = formState.values
            formState.handleChange('extraSegments', extraSegments.filter((_, i) => i !== index))
        },
        handleSwitchFromAndTo: (index) => {
            const {
                setValues,
                values,
            } = formState
            if (typeof index === 'undefined') {
                const { fromDestination, toDestination } = values
                setValues({
                    toDestination: fromDestination,
                    fromDestination: toDestination,
                })
            } else {
                const { extraSegments } = values
                const { fromDestination, toDestination } = extraSegments[index]
                const newSegments = [...extraSegments]
                newSegments[index] = {
                    ...extraSegments[index],
                    toDestination: fromDestination,
                    fromDestination: toDestination,
                }
                formState.handleChange('extraSegments', newSegments)
            }
        }
    }
}

export default useQuickSearchFlightFormState
