import React from 'react'
import clsx from 'clsx'
import * as constants from '@connections/constants'
import {
    sectionSpacingVariant,
    sectionContainerVariant,
    sectionBackgroundVariant,
} from '../../util/variants'
import useQuickSearchTabs from '../hooks/useQuickSearchTabs'
import QuickSearchSectionDesktopTab from './QuickSearchSectionDesktopTab'
import QuickSearchSectionDesktopCarsWithState from './QuickSearchSectionDesktopCarsWithState'
import QuickSearchSectionDesktopFlightAndHotel from './QuickSearchSectionDesktopFlightAndHotel'
import QuickSearchSectionDesktopShopsWithState from './QuickSearchSectionDesktopShopsWithState'
import QuickSearchSectionDesktopTravelWithState from './QuickSearchSectionDesktopTravelWithState'
import QuickSearchSectionDesktopFlightsWithState from './QuickSearchSectionDesktopFlightsWithState'
import QuickSearchSectionDesktopExpertsWithState from './QuickSearchSectionDesktopExpertsWithState'
import QuickSearchSectionDesktopExperiencesWithState from './QuickSearchSectionDesktopExperiencesWithState'

const {
    CONTAINER_SMALL,
    QSM_TAB_CARS: CARS,
    QSM_TAB_SHOPS: SHOPS,
    QSM_TAB_TRAVEL: TRAVEL,
    QSM_TAB_FLIGHTS: FLIGHTS,
    QSM_TAB_EXPERTS: EXPERTS,
    QSM_TAB_EXPERIENCES: EXPERIENCES,
    QSM_TAB_FLIGHT_HOTEL: FLIGHT_HOTEL,
} = constants

const QuickSearchSectionDesktopTabs = ({
    activeTab,
    tabOptions,
    onClickTab,
}) => (
    <ul className="flex bg-gray-50 -mb-px rounded-t-lg">
        {tabOptions.map(({ value, label, icon }) => (
            <QuickSearchSectionDesktopTab
                key={value}
                type={value}
                iconName={icon}
                isActive={value === activeTab}
                onClick={() => onClickTab(value)}
            >
                {label}
            </QuickSearchSectionDesktopTab>
        ))}
    </ul>
)

const QuickSearchSectionDesktop = ({
    uid,
    tabs,
    values,
    openTab,
    spacing,
    isLoading,
    isSearching,
    background,
    promoValues,
    onSearchCars,
    isStandalone,
    onSearchShops,
    onSearchTravel,
    onSearchFlights,
    onSearchExperts,
    onSearchExperiences,
    container = CONTAINER_SMALL,
}) => {
    const {
        activeTab,
        tabOptions,
        onChangeTab,
    } = useQuickSearchTabs(tabs, openTab)
    const hasMultipleTabs = tabOptions.length > 1
    const activeTabOption = tabOptions.find(({ value }) => value === activeTab) || {}
    return (
        <div
            className={clsx(
                'z-10 relative hidden lg:block',
                isStandalone && sectionSpacingVariant({ spacing }),
                isStandalone && sectionBackgroundVariant(background),
            )}
        >
            <div
                className={clsx(
                    !isStandalone && '-mt-28',
                    sectionContainerVariant(container),
                )}
            >
                <div className="shadow-md rounded-lg">
                    {(() => {
                        if (hasMultipleTabs) {
                            return (
                                <QuickSearchSectionDesktopTabs
                                    tabOptions={tabOptions}
                                    activeTab={activeTab}
                                    onClickTab={onChangeTab}
                                />
                            )
                        }
                        return (
                            <div className="bg-white px-6 pt-6 -mb-px rounded-t-lg">
                                <h3 className="text-lg font-medium">
                                    {activeTabOption.label}
                                </h3>
                            </div>
                        )
                    })()}
                    <div className="bg-white p-6 rounded-b-lg">
                        {(() => {
                            if (activeTab === FLIGHTS) {
                                return (
                                    <QuickSearchSectionDesktopFlightsWithState
                                        uid={uid}
                                        values={values}
                                        isLoading={isLoading}
                                        isSearching={isSearching}
                                        promoValues={promoValues}
                                        onSearch={onSearchFlights}
                                    />
                                )
                            }
                            if (activeTab === FLIGHT_HOTEL) {
                                return (
                                    <QuickSearchSectionDesktopFlightAndHotel
                                        uid={uid}
                                        onSearch={() => { }}
                                    />
                                )
                            }
                            if (activeTab === CARS) {
                                return (
                                    <QuickSearchSectionDesktopCarsWithState
                                        uid={uid}
                                        onSearch={onSearchCars}
                                        pickUpIataCode={values.toIataCode}
                                        dropOffIataCode={values.toIataCode}
                                    />
                                )
                            }
                            if (activeTab === EXPERIENCES) {
                                return (
                                    <QuickSearchSectionDesktopExperiencesWithState
                                        uid={uid}
                                        onSearch={onSearchExperiences}
                                        iataCode={values.toIataCode}
                                    />
                                )
                            }
                            if (activeTab === TRAVEL) {
                                return (
                                    <QuickSearchSectionDesktopTravelWithState
                                        uid={uid}
                                        onSearch={onSearchTravel}
                                    />
                                )
                            }
                            if (activeTab === SHOPS) {
                                return (
                                    <QuickSearchSectionDesktopShopsWithState
                                        uid={uid}
                                        onSearch={onSearchShops}
                                    />
                                )
                            }
                            if (activeTab === EXPERTS) {
                                return (
                                    <QuickSearchSectionDesktopExpertsWithState
                                        uid={uid}
                                        onSearch={onSearchExperts}
                                        iataCode={values.toIataCode}
                                    />
                                )
                            }
                            return null
                        })()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktop
