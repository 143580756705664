import React from 'react'
import SbEditable from 'storyblok-react'
import { Trans } from '@lingui/react'
import ContactPhoneLink from '../utils/ContactPhoneLink'
import Logo from '../utils/Logo'
import FooterSocialLink from './FooterSocialLink'
import FooterDesktopLinkGroup from './FooterDesktopLinkGroup'
import FooterDisclaimerLink from './FooterDisclaimerLink'
import FooterNewsletterSignup from './FooterNewsletterSignup'
import FooterPaymentIcons from './FooterPaymentIcons'
import { getStaticUrl } from '../../util'
import Image from '../utils/Image'

const FooterDesktop = ({
    content,
}) => {
    const {
        linkGroups = [],
        openingHours,
        address,
        socialLinks = [],
        disclaimerLinks = []
    } = content
    return (
        <SbEditable content={content}>
            <footer className="hidden lg:block" data-testid="FooterDesktop">
                <div className="h-[340px] bg-black">
                    <div className="h-full container pt-12 grid grid-cols-3 gap-12">
                        {linkGroups.map((group) => (
                            <FooterDesktopLinkGroup
                                key={group._uid}
                                content={group}
                            />
                        ))}
                    </div>
                </div>
                <div className="h-[300px] bg-white border-t border-gray-100">
                    <div className="h-full container pt-8 flex justify-between">
                        <div className="h-full flex flex-col justify-between">
                            <div>
                                <div className="mb-6">
                                    <Logo isHorizontal className="h-10" />
                                </div>
                                <div className="text-sm font-medium mb-3">
                                    <ContactPhoneLink />
                                </div>
                                <div className="text-sm whitespace-pre text-gray-600">
                                    {openingHours}
                                </div>
                            </div>
                            <div>
                                <p className="text-sm text-gray-600">
                                    {address}
                                </p>
                            </div>
                            <div className="pb-5">
                                <ul className="flex">
                                    {socialLinks.map((link) => (
                                        <FooterSocialLink
                                            key={link._uid}
                                            content={link}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="h-full flex flex-col justify-between">
                            <FooterNewsletterSignup className="max-w-[600px]" />
                            <FooterPaymentIcons />
                        </div>
                    </div>
                </div>
                <div className="h-[55px] bg-white">
                    <div className="h-full container">
                        <div className="h-full flex border-t border-gray-100">
                            <div className="h-full flex-1 flex items-center gap-4">
                                <Image
                                    height={32}
                                    width={25}
                                    alt="GFG Garantiefonds Reizen"
                                    src={getStaticUrl('gfg-garantiefonds-reizen.gif')}
                                />
                                <Image
                                    height={32}
                                    width={46}
                                    alt="IATA Accredited Agent"
                                    src={getStaticUrl('iata-accredited-agent.jpg')}
                                />
                                <Image
                                    height={32}
                                    width={32}
                                    alt="ASBL BeCommerce VZW"
                                    src={getStaticUrl('B2C_bg.png')}
                                />
                            </div>
                            <div className="h-full flex-1 flex items-center justify-center text-xs">
                                <Trans id="Copyright - Connections" /> {new Date().getFullYear()}
                            </div>
                            <div className="h-full flex-1 flex items-center justify-end">
                                {disclaimerLinks.map((link) => (
                                    <FooterDisclaimerLink
                                        key={link._uid}
                                        content={link}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </SbEditable>
    )
}

export default FooterDesktop
