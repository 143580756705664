import React from 'react'
import { useLingui, Trans } from '@lingui/react'
import Button from '../utils/Button'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'
import QuickSearchSectionDesktopDestinationSelect from './QuickSearchSectionDesktopDestinationSelect'

const QuickSearchSectionDesktopExperts = ({
    uid,
    onSearch,
    formState,
    onChangeDestination,
}) => {
    const { i18n } = useLingui()
    const { values, errors } = formState
    const { destination } = values

    return (
        <div>
            <div className="grid grid-cols-7 gap-4">
                <QuickSearchSectionDesktopInputGroup
                    className="col-span-5"
                    htmlFor={`experts-destination-${uid}`}
                    label={<Trans id="Destination" />}
                >
                    <QuickSearchSectionDesktopDestinationSelect
                        destination={destination}
                        onChange={onChangeDestination}
                        variant="destinations-experts"
                        id={`experts-destination-${uid}`}
                        inputPlaceholder={i18n._(/*i18n*/'Destination')}
                        resultsPlaceholder={i18n._(/*i18n*/'Destination')}
                        errors={errors.filter(({ path }) => path === 'destination')}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <div className="flex items-end col-span-2">
                    <Button
                        size="large"
                        className="w-full"
                        onClick={onSearch}
                        iconBeforeName="search"
                        variant="primary-filled"
                        data-testid="QuickSearchSectionDesktopExpertsSearchButton"
                    >
                        <Trans id="Search Travel Designers" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopExperts
