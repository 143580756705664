import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { getAllOptions } from './QuickSearchSectionDesktopTravelDestinationSelect'
import QuickSearchSectionTravelSelectPillValue from './QuickSearchSectionTravelSelectPillValue'
import QuickSearchSectionMobileDestinationButton from './QuickSearchSectionMobileDestinationButton'
import QuickSearchSectionMobileTravelDestinationModal from './QuickSearchSectionMobileTravelDestinationModal'

const QuickSearchSectionMobileTravelDestinationSelect = ({
    error,
    loading,
    className,
    formState,
    countries,
    continents,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const { values } = formState
    const selectedDestinations = [...values.continentCodes]
    if (values.countryCode !== '') {
        selectedDestinations.push(values.countryCode)
    }
    const allOptions = getAllOptions(countries, continents)
    const hasValues = values.continentCodes.length > 0 || values.countryCode !== ''
    return (
        <>
            {isOpen && (
                <QuickSearchSectionMobileTravelDestinationModal
                    error={error}
                    loading={loading}
                    hasValues={hasValues}
                    formState={formState}
                    countries={countries}
                    continents={continents}
                    onClose={() => setIsOpen(false)}
                />
            )}
            <div
                className={clsx(
                    'w-full min-h-[56px] flex flex-col justify-center border rounded',
                    error && 'border-primary',
                    !error && 'border-gray-100',
                    className,
                )}
            >
                <QuickSearchSectionMobileDestinationButton
                    label={<Trans id="To" />}
                    isPlaceholder={!hasValues}
                    onClick={() => setIsOpen(true)}
                    placeholder={<Trans id="All destinations" />}
                    destination={hasValues && (
                        <QuickSearchSectionTravelSelectPillValue
                            options={allOptions}
                            values={selectedDestinations}
                            onReset={() => {
                                formState.handleChange('countryCode', '')
                                formState.handleChange('continentCodes', [])
                            }}
                            truncateValue={selectedDestinations.length > 2 && (
                                <Trans
                                    id="<0>{count} {count, plural, =1 {destination} other {destinations}}</0> selected"
                                    values={{ count: selectedDestinations.length }}
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>
    )
}

export default QuickSearchSectionMobileTravelDestinationSelect
