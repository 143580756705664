import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import SelectButton from '../utils/SelectButton'
import SearchListbox, { SearchListboxError, SearchListboxLoading } from '../utils/SearchListbox'
import QuickSearchSectionTravelPeriods from './QuickSearchSectionTravelPeriods'
import QuickSearchSectionTravelSelectPillValue from './QuickSearchSectionTravelSelectPillValue'

const QuickSearchSectionDesktopTravelPeriodSelect = ({
    error,
    months,
    testId,
    loading,
    vacations,
    formState,
    className,
}) => {
    const placeholder = <Trans id="All travel dates" />
    const [isOpen, setIsOpen] = useState(false)

    const handleClose = () => setIsOpen(false)

    const { values } = formState
    const selectedPeriods = [...values.months, ...values.vacations]
    const allOptions = vacations ? [...months, ...vacations] : months
    const hasValues = selectedPeriods.length > 0
    return (
        <div className={clsx('relative', className)}>
            <SelectButton
                hasError={error}
                data-testid={testId}
                isPlaceholder={false}
                onClick={() => setIsOpen(true)}
            >
                <div className="flex items-center">
                    <Icon
                        color="blue"
                        name="calendar"
                        className="mr-1.5"
                    />
                    {hasValues
                        ? (
                            <QuickSearchSectionTravelSelectPillValue
                                options={allOptions}
                                values={selectedPeriods}
                                onReset={() => {
                                    formState.handleChange('months', [])
                                    formState.handleChange('vacations', [])
                                }}
                                truncateValue={selectedPeriods.length > 2 && (
                                    <Trans
                                        values={{ count: selectedPeriods.length }}
                                        id="{count} {count, plural, =1 {period} other {periods}} selected"
                                    />
                                )}
                            />
                        )
                        : placeholder}
                </div>
            </SelectButton>
            {isOpen && (
                <SearchListbox
                    hasInput={false}
                    placeholder={placeholder}
                    testId={`${testId}Input`}
                    onPressEscape={handleClose}
                    onClickOutside={handleClose}
                    className="w-[500px] absolute z-10 -top-2 -left-2 p-2 rounded-md shadow-lg"
                    value={hasValues && (
                        <QuickSearchSectionTravelSelectPillValue
                            options={allOptions}
                            className="flex-wrap"
                            values={selectedPeriods}
                        />
                    )}
                    iconBeforeInput={(
                        <Icon
                            color="blue"
                            name="calendar"
                        />
                    )}
                >
                    {(() => {
                        if (error) {
                            return (
                                <SearchListboxError>
                                    <Trans id="Failed to fetch periods." />
                                </SearchListboxError>
                            )
                        }
                        if (loading) {
                            return (
                                <SearchListboxLoading>
                                    <Trans id="Loading periods..." />
                                </SearchListboxLoading>
                            )
                        }
                        return (
                            <QuickSearchSectionTravelPeriods
                                months={months}
                                vacations={vacations}
                                selectedMonths={values.months}
                                selectedVacations={values.vacations}
                                onSelectDefault={() => {
                                    formState.handleChange('months', [])
                                    formState.handleChange('vacations', [])
                                    handleClose()
                                }}
                            />
                        )
                    })()}
                </SearchListbox>
            )}
        </div>
    )
}

export default QuickSearchSectionDesktopTravelPeriodSelect
