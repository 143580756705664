import React from 'react'
import Icon from '../utils/Icon'

const HeaderMobileSubNavBar = ({
    activeMenuItem,
    onClose,
}) => (
    <nav className="grid grid-cols-12 h-full">
        <button
            type="button"
            className="h-full px-6 col-span-2"
            onClick={onClose}
        >
            <Icon
                name="arrow-left"
                color="blue-bright"
                size="small"
            />
        </button>
        <div className="flex items-center justify-center font-medium col-span-8">
            {activeMenuItem.text}
        </div>
    </nav>
)

export default HeaderMobileSubNavBar
