import React, { useState, useMemo } from 'react'
import { Trans } from '@lingui/react'
import debounce from 'lodash.debounce'
import useAppState from '../hooks/useAppState'
import useDestinationSearchSettingsQuery from '../hooks/useDestinationSearchSettingsQuery'
import SearchListbox, {
    SearchListboxTitle,
    SearchListboxEmpty,
    SearchListboxError,
    SearchListboxLoading,
    SearchListboxResults,
} from './SearchListbox'
import SearchListItem from './SearchListItem'
import SearchDestinationsTreeList from './SearchDestinationsTreeList'

const SearchListItemContinent = ({
    name,
    ...props
}) => (
    <SearchListItem
        iconName="globe"
        {...props}
    >
        {name} <Trans id="(All countries)" />
    </SearchListItem>
)

const SearchListItemCountry = ({
    name,
    ...props
}) => (
    <SearchListItem
        iconName="pin"
        {...props}
    >
        {name} <Trans id="(All cities)" />
    </SearchListItem>
)

const SearchListItemCity = ({
    name,
    ...props
}) => (
    <SearchListItem
        iconName="city"
        {...props}
    >
        {name}
    </SearchListItem>
)

const SearchDestinationsListBox = ({
    testId,
    onChange,
    className,
    destination,
    settingsSlug,
    onPressEscape,
    onClickOutside,
    inputPlaceholder,
    listboxClassName,
}) => {
    const { destinationSearch } = useAppState()
    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const { settings, error, loading } = useDestinationSearchSettingsQuery(settingsSlug)

    let cityCodesAvailable = []
    let countryCodesAvailable = []
    let continentCodesAvailable = []
    const isSearching = searchValue !== ''
    if (settings) {
        cityCodesAvailable = settings.content.cityCodes
        countryCodesAvailable = settings.content.countryCodes
        continentCodesAvailable = settings.content.continentCodes
    }

    const onSearch = async (e) => {
        const { value = '' } = e.target
        const results = destinationSearch
            .search(value)
            .filter(({ score }) => score < 0.2)
            .map(({ item }) => item)
        setSearchValue(value)
        setSearchResults(results)
    }
    const debouncedOnSearch = useMemo(() => debounce(onSearch, 300), [])

    return (
        <SearchListbox
            className={className}
            testId={`${testId}Input`}
            onChange={debouncedOnSearch}
            onPressEscape={onPressEscape}
            placeholder={inputPlaceholder}
            onClickOutside={onClickOutside}
        >
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to find destination." />
                        </SearchListboxError>
                    )
                }
                if (loading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Loading destinations..." />
                        </SearchListboxLoading>
                    )
                }
                if (searchResults.length === 0 && isSearching) {
                    return (
                        <SearchListboxEmpty>
                            <Trans id="No destinations found." />
                        </SearchListboxEmpty>
                    )
                }
                if (destination && !isSearching) {
                    let Component = SearchListItemContinent
                    if (destination.isCountry) {
                        Component = SearchListItemCountry
                    }
                    if (destination.isCity) {
                        Component = SearchListItemCity
                    }
                    return (
                        <SearchListboxResults className={listboxClassName}>
                            <Component
                                isSelected
                                name={destination.name}
                                actionText={<Trans id="Clear" />}
                                onClickAction={(e) => {
                                    e.stopPropagation()
                                    onChange(null)
                                }}
                            />
                        </SearchListboxResults>
                    )
                }
                if (searchValue === '') {
                    return (
                        <SearchDestinationsTreeList
                            onChange={onChange}
                            listboxClassName={listboxClassName}
                            cityCodesAvailable={cityCodesAvailable}
                            countryCodesAvailable={countryCodesAvailable}
                            continentCodesAvailable={continentCodesAvailable}
                        />
                    )
                }
                const resultCities = searchResults
                    .filter(({ value }) => cityCodesAvailable.includes(value))
                    .filter(({ isCity }) => isCity)
                const resultCountries = searchResults
                    .filter(({ value }) => countryCodesAvailable.includes(value))
                    .filter(({ isCountry }) => isCountry)
                const resultContinents = searchResults
                    .filter(({ value }) => continentCodesAvailable.includes(value))
                    .filter(({ isContinent }) => isContinent)
                const hasCities = resultCities.length > 0
                const hasCountries = resultCountries.length > 0
                const hasContinents = resultContinents.length > 0
                return (
                    <SearchListboxResults className={listboxClassName}>
                        {hasContinents && (
                            <SearchListboxTitle>
                                <Trans id="Continents" />
                            </SearchListboxTitle>
                        )}
                        {resultContinents.map((result) => (
                            <SearchListItemContinent
                                name={result.name}
                                key={result.value}
                                onClick={() => onChange(result)}
                            />
                        ))}
                        {hasCountries && (
                            <SearchListboxTitle>
                                <Trans id="Countries" />
                            </SearchListboxTitle>
                        )}
                        {resultCountries.map((result) => (
                            <SearchListItemCountry
                                name={result.name}
                                key={result.value}
                                onClick={() => onChange(result)}
                            />
                        ))}
                        {hasCities && (
                            <SearchListboxTitle>
                                <Trans id="Cities" />
                            </SearchListboxTitle>
                        )}
                        {resultCities.map((result) => (
                            <SearchListItemCity
                                name={result.name}
                                key={result.value}
                                onClick={() => onChange(result)}
                            />
                        ))}
                    </SearchListboxResults>
                )
            })()}
        </SearchListbox>
    )
}

export default SearchDestinationsListBox
