import useStoryblokQuery from './useStoryblokQuery'

const useDestinationSearchSettingsQuery = (slug) => {
    const {
        data,
        error,
        isLoading,
    } = useStoryblokQuery('destination-search-settings', `cdn/stories/${slug}`, {}, { enabled: !!slug })
    return { settings: data?.data.story, error, loading: isLoading }
}

export default useDestinationSearchSettingsQuery
