import React from 'react'
import SbEditable from 'storyblok-react'
import TextLink from '../utils/TextLink'

const TopBarMenuItem = ({
    content
}) => {
    const {
        href,
        text,
        iconBeforeName,
        iconAfterName
    } = content
    return (
        <SbEditable content={content}>
            <TextLink
                className="text-white text-sm px-2 mr-4 hover:underline"
                href={href}
                iconBeforeColor="blue-bright"
                iconAfterColor="blue-bright"
                iconBeforeName={iconBeforeName}
                iconAfterName={iconAfterName}
            >
                {text}
            </TextLink>
        </SbEditable>
    )
}

export default TopBarMenuItem
