import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import SearchCarRentalLocationListBox from '../utils/SearchCarRentalLocationListBox'

const QuickSearchSectionMobileCarsDestinationModal = ({
    title,
    onClose,
    onConfirm,
    inputPlaceholder,
    initialDestination,
    resultsPlaceholder,
    ...props
}) => {
    const [selectedDestination, setSelectedDestination] = useState(initialDestination)

    return (
        <ModalSlideUp
            isFullScreen
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={title}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                <SearchCarRentalLocationListBox
                    className="flex-1"
                    destination={selectedDestination}
                    inputPlaceholder={inputPlaceholder}
                    resultsPlaceholder={resultsPlaceholder}
                    onChange={(dest) => setSelectedDestination(dest)}
                    listboxClassName="h-[500px] overflow-y-scroll" // TODO how can we make the height fill
                />
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    onClick={onClose}
                    className="mr-4 w-full"
                    variant="blue-outlined"
                >
                    <Trans id="Close" />
                </Button>
                <Button
                    className="w-full"
                    variant="blue-filled"
                    iconAfterName="arrow-right"
                    onClick={() => onConfirm(selectedDestination)}
                >
                    <Trans id="Confirm" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default QuickSearchSectionMobileCarsDestinationModal
