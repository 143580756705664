import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from '../utils/Icon'
import TravelSearchListInput from '../pages/travel-search/TravelSearchListInput'

const QuickSearchSectionTravelFormulas = ({
    values,
    className,
    travelThemes,
    travelFormulas,
    onSelectDefault,
}) => (
    <div
        className={clsx(
            'flex flex-col py-4',
            className,
        )}
    >
        <button
            type="button"
            onClick={onSelectDefault}
            className="flex-1 flex justify-between items-center bg-gray-50 -mx-2 mb-3 px-5 py-3"
        >
            <div className="flex items-center">
                <Icon
                    size="smaller"
                    name="location-point"
                />
                <div className="text-sm text-start ml-2">
                    <p className="font-medium">
                        <Trans id="Show me all travel formulas" />
                    </p>
                </div>
            </div>
            <Icon
                color="gray"
                name="arrow-right"
            />
        </button>
        <div className="px-3.5 flex flex-wrap gap-6">
            <TravelSearchListInput
                options={travelThemes}
                selectedOptions={values.themes}
            >
                <Trans id="Travel themes" />
            </TravelSearchListInput>
            <TravelSearchListInput
                options={travelFormulas}
                selectedOptions={values.formulas}
            >
                <Trans id="Travel formulas" />
            </TravelSearchListInput>
        </div>
    </div>
)

export default QuickSearchSectionTravelFormulas
