import React from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import { SearchListboxError, SearchListboxLoading } from '../utils/SearchListbox'
import QuickSearchSectionTravelDestinations from './QuickSearchSectionTravelDestinations'

const QuickSearchSectionMobileTravelDestinationModal = ({
    error,
    loading,
    onClose,
    formState,
    countries,
    hasValues,
    continents,
    ...props
}) => (
    <ModalSlideUp
        isFullScreen
        onClose={onClose}
        {...props}
    >
        <ModalSlideUpHeader
            onClose={onClose}
            title={<Trans id="To" />}
        />
        <ModalSlideUpContent className="px-2">
            {(() => {
                if (error) {
                    return (
                        <SearchListboxError>
                            <Trans id="Failed to fetch destinations." />
                        </SearchListboxError>
                    )
                }
                if (loading) {
                    return (
                        <SearchListboxLoading>
                            <Trans id="Loading destinations..." />
                        </SearchListboxLoading>
                    )
                }
                if (continents?.length <= 0 && countries?.length <= 0) {
                    return (
                        <SearchListboxError>
                            <Trans id="No destinations available." />
                        </SearchListboxError>
                    )
                }
                return (
                    <QuickSearchSectionTravelDestinations
                        className="py-0"
                        countries={countries}
                        continents={continents}
                        selectedCountryCode={formState.values.countryCode}
                        onChangeCountryCode={formState.onChange.countryCode}
                        selectedContinentCodes={formState.values.continentCodes}
                        onSelectDefault={() => {
                            formState.handleChange('countryCode', '')
                            formState.handleChange('continentCodes', [])
                            onClose()
                        }}
                    />
                )
            })()}
        </ModalSlideUpContent>
        <ModalSlideUpFooter className="flex items-center">
            <Button
                onClick={onClose}
                className="mr-4 w-full"
                variant="blue-outlined"
            >
                {hasValues
                    ? <Trans id="Select" />
                    : <Trans id="Close" />}
            </Button>
        </ModalSlideUpFooter>
    </ModalSlideUp>
)

export default QuickSearchSectionMobileTravelDestinationModal
