import React from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import Announcement from './Announcement'
import { variant } from '../../util'

const AnnouncementBarSlider = ({
    className,
    onDismiss,
    isDismissed,
    activeAnnouncement,
}) => {
    const {
        icon,
        text,
        deadline,
        background,
        description,
        showOnMobile,
        canBeDismissed,
    } = activeAnnouncement

    return (
        <AnimatePresence>
            {!isDismissed && (
                <motion.div
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    className={clsx(
                        'first:py-4 overflow-hidden',
                        !showOnMobile && 'hidden lg:block',
                        variant(background, {
                            white: 'bg-white text-black',
                            black: 'bg-black text-white',
                            red: 'bg-primary text-white',
                            blue: 'bg-blue-bright text-white',
                            'gray-50': 'bg-gray-50 text-black',
                            'gray-100': 'bg-gray-100 text-black',
                        }),
                        className
                    )}
                >
                    <Announcement
                        icon={icon}
                        text={text}
                        deadline={deadline}
                        onDismiss={onDismiss}
                        description={description}
                        canBeDismissed={canBeDismissed}
                    />
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default AnnouncementBarSlider
