import React from 'react'
import clsx from 'clsx'
import Icon from '../utils/Icon'
import Countdown from '../utils/Countdown'

const Announcement = ({
    icon,
    deadline,
    onDismiss,
    className,
    description,
    canBeDismissed,
}) => (
    <div
        className={clsx(
            'container flex h-full items-center justify-center gap-4',
            className
        )}
    >
        {icon && (
            <Icon
                name={icon}
                size="small"
                className="flex-shrink-0"
            />
        )}
        {description && (
            <p className="text-sm text-center">
                {description}
            </p>
        )}
        {deadline && <Countdown deadlineString={deadline} className="text-current" />}
        {canBeDismissed && (
            <button onClick={onDismiss} type="button">
                <Icon
                    name="cross"
                    size="small"
                    className="flex-shrink-0"
                />
            </button>
        )}
    </div>
)

export default Announcement
