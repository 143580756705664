import React, { useState } from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import useInterval from '../hooks/useInterval'
import usePathnameLocale from '../hooks/usePathnameLocale'
import CarouselDots from '../utils/CarouselDots'
import HeaderMobileSubMenuLinkGroup from './HeaderMobileSubMenuLinkGroup'
import HeaderDesktopMenuItemDropdownCard from './HeaderDesktopMenuItemDropdownCard'
import HeaderMobileMenuItem from './HeaderMobileMenuItem'
import { getHrefFromLink } from '../../util/index'

const HeaderMobileSubMenu = ({
    activeMenuItem,
}) => {
    const locale = usePathnameLocale()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [hasDisabledAutoplay, setDisableAutoplay] = useState(false)
    const { dropdowns, mobileText, link } = activeMenuItem
    const [dropdown = {}] = dropdowns
    const { cards = [] } = dropdown
    useInterval(() => {
        if (currentIndex < cards.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            setCurrentIndex(0)
        }
    }, hasDisabledAutoplay ? null : 4000)
    if (dropdown === null) {
        return null
    }
    const card = cards[currentIndex]
    const hasMobileText = !!mobileText
    const href = getHrefFromLink(link, locale)
    const hasLink = link && link.story

    return (
        <div className="min-h-full flex flex-col justify-between">
            <div>
                {(hasMobileText && hasLink) && (
                    <HeaderMobileMenuItem
                        as="div"
                        href={href}
                        hasDropdown={false}
                        openInNewTab={link.target === '_blank'}
                        className="border-b border-gray-100 mb-6"
                    >
                        {mobileText}
                    </HeaderMobileMenuItem>
                )}
                <div className={clsx(
                    !(hasMobileText && hasLink) && 'pt-10'
                )}
                >
                    {dropdown && dropdown.linkGroups.map((group) => (
                        <HeaderMobileSubMenuLinkGroup
                            key={group._uid}
                            content={group}
                        />
                    ))}
                </div>
            </div>
            {card && (
                <div className="w-full h-[350px] relative">
                    <AnimatePresence initial={false}>
                        <motion.div
                            key={currentIndex}
                            className="w-full h-full absolute"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <HeaderDesktopMenuItemDropdownCard
                                content={card}
                                onClick={() => {
                                    if (currentIndex < cards.length - 1) {
                                        setCurrentIndex(currentIndex + 1)
                                    } else {
                                        setCurrentIndex(0)
                                    }
                                }}
                            />
                        </motion.div>
                    </AnimatePresence>
                    {cards.length > 1 && (
                        <CarouselDots
                            size="small"
                            count={cards.length}
                            activeIndex={currentIndex}
                            className="absolute top-0 right-0 z-10"
                            onChangeIndex={(index) => {
                                setDisableAutoplay(true)
                                setCurrentIndex(index)
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default HeaderMobileSubMenu
