import React from 'react'
import { useLingui, Trans } from '@lingui/react'
import Button from '../utils/Button'
import Select from '../utils/Select'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'
import QuickSearchSectionDesktopDestinationSelect from './QuickSearchSectionDesktopDestinationSelect'

const QuickSearchSectionDesktopExperiences = ({
    uid,
    experienceTypeOptions,
    formState,
    onChangeDestination,
    onChangeType,
    onSearch,
}) => {
    const { i18n } = useLingui()
    const { values, errors } = formState
    const {
        destination,
        type,
    } = values
    return (
        <div>
            <div className="flex flex-wrap space-x-4">
                <QuickSearchSectionDesktopInputGroup
                    className="flex-1 min-w-[28rem]"
                    htmlFor={`experiences-destination-${uid}`}
                    label={<Trans id="Destination" />}
                >
                    <QuickSearchSectionDesktopDestinationSelect
                        destination={destination}
                        onChange={onChangeDestination}
                        variant="destinations-experiences"
                        id={`experiences-destination-${uid}`}
                        inputPlaceholder={i18n._(/*i18n*/'Destination')}
                        resultsPlaceholder={i18n._(/*i18n*/'Destination')}
                        errors={errors.filter(({ path }) => path === 'destination')}
                    />
                </QuickSearchSectionDesktopInputGroup>
                <QuickSearchSectionDesktopInputGroup
                    className="flex-1 min-w-[10rem]"
                    htmlFor={`experiences-type-${uid}`}
                    label={<Trans id="Type" />}
                >
                    <Select
                        id={`experiences-type-${uid}`}
                        value={type}
                        onChange={(e) => onChangeType(e.target.value)}
                    >
                        {experienceTypeOptions.map(({ value, label }) => (
                            <option value={value} key={value}>{label}</option>
                        ))}
                    </Select>
                </QuickSearchSectionDesktopInputGroup>
                <div className="flex-1 flex items-end">
                    <Button
                        size="large"
                        className="w-full"
                        onClick={onSearch}
                        iconBeforeName="search"
                        variant="primary-filled"
                        data-testid="QuickSearchSectionDesktopExperiencesSearchButton"
                    >
                        <Trans id="Search" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopExperiences
