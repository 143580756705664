import React from 'react'
import { useLingui } from '@lingui/react'
import Icon from '../utils/Icon'
import Input from '../utils/Input'
import usePageProps from '../hooks/usePageProps'
import useSearchState from '../hooks/useSearchState'

const HeaderDesktopGlobalSearch = () => {
    const { i18n } = useLingui()
    const { isPreview } = usePageProps()
    const { formState, handleSearch } = useSearchState()

    const handleSubmit = (e) => {
        if (!isPreview) {
            handleSearch(e)
        }
    }

    return (
        <form
            className="relative"
            onSubmit={handleSubmit}
        >
            <Input
                inputClassName="text-base bg-gray-50"
                placeholder={i18n._(/*i18n*/'Search')}
                {...formState.getNativeInputProps('query')}
            />
            <div className="absolute top-1/2 left-full transform -translate-y-1/2 -translate-x-full pr-1.5">
                <button
                    type="submit"
                    className="bg-blue-bright p-2 rounded-md"
                >
                    <Icon size="small" color="white" name="search" />
                </button>
            </div>
        </form>
    )
}

export default HeaderDesktopGlobalSearch
