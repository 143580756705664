import React, { useState } from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import QuickSearchSectionTravelSelectPillValue from './QuickSearchSectionTravelSelectPillValue'
import QuickSearchSectionMobileDestinationButton from './QuickSearchSectionMobileDestinationButton'
import QuickSearchSectionMobileTravelFormulaModal from './QuickSearchSectionMobileTravelFormulaModal'

const QuickSearchSectionMobileTravelFormulaSelect = ({
    error,
    loading,
    className,
    formState,
    travelFormulas,
    travelThemes,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const { values } = formState
    const { formulas, themes } = values
    const count = formulas.length + themes.length
    const hasValues = count > 0
    const pillValues = [...formulas, ...themes]
    const pillOptions = [...travelFormulas, ...travelThemes]

    return (
        <>
            {isOpen && (
                <QuickSearchSectionMobileTravelFormulaModal
                    error={error}
                    loading={loading}
                    hasValues={hasValues}
                    formState={formState}
                    travelThemes={travelThemes}
                    travelFormulas={travelFormulas}
                    onClose={() => setIsOpen(false)}
                />
            )}
            <div
                className={clsx(
                    'w-full min-h-[56px] flex flex-col justify-center border rounded',
                    error ? 'border-primary' : 'border-gray-100',
                    className,
                )}
            >
                <QuickSearchSectionMobileDestinationButton
                    isPlaceholder={!hasValues}
                    label={<Trans id="What" />}
                    onClick={() => setIsOpen(true)}
                    placeholder={<Trans id="All travel formulas" />}
                    destination={hasValues && (
                        <QuickSearchSectionTravelSelectPillValue
                            values={pillValues}
                            options={pillOptions}
                            onReset={() => {
                                formState.handleChange('formulas', [])
                                formState.handleChange('themes', [])
                            }}
                            truncateValue={count > 2 && (
                                <Trans
                                    values={{ count }}
                                    id="<0>{count} {count, plural, =1 {travel formula} other {travel formulas}}</0> selected"
                                />
                            )}
                        />
                    )}
                />
            </div>
        </>
    )
}

export default QuickSearchSectionMobileTravelFormulaSelect
