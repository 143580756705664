import React from 'react'
import clsx from 'clsx'
import Radio from './Radio'

const ModalSlideUpSelectOption = ({
    isSelected,
    onClose,
    children,
    ...props
}) => (
    <button type="button" className="flex items-center pl-1 py-3 w-full" {...props}>
        <Radio tabIndex="-1" className="mr-4" value={isSelected} />
        {children}
    </button>
)

export default ModalSlideUpSelectOption
