import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import ModalSlideUp from '../utils/ModalSlideUp'
import ModalSlideUpHeader from '../utils/ModalSlideUpHeader'
import ModalSlideUpContent from '../utils/ModalSlideUpContent'
import ModalSlideUpFooter from '../utils/ModalSlideUpFooter'
import ModalSlideUpSelectOption from '../utils/ModalSlideUpSelectOption'

const QuickSearchSectionMobileSelectModal = ({
    title,
    value,
    options,
    onClose,
    onConfirm,
    className,
    ...props
}) => {
    const [selectedMode, setSelectedMode] = useState(value)
    return (
        <ModalSlideUp
            className={className}
            onClose={onClose}
            {...props}
        >
            <ModalSlideUpHeader
                title={title}
                onClose={onClose}
            />
            <ModalSlideUpContent>
                {options.map((option) => (
                    <ModalSlideUpSelectOption
                        isSelected={selectedMode === option.value}
                        onClick={() => {
                            setSelectedMode(option.value)
                            onConfirm(option.value)
                        }}
                        data-testid={`radio-${option.value}-mobile`}
                    >
                        {option.label}
                    </ModalSlideUpSelectOption>
                ))}
            </ModalSlideUpContent>
            <ModalSlideUpFooter className="flex items-center">
                <Button
                    className="mr-4 w-full"
                    variant="blue-outlined"
                    onClick={onClose}
                >
                    <Trans id="Close" />
                </Button>
            </ModalSlideUpFooter>
        </ModalSlideUp>
    )
}

export default QuickSearchSectionMobileSelectModal
