import React from 'react'
import QuickSearchSectionDesktopCars from './QuickSearchSectionDesktopCars'
import useQuickSearchCarFormState from './useQuickSearchCarFormState'

const QuickSearchSectionDesktopCarsWithState = ({
    uid,
    pickUpIataCode,
    dropOffIataCode,
    onSearch
}) => {
    const formState = useQuickSearchCarFormState(pickUpIataCode, dropOffIataCode)
    return (
        <QuickSearchSectionDesktopCars
            uid={uid}
            formState={formState}
            onChangePickupIsSameAsDropOff={(e) => {
                formState.handleChange('pickupIsSameAsDropOff', e.target.checked)
            }}
            onChangePickUpDestination={(dest) => {
                formState.handleChange('pickUpDestination', dest)
            }}
            onChangeDropOffDestination={(dest) => {
                formState.handleChange('dropOffDestination', dest)
            }}
            onChangeDates={(pickUp, dropOff) => {
                formState.handleChange('pickUpDate', pickUp)
                formState.handleChange('dropOffDate', dropOff)
            }}
            onChangeTimes={(pickUp, dropOff) => {
                formState.handleChange('pickUpTime', pickUp)
                formState.handleChange('dropOffTime', dropOff)
            }}
            onSearch={() => {
                if (formState.validate()) {
                    onSearch(formState.values)
                }
            }}
        />
    )
}

export default QuickSearchSectionDesktopCarsWithState
